import React, {useState, useMemo, useEffect, useContext} from 'react'
import ThingsContext from '../../modules/apps/masters/commonicons/GlobalContext'
import ValidContext from '../../modules/apps/masters/commonicons/ValidateContext'
import {useForm} from 'react-hook-form'
import {useIntl} from 'react-intl'
import {useLocation} from 'react-router-dom'
import {ToastContainer, toast} from 'react-toastify'
import {useNavigate} from 'react-router-dom'
import {default as Apiservices} from '../../../../src/common/index'
import moment from 'moment'
import clsx from 'clsx'
import BackDrop from '../../modules/apps/timesheetentry/ComponentBackdrop'
import {PageTitle} from '../../../_metronic/layout/core'
import ReactQuill from 'react-quill'
import {ScrollTop} from '../../../_metronic/layout/components/ScrollTop'
import 'react-quill/dist/quill.snow.css'
import ReactPaginate from 'react-paginate'
import {Container, Row, Col} from 'react-bootstrap'
import {
  useQueryResponseData,
  useQueryResponseLoading,
} from '../../modules/apps/user-management/users-list/core/QueryResponseProvider'
import DatePickerIcon from '../../modules/apps/masters/commonicons/DatePickerIcon'
import {useTable, ColumnInstance} from 'react-table'
import {ListProjects} from '../../modules/apps/user-management/users-list/table/columns/_columns'
import {KTCardBody, KTSVG} from '../../../_metronic/helpers'
import parse from 'html-react-parser'

type FormData = {
  status: string
  qb_project_name: string
  qb_project_id: string
  qb_customer_id: string
  qb_product_name: string
  // currency: string
  work_country: string
  work_city: string
  placement_type: string
  project_type: string
  communication: string
  project_name: string
  candidate: string
  start_date: any
  expected_hours: number
  effective_date: any
  business_unit_id: any
  joining_date: any
  end_date: any
  cancel_date: any
  job_title: string
  client_name: string
  placement_code: string
  end_customer: string
  primary_project: any
  direct_customer_engagement: boolean
  qb_customer_name: string
  record_type_status: string
  qb_product_id: string
  client_manager_name: string
  client_manager_email: string
  reasons: string
}

function CorrespondenceDetails(props: any) {
  const location = useLocation()
  const {
    register,
    setValue,
    reset,
    handleSubmit,
    getValues,
    formState: {errors},
  } = useForm<FormData>()
  const [clientname, setClientName] = useState<any | []>([])
  const users = useQueryResponseData()
  const isLoading = useQueryResponseLoading()

  const data = useMemo(() => users, [users])
  const columns = useMemo(() => ListProjects, [])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })
  const [showBackDrop, setShowBackDrop] = useState(false)
  const [projectid, setProjectid] = useState<any | []>([])
  const [CommentsHistory, setCommentsHistory] = useState<any | []>([])
  const [dateValue, setDateValue] = useState(null)
  const [ExpectedHoursHistory, setExpectedHoursHistory] = useState<any>([])
  const [startDate, setStartDate] = useState(null)
  const [effectiveDate, setEffectiveDate] = useState(null)
  const [announcementDes, setAnnouncementDes] = useState('')
  const [HistoryId, setHistoryId] = useState<any>('')
  const [userId, setUserId] = useState('')
  const [validatedescription, setValidateDescription] = useState<any>(false)
  const [ViewEdit, setViewEdit] = useState<any>()
  const [perPage, setPerPage] = useState(25)
  const [pages, setPages] = useState(1)
  const [page, setPage] = useState(1)
  const [CommentsHistoryData, setCommentsHistoryData] = useState<any | []>([])
  const [order1, setOrder1] = useState('ASC')
  const [sort, setSort] = useState<any | []>(false)
  const [isValid, setisValid] = useState(false)

  type ButtonProps = React.HTMLProps<HTMLButtonElement>
  const intl = useIntl()
  const navigate = useNavigate()

  const tokenHeader: any = useContext(ThingsContext)
  const renderThings = async () => {
    tokenHeader.getCookie()
  }

  useEffect(() => {
    renderThings()
    let state: any = location.state
    setUserId(state.id)
  }, [])
  const onChange = async (e: any) => {
    const {name, value} = e.target
    if (name == 'perPage') {
      setPerPage(value)
      setPage(1)
      CommentPagination(1, value)
    }
  }

  const handlePageClick = (event: any) => {
    let page = event.selected
    let selected = page + 1
    setPage(selected)
    CommentPagination(selected, perPage)
  }

  const CommentPagination = async (page: any, perPage: any) => {
    setShowBackDrop(true)
    const history: any = await Apiservices.getHistoryCommentsbyUserid({
      user_id: userId,
      pagenumber: page,
      pagesize: Number(perPage),
    })
    if (history.data.isError == false) {
      setCommentsHistory(history.data.data)
      setCommentsHistoryData(history.data.count[0].count)
      setPages(Math.ceil(history.data.count[0].count / perPage))
    }
    setShowBackDrop(false)
  }

  useEffect(() => {
    let state: any = location.state
    const viewEmployee = async (id: any) => {
      if (location.state) {
        setShowBackDrop(true)
        setViewEdit(props.IndexState?.commentHistory)
        // setStateEdit(state.view)
        const history: any = await Apiservices.getHistoryCommentsbyUserid({user_id: state.id})
        if (history.data.isError == false) {
          setCommentsHistory(history.data.data)
          setCommentsHistoryData(history.data.count[0].count)
        }
        setShowBackDrop(false)
      }
    }
    if (location.state) {
      viewEmployee(state.id)
    }
  }, [])

  const historyDetails = async (id: any) => {
    setHistoryId(id)

    const history: any = await Apiservices.getHistoryCommentsbyCommentid({id: id})
    if (history.data.isError == false) {
      let dataSet = {
        ...history.data.data,
        start_date: history.data.data[0].communicationdate
          ? new Date(history.data.data[0].communicationdate)
          : '',
      }
      // setValue('start_date', dataSet.start_date)
      setStartDate(dataSet.start_date)
      setValue('communication', history.data.data[0].mode_of_communication)
      setAnnouncementDes(history.data.data[0].comments)
      reset(dataSet)
    }
  }

  let business: any = localStorage.getItem('role_Name')
  let numArray: any = business?.split(',')
  const onSubmit = async (data: FormData) => {
    const article = {
      user_id: userId,
      comments: announcementDes,
      communication_mode: getValues('communication'),
      communication_date: startDate ? moment(startDate).format('YYYY-MM-DD') : null,
    }

    const article1 = {
      user_id: userId,
      id: HistoryId,
      comments: announcementDes,
      communication_mode: getValues('communication'),
      communication_date: startDate ? moment(startDate).format('YYYY-MM-DD') : null,
    }

    if (
      characterLength < 10 ||
      characterLength > 1000 ||
      // announcementDes.length == 0 ||
      announcementDes == '<p><br></p>' ||
      announcementDes == '<h1><br></h1>' ||
      announcementDes == '<h2><br></h2>' ||
      announcementDes == '<h2><br></h2>' ||
      announcementDes == '<h3><br></h3>'
    ) {
      setisValid(true)
    } else {
      setisValid(false)

      if (!HistoryId) {
        setShowBackDrop(true)

        const response: any = await Apiservices.insertRmComment(article)
        setShowBackDrop(false)
        setProjectid(response.data)
        if (response.isError == false) {
          toast.success('Communication Details Added Successfully')
          const history: any = await Apiservices.getHistoryCommentsbyUserid({user_id: userId})
          if (history.data.isError == false) {
            setCommentsHistory(history.data.data)
            let dataSet = {
              start_date: null,
              communication: '',
            }
            setStartDate(dataSet.start_date)
            setAnnouncementDes('')
            reset(dataSet)
          }
        } else {
          toast.warn('Validation Failed')
        }

        // return response.data
      } else if (HistoryId) {
        setShowBackDrop(true)
        const Response: any = await Apiservices.updateComment(article1)
        setShowBackDrop(false)
        if (Response.isError == false) {
          toast.success('Communication Details Updated Successfully')
          const history: any = await Apiservices.getHistoryCommentsbyUserid({user_id: userId})
          if (history.data.isError == false) {
            setHistoryId('')
            setCommentsHistory(history.data.data)
            let dataSet = {
              start_date: null,
              communication: '',
            }
            setStartDate(dataSet.start_date)
            setAnnouncementDes('')
            reset(dataSet)
          }
        } else {
          toast.error(Response.message)
          setHistoryId('')
          let dataSet = {
            start_date: null,
            communication: '',
          }
          setStartDate(dataSet.start_date)
          setAnnouncementDes('')
          reset(dataSet)
        }

        // return Response.data
      }
    }
  }

  const orgID = localStorage.getItem('org_id')

  const handleProjectStartDate = async (date: any) => {
    setDateValue(date)
    setStartDate(date)
    setValue('start_date', date)
    setEffectiveDate(date)
    setValue('effective_date', date)
  }

  const getMaxDate = (date: any) => {
    if (!date) {
      return date
    }
    const startTime = moment(date).add(1, 'd').format('YYYY-MM-DD')
    return new Date(startTime)
  }

  const minDate = React.useMemo(
    () => getMaxDate(ExpectedHoursHistory && ExpectedHoursHistory[0]?.effective_date),
    [ExpectedHoursHistory]
  )

  const handleViewEdit = () => {
    // setIsView(false)
    // setCanFlag(false)
    // setProspectiveFieldDisable(false)
    setViewEdit(false)
  }
  const sorting1 = (col: any) => {
    if (order1 === 'ASC') {
      const sorted = [...CommentsHistory]?.sort((a, b) => {
        let dateA = new Date(a[col]),
          dateB = new Date(b[col])
        if (dateB > dateA) {
          return -1
        } else {
          return 1
        }
      })

      setCommentsHistory(sorted)
      setOrder1('DSC')
      setSort(false)
    }

    if (order1 === 'DSC') {
      const sorted = [...CommentsHistory]?.sort((a, b) => {
        let dateA = new Date(a[col]),
          dateB = new Date(b[col])
        if (dateB < dateA) {
          return -1
        } else {
          return 1
        }
      })

      setCommentsHistory(sorted)
      setOrder1('ASC')
      setSort(true)
    }
  }

  const handleAnnouncement = (content: any, delta: any, source: any, editor: any) => {
    setisValid(false)
    // setAnnouncementDes(select)
    setAnnouncementDes(content)
    const text = editor.getText()
  }
  const stripHtmlTags = (html: any) => {
    const doc = new DOMParser().parseFromString(html, 'text/html')
    // console.log(doc, 'doc', html)
    return doc.body.textContent || ''
  }
  const characterLength = stripHtmlTags(announcementDes).length

  return (
    <>
      {/* {location.state ? (
        <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.UPDATEPROJ'})}</PageTitle>
      ) : (
        <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.ADDPROJ'})}</PageTitle>
      )} */}
      {ViewEdit && props.IndexState?.user_status?.toLowerCase() == 'active' && (
        <>
          <div className='d-flex justify-content-end pb-2'>
            <div>
              <button
                className='btn btn-primary form-group  py-2 '
                type='button'
                onClick={() => handleViewEdit()}
                aria-disabled='true'
              >
                Add Correspondence
              </button>
            </div>
          </div>
        </>
      )}
      {!ViewEdit && props.IndexState?.user_status?.toLowerCase() == 'active' && (
        <>
          <KTCardBody className='py-2 '>
            <div className='card card-custom card-flush'>
              <div className='card-body  py-4'>
                <form onSubmit={handleSubmit(onSubmit)} id='add-project'>
                  <BackDrop showBackDrop={showBackDrop} />

                  <div className='card card-custom card-flush mb-6'>
                    <div className='card-body py-5'>
                      <h3 className='text-gray-900 fs-3 fw-semibold  py-2'>
                        Correspondence Details
                      </h3>
                      <div className='row gy-4 '>
                        {/* Common component for Date Picker */}

                        <div className='col-xs-12 col-md-6 '>
                          <label className='form-label'>
                            {' '}
                            Date of Communication (MM/DD/YYYY) *
                          </label>
                          <DatePickerIcon
                            className='form-control fs-6'
                            // showIcon
                            {...register('start_date', {
                              required: true,
                            })}
                            id='start_date'
                            selected={startDate}
                            // name='startDate'
                            dateFormat='MM/dd/yyyy'
                            handleDate={(date: any) => {
                              handleProjectStartDate(date)
                              // setStartDate(date)
                              // setValue('start_date', date)
                            }}
                            disabled={ViewEdit}
                            // min={
                            //   UserData ? UserData && UserData[0] && new Date(UserData[0].joining_date) : ''
                            // }
                            max={new Date()}
                            placeholderText='Select date'
                          />
                          {errors.start_date &&
                            errors.start_date.type === 'required' &&
                            !getValues('start_date') && (
                              <p className='error text-danger mb-0'>Date is required.</p>
                            )}
                        </div>

                        <div className='col-xs-12 col-md-6 '>
                          <label className='form-label'>Mode of Communication *</label>
                          <select
                            className={clsx(
                              'form-select',
                              errors.communication ? 'is-invalid' : ''
                            )}
                            disabled={ViewEdit}
                            placeholder='Enter Project Type'
                            {...register('communication', {
                              required: true,
                              onChange: (e) => {
                                //   handleProjectType(e.target.value)
                                setValue('communication', e.target.value)
                              },
                            })}
                            aria-label='Default select example'
                          >
                            <option value=''>Select Communication Type</option>
                            <option value='Email'>Email</option>
                            <option value='Call'>Call</option>
                            <option value='Teams'>Teams</option>
                            <option value='Others'>Others</option>
                          </select>

                          {errors.communication &&
                            errors.communication.type === 'required' &&
                            !getValues('communication') && (
                              <p className='error text-danger mb-0'>Type is required.</p>
                            )}
                        </div>

                        <div className='col-xs-12 '>
                          <label className='form-label '>Brief Communication Details *</label>
                          <div>
                            <ReactQuill
                              theme='snow'
                              value={announcementDes}
                              // onChange={setAnnouncementDes}
                              onChange={handleAnnouncement}
                              placeholder={'Min 10 characters, Max 1000 characters'}
                              modules={{
                                toolbar: [
                                  [{header: [1, 2, 3, false]}],
                                  ['bold', 'italic', 'underline'],
                                  // ['link'],
                                  [{list: 'ordered'}, {list: 'bullet'}],
                                ],
                              }}
                            />
                            {/* <p className='error text-danger mb-0'>
                              {isValid &&
                              characterLength &&
                              (characterLength < 10 || characterLength > 1000)
                                ? 'min 10 characters'
                                : ''}
                            </p> */}
                            <p className='error text-danger mb-0'>
                              {isValid && characterLength && characterLength < 10
                                ? 'Min 10 characters'
                                : characterLength > 1000
                                ? 'Max 1000 characters'
                                : ''}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='d-flex justify-content-end  py-1 '>
                    <div>
                      <div className='d-flex gap-3 '>
                        {!HistoryId && (
                          <div>
                            <button
                              type='submit'
                              className='btn btn-primary'
                              disabled={
                                announcementDes.length == 0 ||
                                announcementDes == '<p><br></p>' ||
                                announcementDes == '<h1><br></h1>' ||
                                announcementDes == '<h2><br></h2>' ||
                                announcementDes == '<h2><br></h2>' ||
                                announcementDes == '<h3><br></h3>'
                              }
                            >
                              Submit
                            </button>
                          </div>
                        )}
                        {HistoryId && (
                          <div>
                            <button
                              type='submit'
                              className='btn btn-primary'
                              disabled={
                                announcementDes.length == 0 ||
                                announcementDes == '<p><br></p>' ||
                                announcementDes == '<h1><br></h1>' ||
                                announcementDes == '<h2><br></h2>' ||
                                announcementDes == '<h2><br></h2>' ||
                                announcementDes == '<h3><br></h3>'
                              }
                            >
                              Update
                            </button>
                          </div>
                        )}
                        <div>
                          {' '}
                          <button
                            type='button'
                            onClick={() => navigate(-1)}
                            className='btn btn-danger'
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </KTCardBody>
        </>
      )}
      <KTCardBody className='py-1 '>
        <div className='pt-3'>
          <div className='card card-custom card-flush mb-6'>
            <div className='card-body py-5'>
              <h3 className='text-gray-900 fs-3 fw-semibold  py-2'>Correspondence History</h3>
              <div className='row gy-4 '>
                <div className='col-12 m-0'></div>
                <div className='table-responsive '>
                  <table
                    id='kt_table_users'
                    className='table table-row-bordered table-row-gray-200 gy-2 gs-4 '
                    {...getTableProps()}
                  >
                    <thead>
                      <tr className='fw-semibold fs-7 text-gray-600 text-uppercase'>
                        <th onClick={() => sorting1('communicationdate')} className='  py-4'>
                          Date of Communication{' '}
                          <span>
                            {order1 === 'ASC' ? (
                              <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                            ) : (
                              <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                            )}
                          </span>
                        </th>
                        <th onClick={() => 'work_state'} className='  py-4'>
                          Mode of Communication{' '}
                        </th>
                        <th onClick={() => 'work_city'} className='  py-4'>
                          Description{' '}
                        </th>
                        {/* <th onClick={() => 'created_by_name'} className='  py-4'>
                          Added on{' '}
                        </th> */}
                        <th onClick={() => 'created_by_name'} className='  py-4'>
                          Added by{' '}
                        </th>
                        {!ViewEdit && CommentsHistory && CommentsHistory?.length > 0
                          ? CommentsHistory[0].updatedby == localStorage.getItem('userid') &&
                            CommentsHistory[0].edit_enable && (
                              <th onClick={() => 'createdate'} className='  py-4'>
                                Actions{' '}
                              </th>
                            )
                          : ''}
                      </tr>
                    </thead>
                    {CommentsHistory?.length > 0 ? (
                      <>
                        <tbody className='' {...getTableBodyProps()}>
                          {CommentsHistory.length > 0
                            ? CommentsHistory.map((user: any, index: number) => {
                                return (
                                  <tr
                                    key={index}
                                    className='namepage text-dark fw-semibold  fs-6 text-nowrap'
                                  >
                                    <td className=''>
                                      {user.communicationdate
                                        ? moment(user.communicationdate).format('MM/DD/YYYY')
                                        : '-'}
                                    </td>
                                    <td className='text-capitalize'>
                                      {user.mode_of_communication
                                        ? user.mode_of_communication
                                        : '-'}
                                    </td>
                                    <td className='tableTdBreakWord'>
                                      {/* {parse(props.descriptionprops ? props.descriptionprops : '')} */}
                                      {user.comments ? parse(user.comments) : '-'}
                                    </td>
                                    {/* <td className='text-capitalize'>
                                      {user.updatedate
                                        ? moment(user.updatedate).format('MM/DD/YYYY')
                                        : '-'}
                                    </td> */}
                                    <td className='text-capitalize'>
                                      {user.display_name ? user.display_name : '-'}
                                      <div>
                                        {user.updatedate
                                          ? moment(user.updatedate).format('MM/DD/YYYY HH:mm')
                                          : '-'}
                                      </div>
                                    </td>
                                    {!ViewEdit && index == 0
                                      ? user.updatedby == localStorage.getItem('userid') &&
                                        user.edit_enable && (
                                          <td className=''>
                                            <a
                                              onClick={() => {
                                                historyDetails(user.id)
                                              }}
                                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                            >
                                              <KTSVG
                                                path='/media/icons/duotune/art/art005.svg'
                                                className='svg-icon-3'
                                              />
                                            </a>
                                          </td>
                                        )
                                      : ''}
                                  </tr>
                                )
                              })
                            : ''}
                        </tbody>
                      </>
                    ) : (
                      ''
                    )}
                  </table>
                  {(CommentsHistory?.length == null || CommentsHistory?.length == 0) && (
                    <p className='text-center'> No Records Found</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </KTCardBody>

      <div className='d-flex flex-stack py-2'>
        {CommentsHistory?.length > 0 ? (
          <div>
            <Container className='p-0'>
              <Row className='align-items-center'>
                <Col xs='auto' className='pe-0'>
                  {' '}
                  <label className='form-label fw-semibold'>Rows Per Page</label>
                </Col>

                <Col>
                  <select
                    name='perPage'
                    className='form-select'
                    onChange={onChange}
                    value={perPage}
                  >
                    <option value='25'>25</option>
                    <option value='50'>50</option>
                    <option value='75'>75</option>
                    <option value={CommentsHistoryData}>All</option>
                  </select>
                </Col>
              </Row>
            </Container>
          </div>
        ) : null}

        <div>
          {CommentsHistory?.length > 0 ? (
            <ReactPaginate
              previousLabel={'<'}
              nextLabel={'>'}
              pageCount={pages}
              onPageChange={handlePageClick}
              containerClassName={'pagination justify-content-right'}
              activeClassName={'active'}
              breakLabel={'...'}
              pageClassName={'page-item'}
              pageLinkClassName={'page-link'}
              previousClassName={'page-item'}
              previousLinkClassName={'page-link'}
              nextClassName={'page-item'}
              nextLinkClassName={'page-link'}
              breakClassName={'page-item'}
              breakLinkClassName={'page-link'}
            />
          ) : null}
        </div>
      </div>
      {HistoryId && <ScrollTop />}
      <ToastContainer />
    </>
  )
}
export default CorrespondenceDetails
