import React, {useState} from 'react'
import {default as Apiservices} from '../../../../../../src/common/index'
import {useNavigate} from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'
import parse from 'html-react-parser'

export default function AdpAssociatePopup(props: any) {
  console.log(props, 'dbgjkfdg')
  console.log(props.checkAdpData, 'dbgjkfdg')
  //   const { closeModal,emailForProp,passwordForProp} = props;
  const {closeModal, close, customermessage, cancel} = props
  const navigate = useNavigate()
  const [show, setShow] = useState(true)
  const [adpDisabledd, setAdpDisabledd] = useState(false)

  const handleClose = () => {
    setShow(false)
  }

  const Announcement = async () => {
    const data: any = await Apiservices.markAsReadAnnouncement({
      id: props.announcement_list.id,
    })
    props.setAnnouncementOpenPoup(false)
  }

  const greetUser = async () => {
    props.setConsultant(props.checkAdpData)
    console.log(props.checkAdpData, 'Hi there, user!')
  }
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        // size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        backdrop='static'
      >
        <Modal.Header className='py-4'>
          <Modal.Title>
            <div className=' text-gray-800 fs-4 fw-semibold text-center'>Alert</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className=''>
          <>
            <div className=' text-gray-800 fs-5 fw-semibold text-transform-unset'>
             Provided candidate information will get updated with ADP Details
            </div>
          </>
        </Modal.Body>
        <Modal.Footer className='p-2 py-3'>
          <div className='row justify-content-end  '>
            <div className='col-auto'>
              <button className='btn btn-sm btn-light-danger' onClick={props.onCancel}>
                Close
              </button>
            </div>
            <div className='col-auto'>
              <button className='btn btn-sm btn-primary' onClick={greetUser}>
                OK
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}
