import React, {useMemo, useState, useEffect, useContext, useRef} from 'react'
import ThingsContext from '../../../modules/apps/masters/commonicons/GlobalContext'
import ValidContext from '../../../modules/apps/masters/commonicons/ValidateContext'
import {default as Apiservices} from '../../../../common/index'
import {useIntl} from 'react-intl'
import ReactPaginate from 'react-paginate'
import {useForm} from 'react-hook-form'
import {Container, Row, Col} from 'react-bootstrap'
import 'reactjs-popup/dist/index.css'
import clsx from 'clsx'
import {KTCardBody} from '../../../../_metronic/helpers'
import {Toolbar1} from '../../../../_metronic/layout/components/toolbar/Toolbar1'
import BackDrop from '../../../modules/apps/timesheetentry/ComponentBackdrop'
import {PageTitle} from '../../../../_metronic/layout/core'
import {useTable, ColumnInstance} from 'react-table'
import moment from 'moment'
import {Link} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {ManageListClients} from '../../../modules/apps/user-management/users-list/table/columns/_columns'
import {UsersListLoading} from '../../../modules/apps/user-management/users-list/components/loading/UsersListLoading'
import {UsersListPagination} from '../../../modules/apps/user-management/users-list/components/pagination/UsersListPagination'
import {
  useQueryResponseData,
  useQueryResponseLoading,
} from '../../../modules/apps/user-management/users-list/core/QueryResponseProvider'
import {ClearButton, Typeahead} from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import Chart from 'react-apexcharts'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {Tooltip} from '../../../modules/apps/timesheetentry/ComponentTooltip'
import Modal from 'react-bootstrap/Modal'
import MultiDatePicker from 'react-multi-date-picker'
import {yupResolver} from '@hookform/resolvers/yup'
import * as Yup from 'yup'

type FormData = {
  client: string
  dba: string
  ccdb_id: string
  qb_client_name: string
  qb_client_id: string
  id: number
  user_id: number
  user: number
  select_Year: any
  org_name: any
  employee_type: string
  project_type: string
  start_date: any
}

const MarginComplianceReports = (props: any) => {
  const validationSchema = Yup.object().shape({
    start_date: Yup.string().required('Date Of Joining is required').nullable(),
  })
  const {
    register,
    setValue,
    getValues,
    reset,
    handleSubmit,
    formState: {errors},
  } = useForm<FormData>({
    resolver: yupResolver(validationSchema),
  })

  const users = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => users, [users])
  const columns = useMemo(() => ManageListClients, [])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })
  const [fullscreen, setFullscreen] = useState<any>(true)
  const [show, setShow] = useState(true)

  const handleClose = () => {
    setFullscreen(false)
    setShow(false)
    props.cancel(false)
    // props.view_open(false)
    // props.setViewOpen(false)
  }
  const intl = useIntl()
  const [fromclient, setFromClient] = useState<any | []>(true)
  const [perPage, setPerPage] = useState(25)
  const [disableQuickBooks, setDisableQuickBooks] = useState(false)
  const [disablecustomerdatabynameQuickBooks, setDisablecustomerdatabynameQuickBooks] =
    useState(false)
  const [page, setPage] = useState(1)
  const [showBackDrop, setShowBackDrop] = useState(false)
  const [pages, setPages] = useState(1)
  const [selected, setSelected] = useState([])
  const [selectConsultant, setSelectConsultant] = useState([])
  const [select_consultant, setSelectconsultant] = useState<any | []>([])
  const typeaheadRef: any = useRef<HTMLInputElement>(null)
  const typeaheadRef1: any = useRef<HTMLInputElement>(null)
  const typeaheadRef2: any = useRef<HTMLInputElement>(null)
  const typeaheadRef3: any = useRef<HTMLInputElement>(null)
  const typeaheadRef4: any = useRef<HTMLInputElement>(null)
  const typeaheadRef5: any = useRef<HTMLInputElement>(null)
  const typeaheadRef6: any = useRef<HTMLInputElement>(null)
  const typeaheadRef7: any = useRef<HTMLInputElement>(null)
  const typeaheadRef8: any = useRef<HTMLInputElement>(null)
  const [ComplianceBusinessUnit, setComplianceBusinessUnit] = useState<any | []>([])
  const [BusinessUnitsList, setBusinessUnitsList] = useState<any | []>([])
  const [BusinessUnit, setBusinessUnit] = useState<any | []>([])
  const [country1, setCountry1] = useState<any | []>([])
  const [candidateList, setCandidateList] = useState<any | []>([])
  const calendarRef: any = useRef<HTMLInputElement>(null)
  const [DateValue1, setDateValue1] = useState<any>([])
  const [MultipleYears, setMultipleYears] = useState<any>([])
  const [MultipleMonths, setMultipleMonths] = useState<any>([])
  const [ProjectDetails, setProjectDetails] = useState<any>()
  const [ProjectTotalReportData, setProjectTotalReportData] = useState<any | []>([])
  const [MonthValidate, setMonthValidate] = useState(false)

  // ref.current.close()
  const [AllOptions, setAllOptions] = useState<any | []>([
    {
      label: 'Select All',
      value: 'All',
    },
    {
      label: 'W2',
      value: 'W2',
    },
    {
      label: 'C2C',
      value: 'C2C',
    },
    // {
    //   label: 'Internal',
    //   value: 'Internal',
    // },
    {
      label: '1099',
      value: '1099',
    },
    {
      label: 'Offshore',
      value: 'Offshore',
    },
  ])
  // const typeaheadRef = useRef(null)
  const [sort, setSort] = useState<any | []>(false)
  const [filterstatus, setFilterStatus] = useState<any | []>('ACTIVE')
  const [error, setError] = useState([])
  const [filterReportStaus, setFilterReportStaus] = useState<any | []>([])
  const [orgList, setOrgList] = useState<any | []>([])
  const [employeeList, setEmployeeList] = useState<any | []>([])
  const [clientlist_data, setClient_Data] = useState<any | []>([])
  const [search, setSearch] = useState('')
  const [clientfilteropen, setClientFilterOpen] = useState<any | []>(false)
  const [resetvalues, setResetvalues] = useState(true)
  const [date, setDate] = useState('')
  const [selectYear, setSelectYear] = useState<any>(new Date())
  const [years, setYears] = useState<any>([])
  const [OrganizationReportData, setOrganizationReportData] = useState<any | []>([])
  const [OrganizationReportLineData, setOrganizationReportLineData] = useState<any | []>([])
  const [ProjectReportData, setProjectReportData] = useState<any | []>([])
  const [ProjectReportDataCount, setProjectReportDataCount] = useState<any | []>([])
  const [chartData, setChartData] = useState<any | []>([])
  const [org_name, setOrgName] = useState('')
  const [candidate, setCandidate] = useState<any | []>([])
  const [selectMonth, setSelectMonth] = useState<any>([])
  const [countries1, setCountries1] = useState<any | []>([])
  const [selectConsultant1, setSelectConsultant1] = useState([])
  const [candidateList1, setCandidateList1] = useState<any | []>([])
  const [select_consultant1, setSelectconsultant1] = useState<any | []>([])
  const [flag, setFlag] = useState(false)

  const tokenHeader: any = useContext(ThingsContext)
  const renderThings = async () => {
    tokenHeader.getCookie()
  }

  const tokenHeader1: any = useContext(ValidContext)
  const renderThings1 = async () => {
    tokenHeader1.getValidate()
  }

  useEffect(() => {
    renderThings()
    AllOrgList()
    // handleConsultant()
    setSelectYear(selectYear)
    // setSelectYear(new Date(moment().subtract(1, 'month').format('YYYY')))
    const MonthNumberDefault = [
      {
        label: moment(new Date()).subtract(1, 'month').format('MMMM'),
        value: moment(new Date()).subtract(1, 'month').format('M'),
      },
    ]
    setSelectMonth(MonthNumberDefault)
    // let currYr: any = new Date().getFullYear()
    // setSelectYear(currYr)
    // let pastYr = 2013
    // while (currYr >= pastYr) {
    //   years.push(currYr)
    //   currYr -= 1
  }, [])
  useEffect(() => {
    // get_business_units_list()
  }, [])
  const get_business_units_list = async () => {
    const res: any = await Apiservices.get_business_units_list()
    if (res && res.status == 200) {
      setBusinessUnitsList(res.data.data)
    }
  }
  const handleConsultant = async (consult_Name?: any) => {
    const response: any = await Apiservices.getconsultantbyname()
    setCandidate(response?.data?.data)
  }
  const AllOrgList = async () => {
    setShowBackDrop(true)
    let y = JSON.parse(localStorage.getItem('bu_list') || '[]')

    let n = y.filter((element: any) => element.roleName?.toLowerCase() == 'accounts')
    let business: any = localStorage.getItem('role_Name')
    let numArray: any = business?.split(',')
    let uniqueArr: any = []

    const res: any = await Apiservices.get_business_units_list()
    if (res && res.status == 200) {
      if (res.data.data.length > 0) {
        let obj = {name: 'Deselect All', value: 'empty'}
        if (numArray?.includes('admin')) {
          let x = res.data.data.filter((test: any) => uniqueArr.push(test))
          setBusinessUnitsList([obj, ...uniqueArr])
        } else {
          for (let each of res.data.data) {
            for (let item of n) {
              if (each.business_unit_id == item.buId) {
                uniqueArr.push(each)
              }
            }
          }
          setBusinessUnitsList([obj, ...uniqueArr])
        }
      }
    }

    let business1 = uniqueArr?.map((val: any) => {
      return val.business_unit_id
    })
    let business2 = business1?.filter((temp: any) => `${temp}`).join(',')
    let yesterday = moment(new Date()).subtract(1, 'month').format('M')
    setComplianceBusinessUnit(uniqueArr)

    const article1 = {
      // year: selectYear ? moment(selectYear).format('YYYY') : '',
      year: selectYear ? moment(selectYear).subtract(1, 'month').format('YYYY') : '',
      month: [Number(yesterday)], // optional  // (1=January, 12 = December )Pass null for all months data
      employee_type: null,
      user_id: null, // optional
      project_id: null, // optional
      is_placement_project: null,
      business_unit_id: null,
    }

    let arr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
    let ProjObj: any = {
      year: ['2024'],
      months: [arr],
    }
    setProjectDetails(ProjObj)

    var ProjObj1: any = []
    for (let i = 0; i < ProjObj?.year?.length; i++) {
      ProjObj1.push({
        year: ProjObj.year[i],
        months: ProjObj.months[i],
      })
    }

    let a = [new Date(`${2024}-${'January'}-01`), new Date(`${2024}-${'December'}-01`)]
    setDateValue1(a)
    setValue('start_date', a)

    const article2 = {
      // year: selectYear ? moment(selectYear).format('YYYY') : '',
      // year: selectYear ? moment(selectYear).subtract(1, 'month').format('YYYY') : '',
      date: ProjObj1,
      employee_type: null,
      user_id: null, // optional
      // business_unit_id: null,
      business_unit_id: business2 ? business1 : '',
      pagenumber: 1,
      pagesize: 25,
    }
    const response1: any = await Apiservices.get_yearly_margin_report(article2) //for graph
    // const response: any = await Apiservices.get_project_wise_margin_report(article1) //for table
    // if (response && response.isError == false) {
    //   setProjectReportData(response.data)
    // }
    if (response1 && response1.isError == false) {
      setProjectTotalReportData(response1.total_data)
      setPages(Math.ceil(response1?.count[0]?.count / Number(perPage)))
      setProjectReportDataCount(response1?.count[0]?.count)
      let AllMonths = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ]
      let test = response1.total_data // without business unit
      let AllData = [test]
      if (response1 && response1.data?.length > 0) {
        for (let each of business1) {
          let x = response1.data?.filter((temp: any) => temp.business_unit_id == each)
          AllData.push(x)
        }
      }
      let finalData = []
      let data2: any = []
      let data3: any = []
      let data4: any = []
      for (let each of AllData) {
        let obj: any = {}
        let data1: any = []
        for (let item of each) {
          data2.push(item)
          obj.name = item.business_unit_name
            ? item.business_unit_name
            : localStorage.getItem('org_name')
          obj.color = item.color ? item.color : '#0000ff'

          if (item.monthly_margin_amount < 1000) {
            data1.push(item.monthly_margin_amount)
          } else if (item.monthly_margin_amount >= 1000 && item.monthly_margin_amount < 1000000) {
            data1.push((item.monthly_margin_amount / 1000).toFixed(3))
          } else if (item.monthly_margin_amount >= 1000000) {
            data1.push((item.monthly_margin_amount / 1000000).toFixed(3))
          }
          // data1.push(item.monthly_margin_amount)
        }
        obj.data = data1
        finalData.push(obj)
      }

      let finalData1 = []
      for (let each of AllData) {
        let obj: any = {}
        let data1: any = []
        for (let item of each) {
          obj.name = item.business_unit_name
            ? item.business_unit_name
            : localStorage.getItem('org_name')
          obj.color = item.color ? item.color : '#0000ff'

          data1.push(item.monthly_margin_percent)
        }
        obj.data = data1
        finalData1.push(obj)
      }

      for (let each of AllMonths) {
        let x1 = data2?.filter((temp: any) => temp.monthName == each)
        data3.push(x1)
      }
      for (let item of data3) {
        for (let item1 of item) {
          data4.push(item1)
        }
      }
      setProjectReportData(data4)
      setOrganizationReportData({
        options: {
          chart: {
            type: 'bar',
            height: 350,
            stacked: true,
            stackType: '100%',
            toolbar: {
              show: false,
            },
            zoom: {
              enabled: false,
            },
          },

          xaxis: {
            title: {
              text: 'Margin Amount',
              style: {
                fontSize: '15px',
                fontWeight: 600,
              },
            },
            categories: response1.total_data.map(
              (each: any) => `${moment(each.monthName, 'MMMM').format('MMM')}  ${each.year}`
            ),
          },
          // xaxis: {
          //   categories: AllMonths,
          // },

          plotOptions: {
            bar: {
              horizontal: true,
            },
          },
          stroke: {
            width: 1,
            colors: ['#fff'],
          },
          // title: {
          //   text: 'Margin Amount Graph',
          // },

          tooltip: {
            y: {
              formatter: function (val: any) {
                if (val < 1000) {
                  return val
                } else if (val >= 1000 && val < 1000000) {
                  return val + 'K'
                } else if (val >= 1000000) {
                  return val + 'M'
                }
                // return val + 'K'
              },
            },
          },
          fill: {
            opacity: 1,
          },
          yaxis: {
            title: {
              text: 'Month',
              style: {
                fontSize: '15px',
                fontWeight: 600,
              },
            },
          },
          legend: {
            position: 'bottom',
            horizontalAlign: 'center',
            offsetX: 40,
          },
        },
        series: finalData,
      })

      setOrganizationReportLineData({
        options: {
          chart: {
            height: 350,
            type: 'line',
            toolbar: {
              show: false,
            },
            zoom: {
              enabled: false,
            },
          },
          xaxis: {
            title: {
              text: 'Month',
              floating: false,
            },
            categories: response1.total_data.map(
              (each: any) => `${moment(each.monthName, 'MMMM').format('MMM')}  ${each.year}`
            ),
          },
          // dataLabels: {
          //   enabled: true,
          // },

          dataLabels: {
            enabled: true,
            enabledOnSeries: undefined,
            formatter: function (val: any, opts: any) {
              return val
            },
            style: {
              fontSize: '10px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 'bold',
              colors: undefined,
            },
          },
          stroke: {
            curve: 'straight',
          },
          markers: {
            size: 1,
          },

          // title: {
          //   text: 'Margin Percentage Graph',
          //   align: 'left',
          // },

          yaxis: {
            title: {
              text: '% Margin',
              style: {
                fontSize: '15px',
                fontWeight: 600,
              },
            },
          },
          tooltip: {
            theme: false,
          },
        },
        // series: [
        //   {
        //     name: '% Margin',
        //     type: 'line',
        //     data: orgReport.map((item: any) => item.monthly_margin_percent),
        //   },
        //   {
        //     name: 'Margin Amount in (M) ',
        //     type: 'column',
        //     // data: orgReport.map((item: any) => item.monthly_margin_amount),
        //     data: orgReport.map((item: any) => {
        //       if (item.monthly_margin_amount >= 0) {
        //         return (item.monthly_margin_amount / 1000000).toFixed(0)
        //       } else {
        //         return item.monthly_margin_amount
        //       }
        //     }),
        //   },
        // ],
        series: finalData1,
      })
    }
    setShowBackDrop(false)
  }

  const yearSelect = (year: any) => {
    setSelectYear(year)
    setDate('')
  }
  //To get all organizations
  // const OrganizationList = async () => {
  //   const data: any = await Apiservices.getOrganizationsList()
  //   if (data?.data?.isError == false) {
  //     setOrgList(data.data.data)
  //   }
  // }
  const handlePageClick = (event: any) => {
    let page = event.selected
    let selected = page + 1
    setPage(selected)
    // getallprojects(mainTableData, selected, perPage)
    handleClick(selected, perPage)
  }

  const onChange = async (e: any) => {
    const {name, value} = e.target
    if (name == 'perPage') {
      setPerPage(value)
      setPage(1)
      // getallprojects(mainTableData, 1, value)
      handleClick(1, value)
    }
  }

  // const handleClick = async () => {
  const handleClick = async (page: any, perPage: any) => {
    if (getValues('start_date')?.length == 2) {
      setMonthValidate(false)
    } else {
      setMonthValidate(true)
    }
    if (DateValue1?.length == 2) {
      setShowBackDrop(true)
      // setFlag(true)
      let yesterday = moment(new Date()).subtract(1, 'month').format('M')

      let stat1 = selectConsultant1.map((val: any) => {
        return val.value
      })
      let stat2 = stat1.filter((temp: any) => `${temp}`).join(',')
      let employee = select_consultant1.map((value: any) => {
        return Number(value.id)
      })
      let employee1 = employee.filter((temp: any) => `${temp}`).join(',')
      const MonthNumberDefault = [
        {
          label: moment(new Date()).subtract(1, 'month').format('MMMM'),
          value: moment(new Date()).subtract(1, 'month').format('M'),
        },
      ]
      let business1 = ComplianceBusinessUnit.map((val: any) => {
        return val.business_unit_id
      })
      let business2 = business1.filter((temp: any) => `${temp}`).join(',')
      setSelectMonth(MonthNumberDefault)
      setBusinessUnit(ComplianceBusinessUnit)
      setSelectConsultant(selectConsultant1)
      setValue('project_type', '')
      setSelectconsultant(select_consultant1)
      setCountries1([])

      const article1 = {
        year: selectYear ? moment(selectYear).format('YYYY') : '',
        month: [Number(yesterday)], // optional  // (1=January, 12 = December )Pass null for all months data
        employee_type: stat2 ? stat1 : null,
        user_id: employee1 ? employee : null, // optional
        project_id: null, // optional
        is_placement_project: null,
        business_unit_id: business2 ? business1 : '',
      }
      var ProjObj: any = []
      for (let i = 0; i < ProjectDetails?.year?.length; i++) {
        ProjObj.push({
          year: ProjectDetails.year[i],
          months: ProjectDetails.months[i],
        })
      }
      const article2 = {
        // year: selectYear ? moment(selectYear).format('YYYY') : '',
        date: ProjObj,
        employee_type: stat2 ? stat1 : null,
        user_id: employee1 ? employee : null,
        business_unit_id: business2 ? business1 : '',
        pagenumber: page,
        pagesize: Number(perPage),
      }
      const response1: any = await Apiservices.get_yearly_margin_report(article2) //for graph
      // const response: any = await Apiservices.get_project_wise_margin_report(article1) //for table

      // if (response && response.isError == false) {
      //   setProjectReportData(response.data)
      // }
      if (response1 && response1.isError == false) {
        setProjectTotalReportData(response1.total_data)
        setPages(Math.ceil(response1?.count[0]?.count / Number(perPage)))
        setProjectReportDataCount(response1?.count[0]?.count)
        let AllMonths = [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December',
        ]

        let orgReport = response1.data //with business data
        let test = response1.total_data // without business unit
        let AllData = [test]
        if (response1 && response1.data?.length > 0) {
          for (let each of business1) {
            let x = response1.data?.filter((temp: any) => temp.business_unit_id == each)
            AllData.push(x)
          }
        }
        let finalData = []
        let data2: any = []
        let data3: any = []
        let data4: any = []
        for (let each of AllData) {
          let obj: any = {}
          let data1: any = []
          for (let item of each) {
            data2.push(item)
            obj.name = item.business_unit_name
              ? item.business_unit_name
              : localStorage.getItem('org_name')
            obj.color = item.color ? item.color : '#0000ff'

            if (item.monthly_margin_amount < 1000) {
              data1.push(item.monthly_margin_amount)
            } else if (item.monthly_margin_amount >= 1000 && item.monthly_margin_amount < 1000000) {
              data1.push((item.monthly_margin_amount / 1000).toFixed(3))
            } else if (item.monthly_margin_amount >= 1000000) {
              data1.push((item.monthly_margin_amount / 1000000).toFixed(3))
            }
            // data1.push(item.monthly_margin_amount)
          }
          obj.data = data1
          finalData.push(obj)
        }
        let finalData1 = []
        for (let each of AllData) {
          let obj: any = {}
          let data1: any = []
          for (let item of each) {
            obj.name = item.business_unit_name
              ? item.business_unit_name
              : localStorage.getItem('org_name')
            obj.color = item.color ? item.color : '#0000ff'

            data1.push(item.monthly_margin_percent)
          }
          obj.data = data1
          finalData1.push(obj)
        }
        for (let each of response1.total_data) {
          let x1 = data2?.filter((temp: any) => temp.monthName == each.monthName)
          data3.push(x1)
        }
        for (let item of data3) {
          for (let item1 of item) {
            data4.push(item1)
          }
        }
        setProjectReportData(data4)

        setOrganizationReportData({
          options: {
            chart: {
              type: 'bar',
              height: 350,
              stacked: true,
              stackType: '100%',
              toolbar: {
                show: false,
              },
              zoom: {
                enabled: false,
              },
            },
            xaxis: {
              title: {
                text: 'Margin Amount',
                style: {
                  fontSize: '15px',
                  fontWeight: 600,
                },
              },
              categories: response1.total_data.map(
                (each: any) => `${moment(each.monthName, 'MMMM').format('MMM')}  ${each.year}`
              ),
            },
            // xaxis: {
            //   categories: AllMonths,
            // },

            plotOptions: {
              bar: {
                horizontal: true,
              },
            },
            stroke: {
              width: 1,
              colors: ['#fff'],
            },
            // title: {
            //   text: 'Margin Amount Graph',
            // },

            tooltip: {
              y: {
                formatter: function (val: any) {
                  if (val < 1000) {
                    return val
                  } else if (val >= 1000 && val < 1000000) {
                    return val + 'K'
                  } else if (val >= 1000000) {
                    return val + 'M'
                  }
                  // return val + 'K'
                },
              },
            },

            fill: {
              opacity: 1,
            },
            yaxis: {
              title: {
                text: 'Month',
                style: {
                  fontSize: '15px',
                  fontWeight: 600,
                },
              },
            },
            legend: {
              position: 'bottom',
              horizontalAlign: 'center',
              offsetX: 40,
            },
          },

          series: finalData,
        })

        setOrganizationReportLineData({
          options: {
            chart: {
              height: 350,
              type: 'line',
              toolbar: {
                show: false,
              },
              zoom: {
                enabled: false,
              },
            },
            xaxis: {
              title: {
                text: 'Month',
                floating: false,
              },
              categories: response1.total_data.map(
                (each: any) => `${moment(each.monthName, 'MMMM').format('MMM')}  ${each.year}`
              ),
            },
            // dataLabels: {
            //   enabled: true,
            // },

            dataLabels: {
              enabled: true,
              enabledOnSeries: undefined,
              formatter: function (val: any, opts: any) {
                return val
              },
              style: {
                fontSize: '10px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 'bold',
                colors: undefined,
              },
            },
            stroke: {
              curve: 'straight',
            },
            markers: {
              size: 1,
            },

            // title: {
            //   text: 'Margin Percentage Graph',
            //   align: 'left',
            // },

            yaxis: {
              title: {
                text: '% Margin',
                style: {
                  fontSize: '15px',
                  fontWeight: 600,
                },
              },
            },
            tooltip: {
              theme: false,
            },
          },
          series: finalData1,
        })
      }
      setShowBackDrop(false)
    }
  }
  const options2: any = [
    {
      label: 'W2',
      value: 'W2',
    },
    {
      label: 'C2C',
      value: 'C2C',
    },
    // {
    //   label: 'Internal',
    //   value: 'Internal',
    // },
    {
      label: '1099',
      value: '1099',
    },
    {
      label: 'Offshore',
      value: 'Offshore',
    },
  ]

  const options3: any = [
    {
      label: 'January',
      value: '1',
    },
    {
      label: 'February',
      value: '2',
    },
    {
      label: 'March',
      value: '3',
    },
    {
      label: 'April',
      value: '4',
    },
    {
      label: 'May',
      value: '5',
    },
    {
      label: 'June',
      value: '6',
    },
    {
      label: 'July',
      value: '7',
    },
    {
      label: 'August',
      value: '8',
    },
    {
      label: 'September',
      value: '9',
    },
    {
      label: 'October',
      value: '10',
    },
    {
      label: 'November',
      value: '11',
    },
    {
      label: 'December',
      value: '12',
    },
  ]

  const options4: any = [
    {
      label: 'W2',
      value: 'W2',
    },
    {
      label: 'C2C',
      value: 'C2C',
    },
    // {
    //   label: 'Internal',
    //   value: 'Internal',
    // },
    {
      label: '1099',
      value: '1099',
    },
    {
      label: 'Offshore',
      value: 'Offshore',
    },
  ]
  const user = (candidate: any) => {
    if (candidate === null) return ''
    else {
      let str = ''

      candidate.split(' ').forEach((ele: any, i: any) => {
        str += (i == 0 ? '' : ' ') + ele.charAt(0).toUpperCase() + ele.slice(1)
      })

      return str
    }
  }
  const singleDateSelection = async (date: any) => {
    setSelectYear(date)
    setDate('')
  }
  const handleOrgName = (org: any) => {
    setValue('org_name', org)
    setOrgName(org)
  }

  const handleProjectType = (e: any) => {
    setValue('project_type', e.target.value)
  }
  const handleClick1 = async () => {
    setFlag(true)
    let yesterday = moment(new Date()).subtract(1, 'month').format('M')
    let stat1 = selectConsultant.map((val: any) => {
      return val.value
    })
    let stat2 = stat1.filter((temp: any) => `${temp}`).join(',')
    let business1 = BusinessUnit.map((val: any) => {
      return val.business_unit_id
    })
    let business2 = business1.filter((temp: any) => `${temp}`).join(',')

    let month1 = selectMonth.map((temp: any) => {
      return Number(temp.value)
    })
    let month12 = month1.filter((temp: any) => `${temp}`).join(',')
    let employee = select_consultant.map((value: any) => {
      return Number(value.id)
    })
    let employee1 = employee.filter((temp: any) => `${temp}`).join(',')
    let project = countries1.map((value: any) => {
      return Number(value.id)
    })
    let project1 = project.filter((temp: any) => `${temp}`).join(',')

    const article1 = {
      year: selectYear ? moment(selectYear).format('YYYY') : '',
      month: month12 ? month1 : [Number(yesterday)], // optional  // (1=January, 12 = December )Pass null for all months data
      employee_type: stat2 ? stat1 : null,
      user_id: employee1 ? employee : null, // optional
      project_id: project1 ? project : null, // optional
      is_placement_project: getValues('project_type') ? Number(getValues('project_type')) : null,
      business_unit_id: business2 ? business1 : '',
    }
    setShowBackDrop(true)
    const response: any = await Apiservices.get_project_wise_margin_report(article1) //for table

    if (response && response.isError == false) {
      setProjectReportData(response.data)
    }
    setShowBackDrop(false)
  }
  const exporthandleClick = async (e: any) => {
    setShowBackDrop(true)
    let stat1 = selectConsultant1.map((val: any) => {
      return val.value
    })
    let stat2 = stat1.filter((temp: any) => `${temp}`).join(',')

    let employee = select_consultant1.map((value: any) => {
      return Number(value.id)
    })
    let employee1 = employee.filter((temp: any) => `${temp}`).join(',')
    let business1 = ComplianceBusinessUnit.map((val: any) => {
      return val.business_unit_id
    })
    let business2 = business1.filter((temp: any) => `${temp}`).join(',')

    var ProjObj: any = []
    for (let i = 0; i < ProjectDetails?.year?.length; i++) {
      ProjObj.push({
        year: ProjectDetails.year[i],
        months: ProjectDetails.months[i],
      })
    }

    const article2 = {
      // year: selectYear ? moment(selectYear).format('YYYY') : '',
      date: ProjObj,
      employee_type: stat2 ? stat1 : null,
      user_id: employee1 ? employee : null, // optional
      business_unit_id: business2 ? business1 : '',
    }
    const response: any = await Apiservices.export_yearly_margin_report(article2)
    var link = document.createElement('a')
    // If you don't know the name or want to use
    // the webserver default set name = ''
    link.setAttribute('download', 'Downloaded File')
    link.href = response?.data?.blobUrl
    document.body.appendChild(link)
    link.click()
    link.remove()
    setShowBackDrop(false)
  }

  const exporthandleClick1 = async (e: any) => {
    setShowBackDrop(true)
    let yesterday = moment(new Date()).subtract(1, 'month').format('M')
    let stat1 = selectConsultant.map((val: any) => {
      return val.value
    })
    let stat2 = stat1.filter((temp: any) => `${temp}`).join(',')
    let business1 = BusinessUnit.map((val: any) => {
      return val.business_unit_id
    })
    let business2 = business1.filter((temp: any) => `${temp}`).join(',')

    let month1 = selectMonth.map((temp: any) => {
      return Number(temp.value)
    })
    let month12 = month1.filter((temp: any) => `${temp}`).join(',')
    let employee = select_consultant.map((value: any) => {
      return Number(value.id)
    })
    let employee1 = employee.filter((temp: any) => `${temp}`).join(',')
    let project = countries1.map((value: any) => {
      return Number(value.id)
    })
    let project1 = project.filter((temp: any) => `${temp}`).join(',')

    const article1 = {
      year: selectYear ? moment(selectYear).format('YYYY') : '',
      month: month12 ? month1 : [Number(yesterday)], // optional  // (1=January, 12 = December )Pass null for all months data
      employee_type: stat2 ? stat1 : null,
      user_id: employee1 ? employee : null, // optional
      project_id: project1 ? project : null, // optional
      is_placement_project: getValues('project_type') ? Number(getValues('project_type')) : null,
      business_unit_id: business2 ? business1 : '',
    }
    const response: any = await Apiservices.export_project_wise_margin_report(article1)
    var link = document.createElement('a')
    // If you don't know the name or want to use
    // the webserver default set name = ''
    link.setAttribute('download', 'Downloaded File')
    link.href = response?.data?.blobUrl
    document.body.appendChild(link)
    link.click()
    link.remove()
    setShowBackDrop(false)
  }
  const handleCountries1 = async (country1: any) => {
    // if (country1 && country1.length >= 2) {
    const response: any = await Apiservices.getprojectbyname({country_name: country1})
    if (response?.data?.data !== null) {
      setCountry1(response && response.data && response.data.data ? response?.data?.data : '')
    }
    // }
  }

  const selectedCountries1 = async (state: any) => {
    setCountries1(state)
  }
  const handleFilterConsultant = async (consult_Name: any) => {
    // let filteredConsultant = candidate?.filter((each: any) =>
    //   each?.full_name?.toLowerCase()?.includes(consult_Name?.toLowerCase())
    // )
    // setCandidateList(filteredConsultant)
    const response: any = await Apiservices.getconsultantbyname()
    setCandidateList(response?.data?.data)
  }

  const selectedConsultant = async (consult_name: any) => {
    setSelectconsultant(consult_name)
  }

  const handleFilterConsultant1 = async (consult_Name: any) => {
    const response: any = await Apiservices.getconsultantbyname()
    setCandidateList1(response?.data?.data)
  }
  const selectedConsultant1 = async (consult_name: any) => {
    setSelectconsultant1(consult_name)
    setSelectconsultant(consult_name)
  }
  // const handleConsultantType = (select: any) => {
  //   setSelectConsultant1(select)
  //   setSelectConsultant(select)
  // }

  const handleConsultantType = (selected: any) => {
    if (selected.length > 0) {
      for (let each of selected) {
        if (each.value == 'All') {
          let x = AllOptions.filter((temp: any) => temp.value != 'All' && temp.value != 'empty')
          setSelectConsultant1(x)
          setSelectConsultant(x)
          AllOptions.shift({
            label: 'Select All',
            value: 'All',
          })
          let abc = AllOptions.find((item: any) => item.value == 'empty')
          if (!abc) {
            AllOptions.push({
              label: 'Deselect All',
              value: 'empty',
            })
            setAllOptions(AllOptions)
          }
        } else if (each.value == 'empty') {
          setSelectConsultant1([])
          setSelectConsultant([])
          let cde = AllOptions.find((item: any) => item.value == 'All')
          if (!cde) {
            AllOptions.unshift({
              label: 'Select All',
              value: 'All',
            })
            // setAllOptions(AllOptions)
          }
          AllOptions.pop({
            label: 'Deselect All',
            value: 'empty',
          })
        } else {
          setSelectConsultant1(selected)
          setSelectConsultant(selected)
          let abc = AllOptions.find((item: any) => item.value == 'empty')
          let cde = AllOptions.find((item: any) => item.value == 'All')
          if (!abc) {
            AllOptions.push({
              label: 'Deselect All',
              value: 'empty',
            })
            // setAllOptions(AllOptions)
          }
          if (!cde) {
            AllOptions.unshift({
              label: 'Select All',
              value: 'All',
            })
            setAllOptions(AllOptions)
          }
          let check_drop = selected.find((item: any) => item.value == 'All')
          let check_drop1 = selected.find((item: any) => item.value == 'empty')

          if (!check_drop && !check_drop1) {
            let test = AllOptions.filter(
              (id: any) => !selected.some((x: any) => x.value == id.value)
            )
            if (test.length == 2) {
              let sample = test.filter((res: any) => res.value == 'All' || res.value == 'empty')
              AllOptions.shift({
                label: 'Select All',
                value: 'All',
              })
            }
          }
        }
      }
    } else {
      setSelectConsultant1(selected)
      setSelectConsultant(selected)
      AllOptions.pop({
        label: 'Deselect All',
        value: 'empty',
      })

      let consultantReset = AllOptions.find((item: any) => item.value == 'All')
      if (!consultantReset) {
        AllOptions.unshift({
          label: 'Select All',
          value: 'All',
        })
      }
    }
    // setSelectConsultant1(selected)
    // setSelectConsultant(selected)
  }

  // const handleBusinessUnit = (select: any) => {
  //   setComplianceBusinessUnit(select)
  //   setBusinessUnit(select)
  // }
  const handleBusinessUnit = (selected: any) => {
    // setBusinessUnit(selected)

    if (selected.length > 0) {
      for (let each of selected) {
        if (each.value == 'All') {
          let x = BusinessUnitsList.filter(
            (temp: any) => temp.value != 'All' && temp.value != 'empty'
          )
          setComplianceBusinessUnit(x)
          setBusinessUnit(x)
          BusinessUnitsList.shift({
            name: 'Select All',
            value: 'All',
          })
          let abc = BusinessUnitsList.find((item: any) => item.value == 'empty')
          if (!abc) {
            BusinessUnitsList.push({
              name: 'Deselect All',
              value: 'empty',
            })
            setBusinessUnitsList(BusinessUnitsList)
          }
        } else if (each.value == 'empty') {
          setComplianceBusinessUnit([])
          setBusinessUnit([])
          let cde = BusinessUnitsList.find((item: any) => item.value == 'All')

          if (!cde) {
            BusinessUnitsList.unshift({
              name: 'Select All',
              value: 'All',
            })
            // setBusinessUnitsList(BusinessUnitsList)
          }
          // BusinessUnitsList.pop({
          //   name: 'Deselect All',
          //   value: 'empty',
          // })
          let cde1 = BusinessUnitsList.find((item: any) => item.value == 'empty')
          if (cde1) {
            BusinessUnitsList.splice(
              BusinessUnitsList.findIndex((item: any) => item.value == 'empty'),
              1
            )
          }
        } else {
          setComplianceBusinessUnit(selected)
          setBusinessUnit(selected)
          let abc = BusinessUnitsList.find((item: any) => item.value == 'empty')
          let cde = BusinessUnitsList.find((item: any) => item.value == 'All')

          if (!abc) {
            BusinessUnitsList.push({
              name: 'Deselect All',
              value: 'empty',
            })
            setBusinessUnitsList(BusinessUnitsList)
          }
          if (!cde) {
            BusinessUnitsList.unshift({
              name: 'Select All',
              value: 'All',
            })
            setBusinessUnitsList(BusinessUnitsList)
          }

          let check_drop = selected.find((item: any) => item && item.value && item.value == 'All')
          let check_drop1 = selected.find(
            (item: any) => item && item.value && item.value == 'empty'
          )
          if (!check_drop && !check_drop1) {
            let test = BusinessUnitsList.filter(
              (id: any) => !selected.some((x: any) => x.name == id.name)
            )
            if (test.length == 2) {
              let sample = test.filter((res: any) => res.value == 'All' || res.value == 'empty')
              BusinessUnitsList.shift({
                name: 'Select All',
                value: 'All',
              })
            }
          }
        }
      }
    } else {
      setComplianceBusinessUnit(selected)
      setBusinessUnit(selected)
      let cde1 = BusinessUnitsList.find((item: any) => item.value == 'empty')
      if (cde1) {
        BusinessUnitsList.splice(
          BusinessUnitsList.findIndex((item: any) => item.value == 'empty'),
          1
        )
      }

      // BusinessUnitsList.pop({
      //   label: 'Deselect All',
      //   value: 'empty',
      // })

      let businessReset = BusinessUnitsList.find((item: any) => item.value == 'All')
      if (!businessReset) {
        BusinessUnitsList.unshift({
          name: 'Select All',
          value: 'All',
        })
      }
    }
  }

  const handleChange1 = (newStartDate: any) => {
    let MultipleYears = []
    let MultipleMonths = []
    setDateValue1(newStartDate)
    setValue('start_date', newStartDate)
    let arr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
    if (newStartDate?.length == 2) {
      calendarRef.current.closeCalendar()
      let a1 = newStartDate[0]?.year
      let b1 = newStartDate[1]?.year

      let a = newStartDate[0]?.month?.index
      let abc = arr.slice(a)

      let b = newStartDate[1]?.month?.number
      let abc1 = arr.slice(0, b)

      let a11 = arr.slice(a, b)

      if (a1 == b1) {
        //for same year condition
        MultipleYears.push(a1)
        MultipleMonths.push(a11)
      } else {
        //for different year condition
        MultipleYears.push(a1)
        MultipleYears.push(b1)
        MultipleMonths.push(abc)
        MultipleMonths.push(abc1)
      }
      setMultipleYears(MultipleYears)
      setMultipleMonths(MultipleMonths)

      let projDetails: any = {
        year: MultipleYears,
        months: MultipleMonths,
      }
      setProjectDetails(projDetails)
    }
  }

  const resetData = async (e: any) => {
    setFlag(false)
    setValue('employee_type', '')
    setProjectReportData([])
    setOrganizationReportData([])
    setOrganizationReportLineData([])
    setSelectYear(null)
    setSelectconsultant([])
    setSelectConsultant([])
    setBusinessUnit([])
    setDateValue1(null)
    setSelectConsultant1([])
    setSelectconsultant1([])
    setComplianceBusinessUnit([])
    // typeaheadRef.current.clear()
    // typeaheadRef1.current.clear() // Month
    setSelected([])
    setCountries1([])
    //consultant type
    // business unit

    let consultantReset = AllOptions.find((item: any) => item.value == 'All')
    if (!consultantReset) {
      AllOptions.unshift({
        label: 'Select All',
        value: 'All',
      })
    }
    // AllOptions.pop({
    //   label: 'Deselect All',
    //   value: 'empty',
    // })
    let consultantReset1 = AllOptions.find((item: any) => item.value == 'empty')
    if (consultantReset1) {
      AllOptions.splice(
        AllOptions.findIndex((item: any) => item.value == 'empty'),
        1
      )
    }

    let businessReset = BusinessUnitsList.find((item: any) => item.value == 'All')
    if (!businessReset) {
      BusinessUnitsList.unshift({
        name: 'Select All',
        value: 'All',
      })
    }
    // BusinessUnitsList.pop({
    //   name: 'Deselect All',
    //   value: 'empty',
    // })
    let businessReset1 = BusinessUnitsList.find((item: any) => item.value == 'empty')
    if (businessReset1) {
      BusinessUnitsList.splice(
        BusinessUnitsList.findIndex((item: any) => item.value == 'empty'),
        1
      )
    }
  }

  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]
  const getMaxDate = (date: any) => {
    if (!date) {
      return date
    }
    if (DateValue1.length == 1) {
      let month = date[0]?.month?.name
      let year = date[0]?.year
      let new_date = moment(`${year}-${month}-01`)
      const startTime = moment(new_date).add(11, 'months').format('MMMM YYYY')
      return new Date(startTime)
    }
  }
  const maxDate = React.useMemo(() => getMaxDate(DateValue1), [DateValue1])

  const getMinDate = (date: any) => {
    if (!date) {
      return date
    }
    if (DateValue1.length == 1) {
      let month = date[0]?.month?.name
      let year = date[0]?.year
      let new_date = moment(`${year}-${month}-01`)
      const startTime = moment(new_date).subtract(11, 'months').format('MMMM YYYY')
      return new Date(startTime)
    }
  }

  const minDate = React.useMemo(() => getMinDate(DateValue1), [DateValue1])
  return (
    <>
      <Modal show={show} fullscreen={fullscreen} onHide={() => handleClose()}>
        <Modal.Header closeButton className='p-3 bg-light'>
          <Modal.Title>Margin Report </Modal.Title>
        </Modal.Header>
        <Modal.Body className='p-3 bg-light'>
          <div className='d-flex justify-content-between align-items-center'>
            <div> </div>
          </div>
          {/* <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.MARGINREPORTS'})}</PageTitle> */}
          <BackDrop showBackDrop={showBackDrop} />
          <div className='py-1'>
            <div className='card card-custom card-flush '>
              <div className='card-body  py-1 px-5'>
                <div className='row gy-3 align-items-center py-2'>
                  {/* <div className='col-xs-12 col-md-3 col-lg-3 '>
                    <label className='form-label '>Select Year</label> */}
                  {/* <select name='selectYear' onChange={yearSelect} value={selectYear}> */}
                  {/* <select
                className={clsx('form-select', errors.select_Year ? 'is-invalid' : '')}
                value={selectYear}
                {...register('select_Year', {
                  required: true,
                  onChange: (e) => {
                    yearSelect(e.target.value)
                  },
                })}
              >
                <option value=''>Select Year</option>
                {years &&
                  years.length > 0 &&
                  years.map((value: any) => {
                    return <option value={value}>{value}</option>
                  })}
              </select> */}
                  {/* <DatePicker
                      selected={selectYear}
                      onChange={(date: any) => singleDateSelection(date)}
                      placeholderText='Select Year'
                      className={clsx('form-control')}
                      dateFormat='yyyy'
                      showYearPicker
                      required
                    />
                  </div> */}

                  <div className='col-xs-12 col-md-3 col-lg-3'>
                    <label className='form-label '>Select Month</label>
                    <MultiDatePicker
                      value={DateValue1}
                      format='MMM YYYY'
                      // multiple
                      numberOfMonths={2}
                      range={true}
                      sort
                      placeholder='Select Month Range'
                      highlightToday={false}
                      rangeHover
                      ref={calendarRef}
                      // minDate={new Date().setDate(5)}
                      // minDate={DateValue1 ? DateValue1[0] : []}
                      minDate={minDate}
                      maxDate={maxDate}
                      // showOtherDays
                      // showMonthYearPicker
                      onlyMonthPicker
                      hideMonth
                      months={months}
                      onChange={handleChange1}
                      dateSeparator=' - '
                      inputClass='form-control'

                      // showWeekend
                      // showMonthArrow
                      // months={[
                      //   {year: 2022, month: 1},
                      //   {year: 2023, month: 2},
                      // ]}
                    />
                    {MonthValidate && (
                      <span className='error text-danger mb-0'>Month is required</span>
                    )}
                  </div>

                  <div className='col-xs-12 col-md-3 col-lg'>
                    <label className='form-label '>Consultant Type</label>
                    {/* <Typeahead
                      multiple
                      id='keep-menu-open'
                      onChange={(selected: any) => {
                        handleConsultantType(selected)
                        // setSelectConsultant1(selected)
                        // Keep the menu open when making multiple selections.
                        typeaheadRef6.current.toggleMenu()
                      }}
                      options={options4}
                      placeholder='Select Consultant Type'
                      ref={typeaheadRef6}
                      selected={selectConsultant1}
                    /> */}
                    <Typeahead
                      multiple
                      id='keep-menu-open'
                      onChange={(selected: any) => {
                        handleConsultantType(selected)
                        // setSelectConsultant(selected)
                        // Keep the menu open when making multiple selections.
                        typeaheadRef6.current.toggleMenu()
                      }}
                      options={AllOptions}
                      placeholder='Select Consultant Type'
                      ref={typeaheadRef6}
                      selected={selectConsultant1}
                    />
                    {/* <div className='invalid-feedback'>{errors.last_name?.message}</div> */}
                  </div>

                  <div className='col-xs-12 col-md-3 col-lg-3'>
                    <label className='form-label '>Employee Name</label>
                    <Typeahead
                      multiple
                      id='keep-menu-open'
                      // disabled={isView}
                      onChange={(e) => {
                        selectedConsultant1(e)
                        typeaheadRef5.current.toggleMenu()
                      }}
                      onInputChange={(e) => handleFilterConsultant1(e)}
                      options={candidateList1}
                      ref={typeaheadRef5}
                      placeholder='Search Employee'
                      selected={select_consultant1}
                      labelKey={(candidateList1: any) => user(candidateList1.full_name)}
                      //   inputProps={{required: true}}
                    >
                      {/* {({onClear, selected}) => (
                    <div className='rbt-aux'>
                      {!!selected.length && (
                        <ClearButton disabled={AllFieldsToDisabled} onClick={onClear} />
                      )}
                    </div>
                  )} */}
                    </Typeahead>
                    {/* <div className='invalid-feedback'>{errors.last_name?.message}</div> */}
                  </div>
                  <div className='col-xs-12 col-md-3 col-lg'>
                    <label className='form-label '>Business Unit</label>
                    {/* <Typeahead
                      multiple
                      id='keep-menu-open'
                      // disabled={isView}
                      onChange={(select: any) => {
                        handleBusinessUnit(select)
                        typeaheadRef8.current.toggleMenu()
                      }}
                      options={BusinessUnitsList}
                      ref={typeaheadRef8}
                      placeholder='Select Business Unit'
                      selected={ComplianceBusinessUnit}
                      labelKey={(BusinessUnitsList: any) => BusinessUnitsList.name}
                      //   inputProps={{required: true}}
                    ></Typeahead> */}

                    <Typeahead
                      multiple
                      id='keep-menu-open'
                      // disabled={isView}
                      onChange={(e) => {
                        // setBusinessUnit(e)
                        handleBusinessUnit(e)
                        typeaheadRef8.current.toggleMenu()
                      }}
                      options={BusinessUnitsList}
                      ref={typeaheadRef8}
                      placeholder='Select Business Unit'
                      selected={ComplianceBusinessUnit}
                      labelKey={(BusinessUnitsList: any) => BusinessUnitsList.name}
                      //   inputProps={{required: true}}
                    ></Typeahead>
                  </div>

                  <div className='col-auto pt-md-0 pt-lg-8'>
                    <button
                      className='btn btn-sm btn-primary'
                      type='submit'
                      // onClick={() => handleClick()}
                      onClick={() => handleClick(page, perPage)}
                    >
                      Run
                    </button>
                  </div>
                  <div className='col-auto pt-md-0 pt-lg-8'>
                    <button
                      type='button'
                      className='btn btn-danger btn-sm cursor-pointer '
                      id='kt_toolbar_primary_button'
                      onClick={resetData}
                    >
                      Reset
                    </button>
                  </div>
                  <div className='col-auto pt-md-0 pt-lg-8'>
                    <button
                      // className='btn btn-icon btn-light  btn-active-color-primary btn-sm'
                      className='btn btn-light-primary   btn-sm'
                      onClick={exporthandleClick}
                    >
                      Export
                      {/* <i className='bi bi-cloud-arrow-down-fill fs-2'></i> */}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {OrganizationReportData && OrganizationReportData?.series && (
            <div className='py-1'>
              <div id='chart'>
                <div className='card card-custom card-flush '>
                  <div className='card-body p-2 py-1'>
                    <div className='row justify-content-center'>
                      <div className='col-11'>
                        <Chart
                          options={OrganizationReportData.options}
                          series={OrganizationReportData.series}
                          type='bar'
                          // width='100%'
                          height={450}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {OrganizationReportLineData && OrganizationReportLineData?.series && (
            <div className='py-1'>
              <div id='chart'>
                <div className='card card-custom card-flush '>
                  <div className='card-body p-2 py-1'>
                    <div className='row justify-content-center'>
                      <div className='col-11'>
                        <Chart
                          options={OrganizationReportLineData.options}
                          series={OrganizationReportLineData.series}
                          type='line'
                          // width='100%'
                          height={450}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* <div className='py-1'>
            <h3 className='text-gray-900 fs-3 fw-semibold  py-2'>Margin Data</h3>
            <div className='card card-custom card-flush '>
              <div className='card-body  py-1 px-5'>
                <div className='row gy-3 align-items-center py-2'>
                  <div className='col-xs-12 col-md-3 col-lg '>
                    <label className='form-label '> Year</label>
                    <DatePicker
                      selected={selectYear}
                      onChange={(date: any) => singleDateSelection(date)}
                      placeholderText='Select Year'
                      className={clsx('form-control')}
                      disabled={true}
                      dateFormat='yyyy'
                      showYearPicker
                      required
                    />
                  </div>
                  <div className='col-xs-12 col-md-3 col-lg '>
                    <label className='form-label '>Select Month</label>
                    <Typeahead
                      multiple
                      id='keep-menu-open'
                      onChange={(selected: any) => {
                        setSelectMonth(selected)
                        // Keep the menu open when making multiple selections.
                        typeaheadRef1.current.toggleMenu()
                      }}
                      options={options3}
                      placeholder='Select Month'
                      ref={typeaheadRef1}
                      selected={selectMonth}
                    />

                    <div className='invalid-feedback'>{errors.employee_type?.message}</div>
                  </div>
                  <div className='col-xs-12 col-md-3 col-lg'>
                    <label className='form-label '>Consultant Type</label>
                    <Typeahead
                      multiple
                      id='keep-menu-open'
                      onChange={(selected: any) => {
                        setSelectConsultant(selected)
                        // Keep the menu open when making multiple selections.
                        typeaheadRef2.current.toggleMenu()
                      }}
                      options={options2}
                      placeholder='Select Consultant Type'
                      ref={typeaheadRef2}
                      selected={selectConsultant}
                    />
                  </div>
                  <div className='col-xs-12 col-md-3 col-lg'>
                    <label className='form-label '>Business Unit</label>
                    <Typeahead
                      multiple
                      id='keep-menu-open'
                      // disabled={isView}
                      onChange={(e) => {
                        setBusinessUnit(e)
                        typeaheadRef7.current.toggleMenu()
                      }}
                      options={BusinessUnitsList}
                      ref={typeaheadRef7}
                      placeholder='Select Business Unit'
                      selected={BusinessUnit}
                      labelKey={(BusinessUnitsList: any) => BusinessUnitsList.name}
                      //   inputProps={{required: true}}
                    >
                    </Typeahead>
                  </div>
                  <div className='col-xs-12 col-md-3 col-lg'>
                    <label className='form-label '>Project Type </label>
                    <select
                      // value={selectedItem}
                      {...register('project_type')}
                      onChange={handleProjectType}
                      className={clsx('form-select')}
                    >
                      <option value=''>Select Project Type</option>
                      <option value='1'>Placement</option>
                      <option value='2'>Bench Project</option>
                      <option value='3'>Internal</option>
                    </select>
                  </div>
                  <div className='col-xs-12 col-md-3 col-lg'>
                    <label className='form-label '>Employee Name</label>
                    <Typeahead
                      multiple
                      id='keep-menu-open'
                      // disabled={isView}
                      onChange={(e) => {
                        selectedConsultant(e)
                        typeaheadRef3.current.toggleMenu()
                      }}
                      onInputChange={(e) => handleFilterConsultant(e)}
                      options={candidateList}
                      ref={typeaheadRef3}
                      placeholder='Search Employee'
                      selected={select_consultant}
                      labelKey={(candidateList: any) => user(candidateList.full_name)}
                      //   inputProps={{required: true}}
                    >
                    </Typeahead>
                  </div>
                  <div className='col-xs-12 col-md-3 col-lg'>
                    <label className='form-label '>Project Name</label>
                    <Typeahead
                      multiple
                      id='keep-menu-open'
                      onChange={(e) => {
                        selectedCountries1(e)
                        typeaheadRef4.current.toggleMenu()
                      }}
                      onInputChange={(e) => {
                        handleCountries1(e)
                      }}
                      placeholder='Please Enter Project Name'
                      ref={typeaheadRef4}
                      options={country1}
                      selected={countries1}
                      labelKey={(country1: any) => country1.project_name}
                    >
                    </Typeahead>
                  </div>

                  <div className='col-auto pt-md-8 pt-lg-8'>
                    <button
                      className='btn btn-sm btn-primary'
                      type='submit'
                      onClick={() => handleClick1()}
                    >
                      Run
                    </button>
                  </div>
                  <div className='col-auto pt-sm-0 pt-md-8'>
                    <button
                      // className='btn btn-icon btn-light  btn-active-color-primary btn-sm'
                      className='btn btn-light-primary   btn-sm'
                      onClick={exporthandleClick1}
                    >
                      Export
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          */}
          {/* {flag == true && ( */}
          <>
            {ProjectReportData?.length > 0 && (
              <div className='pt-3'>
                <div className='card card-custom card-flush '>
                  <div className='card-body p-3 py-3'>
                    <div className='table-responsive reports-table'>
                      <table
                        id='kt_table_users'
                        className='table table-row-bordered table-row-gray-300 gy-4'
                      >
                        <>
                          {/* business2 ? business1 : '' */}
                          {ComplianceBusinessUnit && ComplianceBusinessUnit.length > 0 ? (
                            <thead>
                              <tr className='fw-semibold fs-7 text-gray-600 text-uppercase'>
                                <th className='  py-4'>Organization </th>
                                <th className='  py-4'>Business Unit </th>
                                <th className='  py-4'>MONTH & YEAR</th>
                                <th className='  py-4'>MARGIN AMOUNT </th>
                                <th className='  py-4'>MARGIN % </th>
                              </tr>
                            </thead>
                          ) : (
                            <thead>
                              <tr className='fw-semibold fs-7 text-gray-600 text-uppercase'>
                                <th className='  py-4'>Organization </th>
                                {/* <th className='  py-4'>Business Unit </th> */}
                                <th className='  py-4'>MONTH & YEAR</th>
                                <th className='  py-4'>MARGIN AMOUNT </th>
                                <th className='  py-4'>MARGIN % </th>
                              </tr>
                            </thead>
                          )}
                          <tbody>
                            {ProjectReportData?.length > 0 ? (
                              <>
                                {ProjectReportData?.map((each: any, index: any) => {
                                  return (
                                    <tr
                                      className='namepage text-dark fw-semibold  fs-6 '
                                      key={index}
                                    >
                                      <>
                                        <td>
                                          {!each.business_unit_name
                                            ? localStorage.getItem('org_name')
                                            : ''}
                                        </td>
                                        {ComplianceBusinessUnit &&
                                          ComplianceBusinessUnit.length > 0 && (
                                            <td>
                                              {each.business_unit_name
                                                ? each.business_unit_name
                                                : ''}
                                            </td>
                                          )}
                                        <td className=''>
                                          {each.monthName
                                            ? moment(each.monthName, 'MMMM').format('MMM')
                                            : '-'}{' '}
                                          {moment(each.year, 'YYYY').format('YYYY')}
                                        </td>
                                        <td className=''>
                                          {each.monthly_margin_amount
                                            ? '$' +
                                              Number(each.monthly_margin_amount).toLocaleString(
                                                undefined,
                                                {minimumFractionDigits: 2, maximumFractionDigits: 2}
                                              )
                                            : '-'}
                                        </td>
                                        <td className=''>
                                          {each.monthly_margin_percent
                                            ? each.monthly_margin_percent
                                            : '-'}
                                        </td>
                                      </>
                                    </tr>
                                  )
                                })}
                              </>
                            ) : (
                              ''
                            )}
                          </tbody>
                        </>
                      </table>
                      {(ProjectReportData?.length == null || ProjectReportData?.length == 0) && (
                        <div className='text-center  fw-bold text-gray-800'>No Records found </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className='d-flex flex-stack py-2'>
              {ProjectReportData?.length > 0 ? (
                <div>
                  <Container className='p-0'>
                    <Row className='align-items-center'>
                      <Col xs='auto' className='pe-0'>
                        {' '}
                        <label className='form-label fw-semibold'>Rows Per Page</label>
                      </Col>

                      <Col>
                        <select
                          name='perPage'
                          className='form-select'
                          onChange={onChange}
                          value={perPage}
                        >
                          {/* <option value='10'>10</option> */}
                          <option value='25'>25</option>
                          <option value='50'>50</option>
                          <option value='75'>75</option>
                          <option value={ProjectReportDataCount}>All</option>
                        </select>
                      </Col>
                    </Row>
                  </Container>
                </div>
              ) : null}

              <div>
                {ProjectReportData?.length > 0 ? (
                  <ReactPaginate
                    previousLabel={'<'}
                    nextLabel={'>'}
                    pageCount={pages}
                    onPageChange={handlePageClick}
                    containerClassName={'pagination justify-content-right'}
                    activeClassName={'active'}
                    breakLabel={'...'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    breakClassName={'page-item'}
                    breakLinkClassName={'page-link'}
                  />
                ) : null}
              </div>
            </div>
          </>
          {/* )} */}
          {/* {flag == false && (
            <div className='fs-6 text-gray-500 text-transform-unset'>
              Note: Please select any one and click run to view the result
            </div>
          )} */}
        </Modal.Body>
      </Modal>
    </>
  )
}

export default MarginComplianceReports
