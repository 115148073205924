/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC, useEffect} from 'react'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {Logout, AuthPage, useAuth} from '../modules/auth'
import {App} from '../App'
// import Timesheet from '../pages/dashboard/--Timesheet'
import Timesheet from '../pages/login/Login'

// import ForgotPassword from '../../app/pages/dashboard/ForgotPassword'
import ForgotPassword from '../../app/pages/login/ForgotPassword'

// import Approver from '../modules/apps/approver/Approver'

import {useNavigate} from 'react-router-dom'
import OrganizationList from '../../app/pages/dashboard/OrganizationList'
import {useLocation} from 'react-router'
import {ToastContainer} from 'react-bootstrap'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env

const AppRoutes: FC = () => {
  const {currentUser} = useAuth()
  let business: any = localStorage.getItem('role_Name')
  let numArray: any = business?.split(',')
  return (
    <BrowserRouter basename='timetracker'>
      <Routes>
        <Route element={<App />}>
          {/* <Route path='error/*' element={<ErrorsPage />} />
            <Route path='logout' element={<Logout />} /> */}

          <>
            <Route path='/*' element={<PrivateRoutes />} />
            <Route index element={<Navigate to='/login' />} />
          </>
          {localStorage.getItem('role_Name') && (
            <>
              {(window.location.pathname == '/' ||
                window.location.pathname == '/timetracker' ||
                window.location.pathname == '/timetracker/' ||
                window.location.pathname == '/timetracker/login') &&
                (window.location.href =
                  // localStorage.getItem('role_Name') === 'employee'
                  numArray?.includes('employee')
                    ? '/timetracker/timesheetlist'
                    : // : localStorage.getItem('role_Name') === 'timesheetadmin' ||
                    //   localStorage.getItem('role_Name') === 'superadmin' ||
                    //   localStorage.getItem('role_Name') === 'admin'
                    numArray?.includes('timesheetadmin') ||
                      numArray?.includes('superadmin') ||
                      numArray?.includes('admin')
                    ? '/timetracker/masters/manageconsultants'
                    : numArray?.includes('relationshipmanager')
                    ? '/timetracker/MyConsultant'
                    : '/timetracker/approver')}
            </>
          )}

          {!localStorage.getItem('role_Name') && (
            <>{window.location.pathname == '/' && (window.location.href = '/timetracker/login')}</>
          )}
          <>
            {/* <Route path='auth/*' element={<AuthPage />} />
                <Route path='*' element={<Navigate to='/auth' />} /> */}
            <Route path='login/*' element={<Timesheet />} />
            <Route path='forgotPassword' element={<ForgotPassword />} />
            <Route element={<MasterLayout />}>
              <Route path='OrganizationList/*' element={<OrganizationList />} />

              <Route path='/OrganizationList' element={<OrganizationList />} />
            </Route>
            {/* <Route path='OrganizationList/*' element={<OrganizationList />} /> */}
            <Route path='*' element={<Navigate to='/login' />} />
          </>
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}
