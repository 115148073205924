import React, {useState} from 'react'
import {default as Apiservices} from '../../../../../../src/common/index'
import {useNavigate} from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'
import parse from 'html-react-parser'

export default function AnnouncementPop(props: any) {
  //   const { closeModal,emailForProp,passwordForProp} = props;
  const {closeModal, close, customermessage, cancel} = props
  const navigate = useNavigate()
  const [show, setShow] = useState(true)

  const handleClose = () => {
    setShow(false)
  }

  const Announcement = async () => {
    const data: any = await Apiservices.markAsReadAnnouncement({
      id: props.announcement_list.id,
    })
    props.setAnnouncementOpenPoup(false)
  }
  return (
    <>
      <Modal
        size='lg'
        show={show}
        onHide={handleClose}
        // size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        backdrop='static'
      >
        <Modal.Header className='py-4'>
          <Modal.Title>
            {props.role_name == 'Admin' ? (
              <div className=' text-gray-800 fs-4 fw-semibold text-transform-unset'>
                {props.titleprops ? props.titleprops : ''}
              </div>
            ) : (
              <div className=' text-gray-800 fs-4 fw-semibold text-transform-unset'>
                {parse(props.announcement_list ? props.announcement_list.title : '')}
              </div>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='modal-max-w-img'>
          {props.role_name == 'Admin' ? (
            <>
              <div className=' m-0'>
                {parse(props.descriptionprops ? props.descriptionprops : '')}
              </div>
            </>
          ) : (
            <>
              <div className=' m-0'>
                {parse(props.announcement_list ? props.announcement_list.description : '')}
              </div>
            </>
          )}

          {/* <div className='border-top'></div> */}
        </Modal.Body>
        <Modal.Footer className='p-2 py-2'>
          <div className='row justify-content-end gap-2  '>
            {props.role_name == 'Admin' ? (
              <div className='col-auto'>
                <button className='btn btn-sm btn-light-danger' onClick={props.close}>
                  Close
                </button>
              </div>
            ) : (
              <>
                <div className='col-auto'>
                  <button className='btn btn-sm btn-light-danger' onClick={Announcement}>
                    Don't show it again
                  </button>
                </div>
                <div className='col-auto'>
                  <button className='btn btn-sm btn-primary ' onClick={() => close('true')}>
                    Confirm
                  </button>
                </div>
              </>
            )}
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}
