import React, {useState, useMemo, useEffect, useRef} from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas'
import moment from 'moment'
import momenttz from 'moment-timezone'
// import {Tooltip} from './ComponentTooltip'
import {Tooltip} from '../../../apps/timesheetentry/ComponentTooltip'
import {default as Apiservices} from '../../../../../../src/common/index'

const Drawer = (props: any) => {
  const handleClose = () => {
    props.setModalShow(false)
    props.setCommentValidation(false)
  }

  return (
    <Offcanvas
      show={props.modalShow}
      onHide={handleClose}
      placement='end'
      className='zIndexForMulti'
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title className='fs-5  fw-semibold text-gray-700'>
          <div className='text-capitalize'>{props.ConsultantName}</div>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <div className='border-top '></div>
      <Offcanvas.Body>
        <textarea
          rows={3}
          // {...register('access_token', {
          //   required: true,
          // })}
          placeholder='Enter Comment'
          className={`form-control`}
          onChange={(e) => props?.commentField(e.target.value)}
          value={props.searchData}
        />
        {props.CommentValidation && (
          <p className='error text-danger mb-0 mt-2'>{'Comment field is required'}</p>
        )}
        <div className='d-flex justify-content-end  py-1 '>
          <div>
            <div className='d-flex gap-3 pt-3'>
              <div>
                <button type='submit' className='btn btn-primary' onClick={props.handleSubmitt}>
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
        <div></div>

        <div className='fs-5 py-9 fw-semibold text-gray-700 offcanvas-title h5'>
          Comments History
        </div>
        {/* <div className=''>
          <div className='card card-custom card-flush'>
            <div className='card-body  py-4'> */}
        <div className='table-responsive '>
          <table
            id='kt_table_users'
            className='table table-row-bordered table-row-gray-200 gy-2 gs-4  side-bar-table'
          >
            {props.CommentHistory?.length > 0 ? (
              <>
                <thead>
                  <tr className='fw-semibold fs-7 text-gray-600 text-uppercase'>
                    <th className='  py-4'>Comment </th>
                    <th className='  py-4'>Added On </th>
                    <th className='  py-4'>Created By </th>
                  </tr>
                </thead>
                <>
                  {props.CommentHistory?.length > 0 ? (
                    <>
                      <tbody className=''>
                        {props.CommentHistory.length > 0
                          ? props.CommentHistory.map((user: any, index: number) => {
                              return (
                                <tr
                                  key={index}
                                  className='namepage text-dark fw-semibold  fs-6 text-nowrap'
                                >
                                  <td className='w-50'>{user.comments ? user.comments : '-'}</td>
                                  <td className='w-50'>
                                    {user.createdate
                                      ? moment(user.createdate).format('YYYY-MM-DD')
                                      : '-'}
                                  </td>
                                  <td className='text-capitalize'>
                                    {user.display_name ? user.display_name : '-'}
                                  </td>
                                </tr>
                              )
                            })
                          : ''}
                      </tbody>
                    </>
                  ) : (
                    ''
                  )}
                </>
              </>
            ) : (
              ''
            )}
          </table>
          {(props.CommentHistory?.length == null || props.CommentHistory?.length == 0) && (
            <p className='text-center'> No Records Found</p>
          )}
        </div>
        {/* </div> */}
        {/* </div>
        </div> */}
      </Offcanvas.Body>
    </Offcanvas>
  )
}
export default Drawer
