import React, { useState } from 'react'
import { default as Apiservices } from '../../../../../../src/common/index'
import { useNavigate } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'
import parse from 'html-react-parser'
import { useForm } from 'react-hook-form'

export default function RevertAllPopup(props: any) {
    const methods = useForm({
        mode: 'onChange',
    })

    const { register, control, handleSubmit, setValue, reset, getValues } = methods

    let validateFields = [`customer_name`]
    // let validateform = methods.triggerValidation(validateFields);
    console.log('validateforms are ', validateFields)

    // if (!validateform) return;
    console.log(props, 'dbgjkfdg')
    console.log(props.checkAdpData, 'dbgjkfdg')
    //   const { closeModal,emailForProp,passwordForProp} = props;
    const { closeModal, close, customermessage, cancel } = props
    const navigate = useNavigate()
    const [show, setShow] = useState(true)
    const [adpDisabledd, setAdpDisabledd] = useState(false)
    const [revertDataPopup, setRevertDataPopup] = useState('')
    const [okButtonTriggered, setOkButtonTriggered] = useState(false)
    console.log(revertDataPopup, 'dhjkhfg')
    const handleClose = () => {
        setShow(false)
    }

    const Announcement = async () => {
        const data: any = await Apiservices.markAsReadAnnouncement({
            id: props.announcement_list.id,
        })
        props.setAnnouncementOpenPoup(false)
    }
    console.log(revertDataPopup, 'nsjkhdsjkh')

    const greetUser = async () => {
        if (revertDataPopup !== '') {
            props.setConsultant3(revertDataPopup)
        }
        setOkButtonTriggered(true)
    }

    function handleChange(event: any) {
        console.log(event.target.value)
        setRevertDataPopup(event.target.value)
        // props.setConsultant(props.checkAdpData)
    }

    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                // size='lg'
                aria-labelledby='contained-modal-title-vcenter'
                centered
                backdrop='static'
            >
                <Modal.Header className=' py-4'>
                    <Modal.Title>
                        <div className=' text-gray-800 fs-4 fw-semibold text-transform-unset'>
                            <span></span> Do you want reject the entire week ?
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='py-3'>
                    <>
                        <div className=' m-0'>
                            <div className='col'>
                                <textarea
                                    placeholder='Please Enter Revert Comments *'
                                    onChange={handleChange}
                                    rows={3}
                                    className={`form-control`}
                                ></textarea>
                            </div>
                            {(!revertDataPopup || revertDataPopup == '' || revertDataPopup == ' ') &&
                                okButtonTriggered && (
                                    <>
                                        <p style={{ color: 'red' }}>Please Enter Reject Comments</p>
                                    </>
                                )}
                        </div>
                    </>
                </Modal.Body>
                <Modal.Footer className='p-2 '>
                    <div className='row justify-content-end   '>
                        <div className='col-auto'>
                            <button className='btn btn-light-danger btn-sm cursor-pointer' onClick={props.onCancel}>
                                Cancel
                            </button>
                        </div>

                        <div className='col-auto'>
                            <button className='btn btn-sm btn-primary' onClick={greetUser}>
                                OK
                            </button>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )
}
