/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {default as Apiservices} from '../../../../common/index'

import {useLayout} from '../../core'
import {Header} from './Header'
import {DefaultTitle} from './page-title/DefaultTitle'
import {Topbar} from './Topbar'
import MsrLogo from '../../../../localMedia/msrLogo.svg'

export function HeaderWrapper(props: any) {
  const {config, classes, attributes} = useLayout()
  const {header, aside} = config
  const organization = localStorage.getItem('org_name')
  const [docResultDataRes, setDocResultDataRes] = useState<any>([])
  const [orgId, setOrdId] = useState(localStorage.getItem('org_id'))
  const getLogoDetails = async () => {
    const res: any = await Apiservices.getLogoDetails()

    if (res && res.data) {
      let logourl = res?.data?.orgLogoContent
      setDocResultDataRes(logourl)
    }
  }

  useEffect(() => {
    if (localStorage.getItem('org_id')) {
      getLogoDetails()
    }
  }, [])
  return (
    <div
      id='kt_header'
      className={clsx('header', classes.header.join(' '), 'align-items-stretch')}
      {...attributes.headerMenu}
    >
      <div
        className={clsx(
          classes.headerContainer.join(' '),
          'd-flex align-items-stretch justify-content-between'
        )}
      >
        {/* begin::Aside mobile toggle */}
        {aside.display && (
          <div className='d-flex align-items-center d-lg-none ms-n3 me-1' title='Show aside menu'>
            <div
              className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
              id='kt_aside_mobile_toggle'
            >
              <KTSVG path='/media/icons/duotune/abstract/abs015.svg' className='svg-icon-2x mt-1' />
            </div>
          </div>
        )}
        {/* end::Aside mobile toggle */}
        {/* begin::Logo */}
        {!aside.display && (
          <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0'>
            <Link to='/dashboard' className='d-lg-none'>
              <img
                alt='Logo'
                src={toAbsoluteUrl('/media/logos/MSRcososGroup-logo.svg')}
                className='h-30px'
              />
            </Link>
          </div>
        )}
        {/* end::Logo */}

        {aside.display && (
          <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0'>
            <Link to='/' className='d-lg-none d-none'>
              <img
                alt='Logo'
                src={toAbsoluteUrl('/media/logos/MSRcososGroup-logo.svg')}
                className='h-30px'
              />
            </Link>
          </div>
        )}

        {/* begin::Wrapper */}
        <div className='d-flex align-items-stretch justify-content-between flex-lg-grow-1'>
          {/* begin::Navbar */}
          {header.left === 'menu' && (
            <div className='d-flex align-items-stretch' id='kt_header_nav'>
              <div className='d-flex align-items-center'>
                <div className='text-primary fw-semibold fs-1 '>
                  {docResultDataRes?.length > 0 ? (
                    <img src={docResultDataRes} className='orgLogo' />
                  ) : (
                    <img src={MsrLogo} className='mobileHeaderLogo' />
                  )}
                  {/* {docResultDataRes == null && <img src={MsrLogo} className='mobileHeaderLogo' />} */}
                </div>
                {/* <div className='text-primary fw-semibold fs-1 d-none d-md-block'>
                  {organization ? organization : 'Time Tracker'}
                </div> */}
              </div>
            </div>
          )}

          {/* {header.left === 'menu' && (
            <div className='d-flex align-items-stretch' id='kt_header_nav'>
              {/* <Header /> */}
          {/* <div className="d-flex align-items-center">
                <button
                  className='btn btn-secondary form-group  mt-2'
                  type='button'
                  // onClick={() => handleCustomerName(clientname)}
                  aria-disabled='true'
                >
                  Organization Details
                </button>              </div>
            </div> */}

          {header.left === 'page-title' && (
            <div className='d-flex align-items-center' id='kt_header_nav'>
              <DefaultTitle />
            </div>
          )}

          <div className='d-flex align-items-stretch flex-shrink-0'>
            <Topbar
              docResultDataRes={docResultDataRes}
              getLogoDetails={getLogoDetails}
              widgetReload={props.widgetReload}
              widgetLogout={props.widgetLogout}
            />
          </div>
        </div>
        {/* end::Wrapper */}
      </div>
    </div>
  )
}
