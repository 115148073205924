import React, {useState} from 'react'
import {default as Apiservices} from '../../../../../../src/common/index'
import {useNavigate} from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import BackDrop from '../../timesheetentry/ComponentBackdrop'
import AnnouncementPop from '../modal/AnnouncementPop'

export default function ProjectQBPop(props: any) {
  const navigate = useNavigate()
  const [show, setShow] = useState(true)
  const [message, setMessage] = useState(false)
  const [showBackDrop, setShowBackDrop] = useState(true)
  const [isLoading, setIsLoading] = useState<any | []>(false)
  const [announcement_open_popup, setAnnouncementOpenPoup] = useState(false)

  const handleClose = () => {
    setShow(false)
    props.close(false)

    props.setShow(false)
  }

  return (
    <>
      <Modal
        show={show}
        // onHide={handleClose}
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Body>
          {props.QBName == 'UserProjectBUQB' ? (
            <p className='text-center text-gray-800 fs-6 text-transform-unset'>
              Please Check User Business Unit and Project Business Unit
            </p>
          ) : props.QBName == 'OTProductHours' ? (
            <p className='text-center text-gray-800 fs-6 text-transform-unset'>
              Do you want to create another product for OT hours?
            </p>
          ) : (
            <p className='text-center text-gray-800 fs-6 text-transform-unset'>
              Do you want to edit this{' '}
              {props.Settings == 'NetSuite' ? 'NetSuite field?' : 'QB field?'}
            </p>
          )}

          {props.QBName == 'ClientQB' ? (
            <div className='row justify-content-center gap-2 py-2'>
              <div className='col-auto'>
                <button className='btn btn-primary' onClick={props.close}>
                  Ok
                </button>
              </div>
              <div className='col-auto'>
                <button className='btn btn-danger' onClick={props.cancel}>
                  Cancel
                </button>
              </div>
            </div>
          ) : props.QBName == 'EmployeeQB' ? (
            <div className='row justify-content-center gap-2 py-2'>
              <div className='col-auto'>
                <button className='btn btn-primary' onClick={props.close}>
                  Ok
                </button>
              </div>
              <div className='col-auto'>
                <button className='btn btn-danger' onClick={props.cancel}>
                  Cancel
                </button>
              </div>
            </div>
          ) : props.QBName == 'ProjectQB' ? (
            <div className='row justify-content-center gap-2 py-2'>
              <div className='col-auto'>
                <button className='btn btn-primary' onClick={props.close}>
                  Ok
                </button>
              </div>
              <div className='col-auto'>
                <button className='btn btn-danger' onClick={props.cancel}>
                  Cancel
                </button>
              </div>
            </div>
          ) : props.QBName == 'OTProductHours' ? (
            <div className='row justify-content-center gap-2 py-2'>
              <div className='col-auto'>
                <button className='btn btn-primary' onClick={props.OTProductHoursOk}>
                  Ok
                </button>
              </div>
              <div className='col-auto'>
                <button className='btn btn-danger' onClick={props.OTProductHoursCancel}>
                  Cancel
                </button>
              </div>
            </div>
          ) : props.QBName == 'UserProjectBUQB' ? (
            <div className='row justify-content-center gap-2 py-2'>
              <div className='col-auto'>
                <button className='btn btn-primary' onClick={props.UserProjectClose}>
                  Ok
                </button>
              </div>
            </div>
          ) : (
            <div className='row justify-content-center gap-2 py-2'>
              <div className='col-auto'>
                <button className='btn btn-primary' onClick={props.close}>
                  Ok
                </button>
              </div>
              <div className='col-auto'>
                <button className='btn btn-danger' onClick={props.cancel}>
                  Cancel
                </button>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
      <ToastContainer />
    </>
  )
}
