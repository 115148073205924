import React, {useState} from 'react'
import {default as Apiservices} from '../../../../../../src/common/index'
import {useNavigate} from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'
import parse from 'html-react-parser'

export default function AdpPopup(props: any) {
  console.log(props.onCancel, 'dnghjgfdh')
  //   const { closeModal,emailForProp,passwordForProp} = props;
  const {closeModal, close, customermessage, cancel} = props
  const navigate = useNavigate()
  const [show, setShow] = useState(true)

  const handleClose = () => {
    setShow(false)
  }

  const Announcement = async () => {
    const data: any = await Apiservices.markAsReadAnnouncement({
      id: props.announcement_list.id,
    })
    props.setAnnouncementOpenPoup(false)
  }
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        backdrop='static'
      >
        <Modal.Body className='p-0'>
          <>
            <div className=' text-gray-800 fs-5 fw-semibold p-4'>
              {props.announcement_list ? props.announcement_list.title : ''}
              <div className='text-center text-gray-800 fs-5 fw-semibold text-transform-unset'>
                ADP details not found, Please add consultant details in ADP application.
              </div>
            </div>
          </>
          <div className='border-top'></div>
          <div className='row justify-content-end gap-2 py-2 p-4 '>
            <div className='col-auto'>
              <button className='btn btn-sm btn-light-primary' onClick={props.onCancel}>
                Close
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
