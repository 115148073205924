import clsx from 'clsx'
import React, {FC, useState, useEffect, useRef} from 'react'

import {KTSVG} from '../../../helpers'
import {ThemeModeType, useThemeMode} from './ThemeModeProvider'
import {useNavigate} from 'react-router-dom'
import {default as Apiservices} from '../../../../../src/common/index'
import {Tooltip} from '../../../../app/modules/apps/timesheetentry/ComponentTooltip'
import BackDrop from '../../../../app/modules/apps/timesheetentry/ComponentBackdrop'
import {ToastContainer, toast} from 'react-toastify'
import Cookies from 'universal-cookie'

/* eslint-disable jsx-a11y/anchor-is-valid */
type Props = {
  toggleBtnClass?: string
  toggleBtnIconClass?: string
  menuPlacement?: string
  menuTrigger?: string
  text?: string
  docResultDataRes11?: any
  getLogoDetails?: any
  gettingUser?: any
  widgetReload?: any
}

const ChooseOrganizationHeader = ({
  toggleBtnClass = '',
  toggleBtnIconClass = 'svg-icon-2',
  menuPlacement = 'bottom-end',
  menuTrigger = "{default: 'click', lg: 'hover'}",
  docResultDataRes11 = '',
  getLogoDetails = '',
  gettingUser = '',
  widgetReload = '',
}: Props) => {
  const {mode, menuMode, updateMode, updateMenuMode} = useThemeMode()
  const [organizations, setOrganizations] = useState([])
  const [showBackDrop, setShowBackDrop] = useState(false)
  const [docResultDataRes, setDocResultDataRes] = useState<any>([])
  const [user, setUser] = useState<any>()
  const navigate = useNavigate()
  const cookies = new Cookies()

  const switchMode = (_mode: ThemeModeType) => {
    updateMenuMode(_mode)
    updateMode(_mode)
  }
  useEffect(() => {
    let users = JSON.parse(localStorage.getItem('org_list') || '[]')
    users.map((org_lists: any) => {
      return <div>{org_lists.organizationName}</div>
    })
    setOrganizations(users)
  }, [])
  function removeAll() {
    let lRemove: any = [
      'token',
      'user_email',
      'org_list',
      'role_Name',
      'org_prefix',
      'org_id',
      'org_name',
      'tool_name',
      'ecdb_user_id',
      'userid',
      'Authorization',
      'NetSuiteToken',
      'enable_netsuite',
      'subsidary_name',
      'logged_in_id',
      'Announcement',
      'refresh_Token',
      'enable_quickbooks',
      'org_icon',
      'time_sheet_admin_screen_user_id',
      'MobileForceUpdateAccessbyEmail',
      'allowed_business_unit_id',
      'bu_list',
      'enable_unbilled',
      'invoice_access',
    ]
    for (let each of lRemove) {
      localStorage.removeItem(each)
    }
  }
  const getUser = async (org: any) => {
    let organizationName = org
    localStorage.setItem('org_prefix', org.orgPrefix)
    localStorage.setItem('org_id', org.organizationId)
    cookies.set('org_id', org.organizationId, {path: '/', domain: '.msrcosmos.com'})
    cookies.set('torg_id', org.organizationId, {path: '/', domain: '.msrcosmos.com'})
    localStorage.setItem('org_name', org.organizationName)
    for (let tools of organizationName.toolRoles) {
      if (tools.toolName == 'Timesheet Tracker') {
        localStorage.setItem('role_Name', tools.roleName?.toLowerCase())
        localStorage.setItem('tool_name', tools.toolName)
        getUserAuth(org.organizationId)
        break
      }
    }
  }
  let business: any = localStorage.getItem('role_Name')
  let numArray: any = business?.split(',')
  const reloadPage = () => {
    window.location.reload()
  }
  // const getLogoDetails1 = async () => {
  //   const res: any = await Apiservices.getLogoDetails()
  //   if (res && res.data) {
  //     let logourl = res.data.orgLogoContent

  //     setDocResultDataRes(logourl)
  //   }
  // }
  const getUserAuth = async (orgId: any) => {
    // setIsLoading(true)
    setShowBackDrop(true)
    let authtoken: any = localStorage.getItem('token')?.split(' ')
    let orgEmail = localStorage.getItem('user_email')
    let data: any = {}
    let roles: any = []
    var buRoles
    data.email = orgEmail
    data.organizationId = orgId
    data.ecdbToken = authtoken[1]
    const response: any = await Apiservices.tokenData(data)
    if (response.isError == false) {
      if (response?.userBUresponse?.length > 0) {
        for (let each of response.userBUresponse) {
          roles.push(each.roleName?.toLowerCase())
        }
        roles.push(response.user_data.role_name?.toLowerCase())
        buRoles = roles
      } else {
        buRoles = response.user_data.role_name?.toLowerCase()
      }
      getLogoDetails()
      widgetReload()
      localStorage.setItem('ecdb_user_id', response.user_data.ecdb_user_id)
      localStorage.setItem('enable_unbilled', response?.user_data?.enable_unbilled)
      localStorage.setItem('invoice_access', response?.user_data?.invoice_access)
      localStorage.setItem('userid', response.user_data.id)
      localStorage.setItem('Authorization', response.data)
      localStorage.setItem('logged_in_id', orgId)
      localStorage.setItem('role_Name', buRoles)
      localStorage.setItem('bu_list', JSON.stringify(response.userBUresponse))
      localStorage.setItem('allowed_business_unit_id', response.allowed_business_unit_id)
      toast.success('Organization has been changed successfully')

      viewUser()
      // if (localStorage.getItem('role_Name') === 'employee') {
      if (numArray?.includes('employee')) {
        localStorage.setItem('Announcement', 'false')

        // navigate('/timesheetlist')
        const token: any = await Apiservices.refreshToken()
        if (token && token.data && token.data.isError == false) {
          localStorage.setItem('refresh_Token', token.data.isError)
          setShowBackDrop(false)
          navigate('/timesheetlist')
          reloadPage()
        } else {
          localStorage.setItem('refresh_Token', 'true')
          return setTimeout(function () {
            setShowBackDrop(false)
            navigate('/timesheetlist')
            reloadPage()
          }, 2000)
        }
      } else if (
        // localStorage.getItem('role_Name') === 'superadmin' ||
        // localStorage.getItem('role_Name') === 'admin' ||
        // localStorage.getItem('role_Name') === 'timesheetadmin'
        numArray?.includes('superadmin') ||
        numArray?.includes('admin') ||
        numArray?.includes('timesheetadmin')
      ) {
        localStorage.setItem('Announcement', 'false')
        const token: any = await Apiservices.refreshToken()
        if (token && token.data && token.data.isError == false) {
          localStorage.setItem('refresh_Token', token.data.isError)
          setShowBackDrop(false)
          navigate('/masters/manageconsultants')
          reloadPage()
        } else {
          localStorage.setItem('refresh_Token', 'true')
          return setTimeout(function () {
            setShowBackDrop(false)
            navigate('/masters/manageconsultants')
            reloadPage()
          }, 2000)
        }
      } else if (numArray?.includes('relationshipmanager')) {
        localStorage.setItem('Announcement', 'false')
        const token: any = await Apiservices.refreshToken()
        if (token && token.data && token.data.isError == false) {
          localStorage.setItem('refresh_Token', token.data.isError)
          setShowBackDrop(false)
          navigate('/MyConsultant')
          reloadPage()
        } else {
          localStorage.setItem('refresh_Token', 'true')
          return setTimeout(function () {
            setShowBackDrop(false)
            navigate('/MyConsultant')
            reloadPage()
          }, 2000)
        }
      }
      // else if (localStorage.getItem('role_Name') === 'admin') {
      //   const token: any = await Apiservices.refreshToken()
      //   if (token && token.data && token.data.isError == false) {
      //     localStorage.setItem('refresh_Token', token.data.isError)
      //     setShowBackDrop(false)
      //     navigate('/masters/manageconsultants')
      //     reloadPage()
      //   } else {
      //     localStorage.setItem('refresh_Token', 'true')

      //     return setTimeout(function () {
      //       setShowBackDrop(false)
      //       navigate('/masters/manageconsultants')
      //       reloadPage()
      //     }, 2000)
      //   }
      // }
      else {
        // navigate('/approver')
        localStorage.setItem('Announcement', 'false')
        const token: any = await Apiservices.refreshToken()
        if (token && token.data && token.data.isError == false) {
          localStorage.setItem('refresh_Token', token.data.isError)
          setShowBackDrop(false)
          navigate('/approver')
          reloadPage()
        } else {
          localStorage.setItem('refresh_Token', 'true')
          // toast.warn('QuickBooks not enabled. Please contact your admin')
          // navigate('/approver')
          return setTimeout(function () {
            setShowBackDrop(false)
            navigate('/approver')
            reloadPage()
          }, 2000)
          // setShow(false)
          // props.setList(false)
        }
      }
      setShowBackDrop(false)

      // setIsLoading(false)
      // setShowBackDrop(false)
    } else {
      // localStorage.clear()
      removeAll()
      cookies.remove('JWT-TOkEN')
      cookies.remove('JWT_TOKEN')
      cookies.remove('org_id')
      cookies.remove('torg_id')
      cookies.remove('profileView')

      cookies.remove('JWT-TOkEN', {path: '/', domain: '.msrcosmos.com'})
      cookies.remove('JWT_TOKEN', {path: '/', domain: '.msrcosmos.com'})
      cookies.remove('org_id', {path: '/', domain: '.msrcosmos.com'})
      cookies.remove('torg_id', {path: '/', domain: '.msrcosmos.com'})
      cookies.remove('profileView', {path: '/', domain: '.msrcosmos.com'})

      // navigate('/login')
      // toast.error('Unauthorize access')
      return (
        toast.error('Unauthorize access'),
        {
          position: toast.POSITION.BOTTOM_LEFT,
        },
        setTimeout(function () {
          navigate('/login')
        }, 1000)
      )
    }
  }

  const viewUser = async () => {
    const userID = localStorage.getItem('userid')
    if (userID) {
      const data: any = await Apiservices.getuserbyid({id: userID})
      setUser(data?.data?.data?.full_name)
      gettingUser(data?.data?.data?.full_name)
    }
  }

  // const logout = async () => {
  //   const data: any = await Apiservices.getUserLogout()
  //   localStorage.clear()
  //   return (
  //     toast.error('Session expired'),
  //     {
  //       position: toast.POSITION.BOTTOM_LEFT,
  //     },
  //     setTimeout(function () {
  //       navigate('/login')
  //     }, 1000)
  //   )
  // }
  const organization = localStorage.getItem('org_name')
  return (
    <>
      <BackDrop showBackDrop={showBackDrop} />

      {/* begin::Menu toggle */}
      {localStorage.getItem('org_icon') == 'true' && (
        <Tooltip text='Organization'>
          <a
            href='#'
            className={clsx('btn btn-icon ', toggleBtnClass)}
            data-kt-menu-trigger={menuTrigger}
            data-kt-menu-attach='parent'
            data-kt-menu-placement={menuPlacement}
          >
            {mode === 'dark' && (
              <KTSVG
                path='/media/icons/duotune/finance/fin001.svg'
                className={clsx('theme-light-hide', toggleBtnIconClass)}
              />
            )}

            {mode === 'light' && (
              <>
                <KTSVG
                  path='/media/icons/duotune/finance/fin001.svg'
                  className={clsx('theme-dark-hide', toggleBtnIconClass)}
                />
              </>
            )}
          </a>
        </Tooltip>
      )}

      {/* begin::Menu toggle */}

      {/* begin::Menu */}
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-muted menu-active-bg menu-state-primary fw-semibold py-4 fs-base w-175px'
        data-kt-menu='true'
      >
        {/* begin::Menu item */}
        {organizations?.map((each: any, ChooseOrg: number) => {
          return (
            <div className='menu-item px-3 my-0' key={ChooseOrg}>
              <a
                href='#'
                className={clsx('menu-link px-3 py-2', {
                  active: each.organizationName === organization,
                })}
                // onClick={() => switchMode('light')}
                onClick={() => getUser(each)}
              >
                {/* <span className='menu-icon' data-kt-element='icon'>
                  <KTSVG path='/media/icons/duotune/general/gen060.svg' className='svg-icon-3' />
                </span> */}
                <span className='menu-title'>{each.organizationName}</span>
              </a>
            </div>
          )
        })}
        {/* end::Menu item */}
        {/* begin::Menu item */}
        {/* <div className='menu-item px-3 my-0'>
          <a
            href='#'
            className={clsx('menu-link px-3 py-2', {active: menuMode === 'dark'})}
            onClick={() => switchMode('dark')}
          >
            <span className='menu-icon' data-kt-element='icon'>
              <KTSVG path='/media/icons/duotune/general/gen061.svg' className='svg-icon-3' />
            </span>
            <span className='menu-title'>Dark</span>
          </a>
        </div> */}
        {/* end::Menu item */}
        {/* begin::Menu item */}
        {/* <div className='menu-item px-3 my-0'>
          <a
            href='#'
            className={clsx('menu-link px-3 py-2', {active: menuMode === 'system'})}
            onClick={() => switchMode('system')}
          >
            <span className='menu-icon' data-kt-element='icon'>
              <KTSVG path='/media/icons/duotune/general/gen062.svg' className='svg-icon-3' />
            </span>
            <span className='menu-title'>System</span>
          </a>
        </div> */}
        {/* end::Menu item */}
      </div>
      {/* end::Menu */}
    </>
  )
}

export {ChooseOrganizationHeader}
