import React, {useState, useRef} from 'react'
import Modal from 'react-bootstrap/Modal'
import {useForm} from 'react-hook-form'
import AddConsultant from '../masters/manageConsultants/AddConsultant'
import {Link} from 'react-router-dom'

export default function UpdateConsultant(props: any) {
  const methods = useForm()
  const values = [true]
  const [fullscreen, setFullscreen] = useState<any>(true)
  const [show, setShow] = useState(true)

  const handleClose = (myname: any) => {
    setFullscreen(false)
    setShow(false)
    props.cancel(false, myname)
  }
  const {
    register,
    handleSubmit,
    resetField,
    reset,
    setValue,
    getValues,
    formState: {errors},
  } = useForm()
  console.log(props.AdpUserId, 'vaishnavi')

  return (
    <>
      <Modal show={show} fullscreen={fullscreen} onHide={() => handleClose('pagereload')}>
        <Modal.Header closeButton className='p-3'>
          <Modal.Title>Update Consultant</Modal.Title>
        </Modal.Header>
        <Modal.Body className='p-3'>
          <AddConsultant state={props.AdpUserId} handleClose={() => handleClose('pagereload')} />
        </Modal.Body>
      </Modal>
    </>
  )
}
