import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

export default function DocFilesView(props: any) {
    const values = [true]
    const [fullscreen, setFullscreen] = useState<any>(true)
    const [show, setShow] = useState(true)

    function handleShow(breakpoint: any) {
        console.log(breakpoint, 'viewpop')
        setFullscreen(true)
        setShow(true)
    }
    const handleClose = () => {
        setFullscreen(false)
        setShow(false)
        props.cancel(false)
        props.view_open(false)
        props.setViewOpen(false)
    }

    return (
        <>
            <Modal show={show} fullscreen={fullscreen} onHide={() => handleClose()}>
                <Modal.Header closeButton>
                    <Modal.Title>Uploaded Document</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {props.view_open && (
                        <>
                            <div className='text-center'>
                                <img src={props.view_blob} />
                            </div>
                        </>
                    )}
                </Modal.Body>
            </Modal>
        </>
    )
}
