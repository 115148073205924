import React, {useMemo, useState, useEffect, useContext, useRef} from 'react'
import ThingsContext from '../../../modules/apps/masters/commonicons/GlobalContext'
import ValidContext from '../../../modules/apps/masters/commonicons/ValidateContext'
import {default as Apiservices} from '../../../../common/index'
import {useIntl} from 'react-intl'
import ReactPaginate from 'react-paginate'
import {useForm} from 'react-hook-form'
import {Container, Row, Col} from 'react-bootstrap'
import 'reactjs-popup/dist/index.css'
import clsx from 'clsx'
import {KTCardBody} from '../../../../_metronic/helpers'
import {Toolbar1} from '../../../../_metronic/layout/components/toolbar/Toolbar1'
import BackDrop from '../../../modules/apps/timesheetentry/ComponentBackdrop'
import {PageTitle} from '../../../../_metronic/layout/core'
import {useTable, ColumnInstance} from 'react-table'
import moment from 'moment'
import {Link} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {ManageListClients} from '../../../modules/apps/user-management/users-list/table/columns/_columns'
import {UsersListLoading} from '../../../modules/apps/user-management/users-list/components/loading/UsersListLoading'
import {UsersListPagination} from '../../../modules/apps/user-management/users-list/components/pagination/UsersListPagination'
import {
  useQueryResponseData,
  useQueryResponseLoading,
} from '../../../modules/apps/user-management/users-list/core/QueryResponseProvider'
import {ClearButton, Typeahead} from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import Chart from 'react-apexcharts'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {Tooltip} from '../../../modules/apps/timesheetentry/ComponentTooltip'
import Modal from 'react-bootstrap/Modal'

type FormData = {
  client: string
  dba: string
  ccdb_id: string
  qb_client_name: string
  qb_client_id: string
  id: number
  user_id: number
  user: number
  select_Year: any
  org_name: any
  employee_type: string
  project_type: string
  work_city: string
}

const RevenueMonthlyComplianceReports = (props: any) => {
  const methods = useForm()
  const values = [true]
  const [fullscreen, setFullscreen] = useState<any>(true)
  const [show, setShow] = useState(true)

  const {
    register,
    setValue,
    getValues,
    reset,
    handleSubmit,
    formState: {errors},
  } = useForm<FormData>()

  const handleClose = () => {
    setFullscreen(false)
    setShow(false)
    props.cancel(false)
    // props.view_open(false)
    // props.setViewOpen(false)
  }

  const users = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => users, [users])
  const columns = useMemo(() => ManageListClients, [])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })

  const [order5, setOrder5] = useState('ASC')
  const [order6, setOrder6] = useState('ASC')
  const [order7, setOrder7] = useState('ASC')
  const [order8, setOrder8] = useState('ASC')
  const [order9, setOrder9] = useState('ASC')
  const intl = useIntl()
  const [fromclient, setFromClient] = useState<any | []>(true)
  const [perPage, setPerPage] = useState<any>(25)
  const [disableQuickBooks, setDisableQuickBooks] = useState(false)
  const [disablecustomerdatabynameQuickBooks, setDisablecustomerdatabynameQuickBooks] =
    useState(false)
  const [page, setPage] = useState(1)
  const [showBackDrop, setShowBackDrop] = useState(false)
  const [pages, setPages] = useState(1)
  const [selected, setSelected] = useState([])
  const [selectConsultant, setSelectConsultant] = useState([])
  const [select_consultant, setSelectconsultant] = useState<any | []>([])
  const [select_consultant1, setSelectconsultant1] = useState<any | []>([])
  const [candidateList1, setCandidateList1] = useState<any | []>([])
  const [selectConsultant1, setSelectConsultant1] = useState([])
  const typeaheadRef: any = useRef<HTMLInputElement>(null)
  const typeaheadRef1: any = useRef<HTMLInputElement>(null)
  const typeaheadRef2: any = useRef<HTMLInputElement>(null)
  const typeaheadRef3: any = useRef<HTMLInputElement>(null)
  const typeaheadRef4: any = useRef<HTMLInputElement>(null)
  const typeaheadRef5: any = useRef<HTMLInputElement>(null)
  const typeaheadRef6: any = useRef<HTMLInputElement>(null)
  const typeaheadRef7: any = useRef<HTMLInputElement>(null)
  const typeaheadRef8: any = useRef<HTMLInputElement>(null)
  const [ComplianceBusinessUnit, setComplianceBusinessUnit] = useState<any | []>([])
  const [country1, setCountry1] = useState<any | []>([])
  const [candidateList, setCandidateList] = useState<any | []>([])
  // ref.current.close()

  // const typeaheadRef = useRef(null)
  const [sort, setSort] = useState<any | []>(false)
  const [filterstatus, setFilterStatus] = useState<any | []>('ACTIVE')
  const [error, setError] = useState([])
  const [filterReportStaus, setFilterReportStaus] = useState<any | []>([])
  const [orgList, setOrgList] = useState<any | []>([])
  const [employeeList, setEmployeeList] = useState<any | []>([])
  const [clientlist_data, setClient_Data] = useState<any | []>([])
  const [search, setSearch] = useState('')
  const [clientfilteropen, setClientFilterOpen] = useState<any | []>(false)
  const [resetvalues, setResetvalues] = useState(true)
  const [date, setDate] = useState('')
  const [selectYear, setSelectYear] = useState<any>(new Date())
  const [years, setYears] = useState<any>([])
  const [OrganizationReportData, setOrganizationReportData] = useState<any | []>([])
  const [ProjectReportData, setProjectReportData] = useState<any | []>([])
  const [ProjectReportCountData, setProjectReportCountData] = useState<any | []>([])
  const [chartData, setChartData] = useState<any | []>([])
  const [validatedescription, setValidateDescription] = useState<any>(false)
  const [org_name, setOrgName] = useState('')
  const [candidate, setCandidate] = useState<any | []>([])
  const [selectMonth, setSelectMonth] = useState<any>([])
  const [countries1, setCountries1] = useState<any | []>([])
  const [BusinessUnitsList, setBusinessUnitsList] = useState<any | []>([])
  const [BusinessUnit, setBusinessUnit] = useState<any | []>([])
  const [flag, setFlag] = useState(false)
  const [MonthNumbers, setMonthNumbers] = useState<any | []>([])
  const [AllOptions, setAllOptions] = useState<any | []>([
    {
      label: 'Select All',
      value: 'All',
    },
    {
      label: 'W2',
      value: 'W2',
    },
    {
      label: 'C2C',
      value: 'C2C',
    },
    // {
    //   label: 'Internal',
    //   value: 'Internal',
    // },
    {
      label: '1099',
      value: '1099',
    },
    {
      label: 'Offshore',
      value: 'Offshore',
    },
  ])
  const [AllMonthOptions, setAllMonthOptions] = useState<any | []>([
    // {
    //   label: 'Select All',
    //   value: 'All',
    // },
    {
      label: 'January',
      value: '1',
    },
    {
      label: 'February',
      value: '2',
    },
    {
      label: 'March',
      value: '3',
    },
    {
      label: 'April',
      value: '4',
    },
    {
      label: 'May',
      value: '5',
    },
    {
      label: 'June',
      value: '6',
    },
    {
      label: 'July',
      value: '7',
    },
    {
      label: 'August',
      value: '8',
    },
    {
      label: 'September',
      value: '9',
    },
    {
      label: 'October',
      value: '10',
    },
    {
      label: 'November',
      value: '11',
    },
    {
      label: 'December',
      value: '12',
    },
    {
      label: 'Deselect All',
      value: 'empty',
    },
  ])
  const [selectProjectType, setSelectProjectType] = useState<any>([])
  const [AllProjectTypeOptions, setAllProjectTypeOptions] = useState<any | []>([
    {
      label: 'Select All',
      value: 'All',
    },
    {
      label: 'Placement',
      value: '1',
    },
    {
      label: 'Bench',
      value: '2',
    },
    {
      label: 'Internal',
      value: '3',
    },
  ])
  const tokenHeader: any = useContext(ThingsContext)
  const renderThings = async () => {
    tokenHeader.getCookie()
  }

  const tokenHeader1: any = useContext(ValidContext)
  const renderThings1 = async () => {
    tokenHeader1.getValidate()
  }

  useEffect(() => {
    renderThings()
    AllOrgList()
    // handleConsultant()
    // setSelectYear(selectYear)
    setSelectYear(new Date(moment().subtract(1, 'month').format('YYYY')))
    const MonthNumberDefault = [
      {
        label: moment(new Date()).subtract(1, 'month').format('MMMM'),
        value: moment(new Date()).subtract(1, 'month').format('M'),
      },
    ]
    setSelectMonth(MonthNumberDefault)
    // let currYr: any = new Date().getFullYear()
    // setSelectYear(currYr)
    // let pastYr = 2013
    // while (currYr >= pastYr) {
    //   years.push(currYr)
    //   currYr -= 1
  }, [])
  useEffect(() => {
    get_business_units_list()
    getAllProjects()
  }, [])
  const getAllProjects = async () => {
    const res: any = await Apiservices.getprojectbyname({})
    // if (res.data.isError == false) {
    //   setProjectReportCountData(res.data.data.length)
    // }
  }
  const get_business_units_list = async () => {
    let y = JSON.parse(localStorage.getItem('bu_list') || '[]')

    let n = y.filter((element: any) => element.roleName?.toLowerCase() == 'accounts')
    let business: any = localStorage.getItem('role_Name')
    let numArray: any = business?.split(',')
    let uniqueArr: any = []

    const res: any = await Apiservices.get_business_units_list()
    if (res && res.status == 200) {
      if (res.data.data.length > 0) {
        let obj = {name: 'Select All', value: 'All'}
        if (numArray?.includes('admin')) {
          let x = res.data.data.filter((test: any) => uniqueArr.push(test))
          setBusinessUnitsList([obj, ...uniqueArr])
        } else {
          for (let each of res.data.data) {
            for (let item of n) {
              if (each.business_unit_id == item.buId) {
                uniqueArr.push(each)
              }
            }
          }
          setBusinessUnitsList([obj, ...uniqueArr])
        }
      }
    }
  }
  const handleConsultant = async (consult_Name?: any) => {
    const response: any = await Apiservices.getconsultantbyname()
    setCandidate(response?.data?.data)
  }
  const AllOrgList = async () => {
    setShowBackDrop(true)
    setFlag(true)
    let yesterday = moment(new Date()).subtract(1, 'month').format('M')
    let month1: any = [Number(yesterday)]
    setMonthNumbers(month1)
    const article1 = {
      //year: selectYear ? moment(selectYear).format('YYYY') : '',
      year: selectYear ? moment(selectYear).subtract(1, 'month').format('YYYY') : '',
      month: [Number(yesterday)], // optional  // (1=January, 12 = December )Pass null for all months data
      employee_type: null,
      user_id: null, // optional
      project_id: null, // optional
      is_placement_project: null,
      business_unit_id: null,
      pagenumber: page,
      pagesize: Number(perPage),
    }
    const article2 = {
      //year: selectYear ? moment(selectYear).format('YYYY') : '',
      year: selectYear ? moment(selectYear).subtract(1, 'month').format('YYYY') : '',
      employee_type: null,
      user_id: null, // optional
      business_unit_id: null,
    }
    // const response1: any = await Apiservices.get_yearly_revenue_report(article2) //for graph
    const response: any = await Apiservices.get_project_wise_revenue_report(article1) //for table
    if (response && response.isError == false) {
      // setProjectReportCountData(response.count[0].count)
      setPages(Math.ceil(response.count[0].count / Number(perPage)))
      // setProjectReportData(response.data)  //before format change
      let ProjDetailsArray = []
      let AllData
      for (var i = 0; i < response.data.length; i = i + parseInt(month1.length)) {
        AllData = response.data[i]
        if (!AllData.hasOwnProperty('user_business_unit_name')) {
          for (let j = i + 1; j < i + parseInt(month1.length); j++) {
            if (response.data[j].hasOwnProperty('user_business_unit_name')) {
              AllData = response.data[j]
            }
          }
        }

        let ProjDetailsArray1 = []

        if (AllData.hasOwnProperty('user_business_unit_name')) {
          for (let monthJ = 0; monthJ < month1.length; monthJ++) {
            let total_logged_hours =
              parseFloat(response.data[i + monthJ].total_logged_hours).toFixed(2) || `-`
            let total_revenue = response.data[i + monthJ].total_revenue || ``
            let monthName = response.data[i + monthJ].monthName || ``
            ProjDetailsArray1.push({
              total_logged_hours: total_logged_hours,
              total_revenue: total_revenue,
              monthName: monthName,
            })
          }
          AllData.data = ProjDetailsArray1
          ProjDetailsArray.push(AllData)
          setProjectReportData(ProjDetailsArray)
        }
      }
    }
    // if (response1 && response1.isError == false) {
    //   let orgReport = response1.total_data

    // }
    setShowBackDrop(false)
  }

  const resetData = async (e: any) => {
    setFlag(false)
    setSearch('')
    setValue('employee_type', '')
    setValue('work_city', '')
    setSelectYear(null)
    setSelectProjectType([])
    setSelectconsultant([])
    setSelectConsultant([])
    setBusinessUnit([])
    // typeaheadRef.current.clear()
    typeaheadRef1.current.clear() // Month
    setSelected([])
    setCountries1([])
    //consultant type
    // business unit

    let consultantReset = AllOptions.find((item: any) => item.value == 'All')
    if (!consultantReset) {
      AllOptions.unshift({
        label: 'Select All',
        value: 'All',
      })
    }
    // AllOptions.pop({
    //   label: 'Deselect All',
    //   value: 'empty',
    // })
    let consultantReset1 = AllOptions.find((item: any) => item.value == 'empty')
    if (consultantReset1) {
      AllOptions.splice(
        AllOptions.findIndex((item: any) => item.value == 'empty'),
        1
      )
    }

    let businessReset = BusinessUnitsList.find((item: any) => item.value == 'All')
    if (!businessReset) {
      BusinessUnitsList.unshift({
        name: 'Select All',
        value: 'All',
      })
    }
    // BusinessUnitsList.pop({
    //   name: 'Deselect All',
    //   value: 'empty',
    // })
    let businessReset1 = BusinessUnitsList.find((item: any) => item.value == 'empty')
    if (businessReset1) {
      BusinessUnitsList.splice(
        BusinessUnitsList.findIndex((item: any) => item.value == 'empty'),
        1
      )
    }

    let projectTypeReset = AllProjectTypeOptions.find((item: any) => item.value == 'All')
    if (!projectTypeReset) {
      AllProjectTypeOptions.unshift({
        label: 'Select All',
        value: 'All',
      })
      // setAllProjectTypeOptions(AllProjectTypeOptions)
    }
    // AllProjectTypeOptions.pop({
    //   label: 'Deselect All',
    //   value: 'empty',
    // })
    let projectTypeReset1 = AllProjectTypeOptions.find((item: any) => item.value == 'empty')
    if (projectTypeReset1) {
      AllProjectTypeOptions.splice(
        AllProjectTypeOptions.findIndex((item: any) => item.value == 'empty'),
        1
      )
    }
  }
  const handleChange1 = async (value: any) => {
    setSearch(value)
    // setSearch_Open(true)
    setSelectConsultant([])
    setBusinessUnit([])
    setSelectProjectType([])
    setSelectconsultant([])
    setCountries1([])

    let consultantReset = AllOptions.find((item: any) => item.value == 'All')
    if (!consultantReset) {
      AllOptions.unshift({
        label: 'Select All',
        value: 'All',
      })
    }
    // AllOptions.pop({
    //   label: 'Deselect All',
    //   value: 'empty',
    // })
    let consultantReset1 = AllOptions.find((item: any) => item.value == 'empty')
    if (consultantReset1) {
      AllOptions.splice(
        AllOptions.findIndex((item: any) => item.value == 'empty'),
        1
      )
    }

    let businessReset = BusinessUnitsList.find((item: any) => item.value == 'All')
    if (!businessReset) {
      BusinessUnitsList.unshift({
        name: 'Select All',
        value: 'All',
      })
    }
    // BusinessUnitsList.pop({
    //   name: 'Deselect All',
    //   value: 'empty',
    // })
    let businessReset1 = BusinessUnitsList.find((item: any) => item.value == 'empty')
    if (businessReset1) {
      BusinessUnitsList.splice(
        BusinessUnitsList.findIndex((item: any) => item.value == 'empty'),
        1
      )
    }

    let projectTypeReset = AllProjectTypeOptions.find((item: any) => item.value == 'All')
    if (!projectTypeReset) {
      AllProjectTypeOptions.unshift({
        label: 'Select All',
        value: 'All',
      })
      // setAllProjectTypeOptions(AllProjectTypeOptions)
    }
    // AllProjectTypeOptions.pop({
    //   label: 'Deselect All',
    //   value: 'empty',
    // })
    let projectTypeReset1 = AllProjectTypeOptions.find((item: any) => item.value == 'empty')
    if (projectTypeReset1) {
      AllProjectTypeOptions.splice(
        AllProjectTypeOptions.findIndex((item: any) => item.value == 'empty'),
        1
      )
    }

    let stat1 = selectConsultant.map((val: any) => {
      return val.value
    })
    let stat2 = stat1.filter((temp: any) => `${temp}`).join(',')
    let business1 = BusinessUnit.map((val: any) => {
      return val.business_unit_id
    })
    let business2 = business1.filter((temp: any) => `${temp}`).join(',')
    let monthCondition = selectMonth.length > 0 ? selectMonth : options3
    let month1 = monthCondition.map((temp: any) => {
      return Number(temp.value)
    })
    month1.sort((a: any, b: any) => a - b)
    let month12 = month1.filter((temp: any) => `${temp}`).join(',')
    setMonthNumbers(month1)
    let employee = select_consultant.map((value: any) => {
      return Number(value.id)
    })
    let employee1 = employee.filter((temp: any) => `${temp}`).join(',')
    let project = countries1.map((value: any) => {
      return Number(value.id)
    })
    let project1 = project.filter((temp: any) => `${temp}`).join(',')
    let project_type1 = selectProjectType.map((temp: any) => {
      return Number(temp.value)
    })
    let project_type2 = project_type1.filter((temp: any) => `${temp}`).join(',')

    const article2 = {
      year: selectYear ? moment(selectYear).format('YYYY') : '',
      month: month12 ? month1 : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12], // optional  // (1=January, 12 = December )Pass null for all months data
      employee_type: stat2 ? stat1 : null,
      user_id: employee1 ? employee : null, // optional
      project_id: project1 ? project : null, // optional
      keyword: value,
      is_placement_project: project_type2 ? project_type1 : null,
      business_unit_id: business2 ? business1 : '',
      pagenumber: null,
      pagesize: null,
    }
    if (perPage == null || perPage == '') {
      const res1: any = await Apiservices.get_overall_active_projects_count_in_selected_months(
        article2
      )
      if (res1.isError == false) {
        setProjectReportCountData(res1.count[0].count)
        var count_All_id = res1.count[0].count
        // setProjectReportProjectsData(res1.data)
        var project_All_id = res1.data
      }
    }
    const article1 = {
      year: selectYear ? moment(selectYear).format('YYYY') : '',
      month: month12 ? month1 : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12], // optional  // (1=January, 12 = December )Pass null for all months data
      employee_type: stat2 ? stat1 : null,
      user_id: employee1 ? employee : null, // optional
      keyword: value,
      // project_id: project1 ? project : project_All_id ? project_All_id : null, // optional
      project_id: project1 ? project : null, // optional
      is_placement_project: project_type2 ? project_type1 : null,
      business_unit_id: business2 ? business1 : '',
      pagenumber: page,
      // pagesize: count > 0 ? Number(count) : Number(perPage),
      pagesize: perPage == null || perPage == '' ? Number(count_All_id) : Number(perPage),
    }

    const response: any = await Apiservices.get_project_wise_revenue_report(article1)
    if (response && response.isError == false) {
      // setProjectReportCountData(response.count[0].count)
      if (perPage == null || perPage == '') {
        setPages(Math.ceil(response.count[0].count / Number(count_All_id)))
      } else {
        setPages(Math.ceil(response.count[0].count / Number(perPage)))
      }
      // setProjectReportData(response.data)
      let ProjDetailsArray = []
      let AllData
      for (var i = 0; i < response.data.length; i = i + parseInt(month1.length)) {
        AllData = response.data[i]
        if (!AllData.hasOwnProperty('user_business_unit_name')) {
          for (let j = i + 1; j < i + parseInt(month1.length); j++) {
            if (response.data[j].hasOwnProperty('user_business_unit_name')) {
              AllData = response.data[j]
            }
          }
        }

        let ProjDetailsArray1 = []

        if (AllData.hasOwnProperty('user_business_unit_name')) {
          for (let monthJ = 0; monthJ < month1.length; monthJ++) {
            let total_logged_hours =
              parseFloat(response.data[i + monthJ].total_logged_hours).toFixed(2) || `-`
            let total_revenue = response.data[i + monthJ].total_revenue || ``
            let monthName = response.data[i + monthJ].monthName || ``
            ProjDetailsArray1.push({
              total_logged_hours: total_logged_hours,
              total_revenue: total_revenue,
              monthName: monthName,
            })
          }
          AllData.data = ProjDetailsArray1
          ProjDetailsArray.push(AllData)
          setProjectReportData(ProjDetailsArray)
        }
      }
    }
  }
  const yearSelect = (year: any) => {
    setSelectYear(year)
    setDate('')
  }
  //To get all organizations
  // const OrganizationList = async () => {
  //   const data: any = await Apiservices.getOrganizationsList()
  //   if (data?.data?.isError == false) {
  //     setOrgList(data.data.data)
  //   }
  // }

  const options2: any = [
    {
      label: 'W2',
      value: 'W2',
    },
    {
      label: 'C2C',
      value: 'C2C',
    },
    // {
    //   label: 'Internal',
    //   value: 'Internal',
    // },
    {
      label: '1099',
      value: '1099',
    },
    {
      label: 'Offshore',
      value: 'Offshore',
    },
  ]

  const options4: any = [
    {
      label: 'W2',
      value: 'W2',
    },
    {
      label: 'C2C',
      value: 'C2C',
    },
    // {
    //   label: 'Internal',
    //   value: 'Internal',
    // },
    {
      label: '1099',
      value: '1099',
    },
    {
      label: 'Offshore',
      value: 'Offshore',
    },
  ]

  const options3: any = [
    {
      label: 'January',
      value: '1',
    },
    {
      label: 'February',
      value: '2',
    },
    {
      label: 'March',
      value: '3',
    },
    {
      label: 'April',
      value: '4',
    },
    {
      label: 'May',
      value: '5',
    },
    {
      label: 'June',
      value: '6',
    },
    {
      label: 'July',
      value: '7',
    },
    {
      label: 'August',
      value: '8',
    },
    {
      label: 'September',
      value: '9',
    },
    {
      label: 'October',
      value: '10',
    },
    {
      label: 'November',
      value: '11',
    },
    {
      label: 'December',
      value: '12',
    },
  ]

  const user = (candidate: any) => {
    if (candidate === null) return ''
    else {
      let str = ''

      candidate.split(' ').forEach((ele: any, i: any) => {
        str += (i == 0 ? '' : ' ') + ele.charAt(0).toUpperCase() + ele.slice(1)
      })

      return str
    }
  }
  const singleDateSelection = async (date: any) => {
    setSelectYear(date)
    setDate('')
    setSelectMonth([])
  }
  const handleOrgName = (org: any) => {
    setValue('org_name', org)
    setOrgName(org)
  }
  const sorting5 = (col: any) => {
    if (order5 === 'ASC') {
      const sorted = [...ProjectReportData]?.sort((a, b) => (a[col] > b[col] ? 1 : -1))

      setProjectReportData(sorted)
      setOrder5('DSC')
      setSort(false)
    }

    if (order5 === 'DSC') {
      const sorted = [...ProjectReportData]?.sort((a, b) => (a[col] < b[col] ? 1 : -1))

      setProjectReportData(sorted)

      setOrder5('ASC')
      setSort(true)
    }
  }

  const sorting6 = (col: any) => {
    if (order6 === 'ASC') {
      const sorted = [...ProjectReportData]?.sort((a: any, b: any) => {
        const numA = parseInt(a[col].match(/\d+/), 10) || 0
        const numB = parseInt(b[col].match(/\d+/), 10) || 0

        const lowerA = a[col].replace(/\s+/g, '').toLowerCase()
        const lowerB = b[col].replace(/\s+/g, '').toLowerCase()
        return lowerA.localeCompare(lowerB)
      })

      setProjectReportData(sorted)
      setOrder6('DSC')
      setSort(false)
    }

    if (order6 === 'DSC') {
      const sorted = [...ProjectReportData]?.sort((a: any, b: any) => {
        const numA = parseInt(a[col].match(/\d+/), 10) || 0
        const numB = parseInt(b[col].match(/\d+/), 10) || 0

        const lowerA = a[col].replace(/\s+/g, '').toLowerCase()
        const lowerB = b[col].replace(/\s+/g, '').toLowerCase()
        return lowerB.localeCompare(lowerA)
      })
      setProjectReportData(sorted)
      setOrder6('ASC')
      setSort(true)
    }
  }

  const sorting7 = (col1: any, col2: any) => {
    if (order7 === 'ASC') {
      const sorted = [...ProjectReportData]?.sort((a: any, b: any) => {
        let val1 = a[col1] ? a[col1] : a[col2]
        let val2 = b[col1] ? b[col1] : b[col2]
        return val1?.toLowerCase() > val2?.toLowerCase() ? 1 : -1
      })

      setProjectReportData(sorted)
      setOrder7('DSC')
      setSort(false)
    }

    if (order7 === 'DSC') {
      const sorted = [...ProjectReportData]?.sort((a: any, b: any) => {
        let val1 = a[col1] ? a[col1] : a[col2]
        let val2 = b[col1] ? b[col1] : b[col2]
        return val1?.toLowerCase() < val2?.toLowerCase() ? 1 : -1
      })

      setProjectReportData(sorted)
      setOrder7('ASC')
      setSort(true)
    }
  }

  const sorting8 = (col: any, col1: any, col2: any) => {
    //direct_customer,end_customer,client
    if (order8 === 'ASC') {
      const sorted = [...ProjectReportData]?.sort((a: any, b: any) => {
        let a1 = a[col] ? a[col1] : a[col2]
        let b1 = b[col] ? b[col1] : b[col2]

        return a1?.toLowerCase() > b1?.toLowerCase() ? 1 : -1
      })
      setProjectReportData(sorted)
      setOrder8('DSC')
      setSort(false)
    }

    if (order8 === 'DSC') {
      const sorted = [...ProjectReportData]?.sort((a: any, b: any) => {
        let a1 = a[col] ? a[col1] : a[col2]
        let b1 = b[col] ? b[col1] : b[col2]
        return a1?.toLowerCase() < b1?.toLowerCase() ? 1 : -1
      })
      setProjectReportData(sorted)
      setOrder8('ASC')
      setSort(true)
    }
  }

  const sorting9 = (col: any) => {
    if (order9 === 'ASC') {
      const sorted = [...ProjectReportData]?.sort((a, b) => a[col] - b[col])

      setProjectReportData(sorted)
      setOrder9('DSC')
      setSort(false)
    }

    if (order9 === 'DSC') {
      const sorted = [...ProjectReportData]?.sort((a, b) => b[col] - a[col])
      setProjectReportData(sorted)
      setOrder9('ASC')
      setSort(true)
    }
  }
  // const handleProjectType = (e: any) => {
  //   setValue('project_type', e.target.value)
  // }
  const handleProjectType = (selected: any) => {
    setSelectProjectType(selected)
    if (selected.length > 0) {
      for (let each of selected) {
        if (each.value == 'All') {
          let x = AllProjectTypeOptions.filter(
            (temp: any) => temp.value != 'All' && temp.value != 'empty'
          )
          setSelectProjectType(x)
          AllProjectTypeOptions.shift({
            label: 'Select All',
            value: 'All',
          })
          let abc = AllProjectTypeOptions.find((item: any) => item.value == 'empty')
          if (!abc) {
            AllProjectTypeOptions.push({
              label: 'Deselect All',
              value: 'empty',
            })
            setAllProjectTypeOptions(AllProjectTypeOptions)
          }
        } else if (each.value == 'empty') {
          setSelectProjectType([])
          let cde = AllProjectTypeOptions.find((item: any) => item.value == 'All')
          if (!cde) {
            AllProjectTypeOptions.unshift({
              label: 'Select All',
              value: 'All',
            })
            // setAllProjectTypeOptions(AllProjectTypeOptions)
          }
          AllProjectTypeOptions.pop({
            label: 'Deselect All',
            value: 'empty',
          })
        } else {
          setSelectProjectType(selected)
          let abc = AllProjectTypeOptions.find((item: any) => item.value == 'empty')
          let cde = AllProjectTypeOptions.find((item: any) => item.value == 'All')

          if (!abc) {
            AllProjectTypeOptions.push({
              label: 'Deselect All',
              value: 'empty',
            })
            setAllProjectTypeOptions(AllProjectTypeOptions)
          }
          if (!cde) {
            AllProjectTypeOptions.unshift({
              label: 'Select All',
              value: 'All',
            })
            setAllProjectTypeOptions(AllProjectTypeOptions)
          }

          let check_drop = selected.find((item: any) => item.value == 'All')
          let check_drop1 = selected.find((item: any) => item.value == 'empty')

          if (!check_drop && !check_drop1) {
            let test = AllProjectTypeOptions.filter(
              (id: any) => !selected.some((x: any) => x.value == id.value)
            )
            if (test.length == 2) {
              let sample = test.filter((res: any) => res.value == 'All' || res.value == 'empty')
              AllProjectTypeOptions.shift({
                label: 'Select All',
                value: 'All',
              })
            }
          }
        }
      }
    } else {
      setSelectProjectType(selected)
      AllProjectTypeOptions.pop({
        label: 'Deselect All',
        value: 'empty',
      })

      let projectTypeReset = AllProjectTypeOptions.find((item: any) => item.value == 'All')
      if (!projectTypeReset) {
        AllProjectTypeOptions.unshift({
          name: 'Select All',
          value: 'All',
        })
      }
    }
  }
  const onChange = async (e: any) => {
    const {name, value} = e.target
    if (name == 'perPage') {
      setPerPage(value)
      setPage(1)
      handleClick1(1, value)
    }
  }
  const handlePageClick = (event: any) => {
    let page = event.selected
    let selected = page + 1
    setPage(selected)
    // getallprojects(mainTableData, selected, perPage)
    // handleClick1(selected, perPage, ProjectReportCountData)
    handleClick1(selected, perPage)
  }

  // const onChange = async (e: any) => {
  //   const {name, value} = e.target
  //   let stat1 = selectConsultant.map((val: any) => {
  //     return val.value
  //   })
  //   let stat2 = stat1.filter((temp: any) => `${temp}`).join(',')
  //   let business1 = BusinessUnit.map((val: any) => {
  //     return val.business_unit_id
  //   })
  //   let business2 = business1.filter((temp: any) => `${temp}`).join(',')
  //   let monthCondition = selectMonth.length > 0 ? selectMonth : options3
  //   let month1 = monthCondition.map((temp: any) => {
  //     return Number(temp.value)
  //   })
  //   month1.sort((a: any, b: any) => a - b)
  //   let month12 = month1.filter((temp: any) => `${temp}`).join(',')
  //   setMonthNumbers(month1)
  //   let employee = select_consultant.map((value: any) => {
  //     return Number(value.id)
  //   })
  //   let employee1 = employee.filter((temp: any) => `${temp}`).join(',')
  //   let project = countries1.map((value: any) => {
  //     return Number(value.id)
  //   })
  //   let project1 = project.filter((temp: any) => `${temp}`).join(',')
  //   let project_type1 = selectProjectType.map((temp: any) => {
  //     return Number(temp.value)
  //   })
  //   let project_type2 = project_type1.filter((temp: any) => `${temp}`).join(',')

  //   if (name == 'perPage') {
  //     if (e.target.value == null || e.target.value == '') {
  //       const article1 = {
  //         year: selectYear ? moment(selectYear).format('YYYY') : '',
  //         month: month12 ? month1 : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12], // optional  // (1=January, 12 = December )Pass null for all months data
  //         employee_type: stat2 ? stat1 : null,
  //         user_id: employee1 ? employee : null, // optional
  //         project_id: project1 ? project : null, // optional
  //         is_placement_project: project_type2 ? project_type1 : null,
  //         business_unit_id: business2 ? business1 : '',
  //         pagenumber: null,
  //         pagesize: null,
  //       }
  //       setShowBackDrop(true)
  //       const response: any =
  //         await Apiservices.get_overall_active_projects_count_in_selected_months(article1)
  //       if (response.isError == false) {
  //         setProjectReportCountData(response.count[0].count)
  //         setPerPage(value)
  //         setPage(1)
  //         handleClick1(1, value, response.count[0].count)
  //       }
  //       setShowBackDrop(false)
  //     } else {
  //       setPerPage(value)
  //       setPage(1)
  //       handleClick1(1, value, ProjectReportCountData)
  //     }
  //   }
  // }

  // const handleClick1 = async () => {
  // const handleClick1 = async (page: any, perPage: any, count: any) => {
  const handleClick1 = async (page: any, perPage: any) => {
    if (
      selectMonth == null ||
      selectMonth == [] ||
      selectMonth == '' ||
      selectMonth?.length == 0 ||
      selectMonth == undefined
    ) {
      setValidateDescription(true)
      setProjectReportData([])
      setFlag(false)
    } else {
      setValidateDescription(false)
    }

    setProjectReportData([])

    let yesterday = moment(new Date()).subtract(1, 'month').format('M')
    let stat1 = selectConsultant.map((val: any) => {
      return val.value
    })
    let stat2 = stat1.filter((temp: any) => `${temp}`).join(',')
    let business1 = BusinessUnit.map((val: any) => {
      return val.business_unit_id
    })
    let business2 = business1.filter((temp: any) => `${temp}`).join(',')
    let monthCondition = selectMonth.length > 0 ? selectMonth : options3
    let month1 = monthCondition.map((temp: any) => {
      return Number(temp.value)
    })
    month1.sort((a: any, b: any) => a - b)
    let month12 = month1.filter((temp: any) => `${temp}`).join(',')
    setMonthNumbers(month1)
    let employee = select_consultant.map((value: any) => {
      return Number(value.id)
    })
    let employee1 = employee.filter((temp: any) => `${temp}`).join(',')
    let project = countries1.map((value: any) => {
      return Number(value.id)
    })
    let project1 = project.filter((temp: any) => `${temp}`).join(',')
    let project_type1 = selectProjectType.map((temp: any) => {
      return Number(temp.value)
    })
    let project_type2 = project_type1.filter((temp: any) => `${temp}`).join(',')

    if (
      selectMonth == null ||
      selectMonth == [] ||
      selectMonth == '' ||
      selectMonth?.length == 0 ||
      selectMonth == undefined
    ) {
      console.log('if')
    } else {
      setFlag(true)
      setShowBackDrop(true)
      const article2 = {
        year: selectYear ? moment(selectYear).format('YYYY') : '',
        month: month12 ? month1 : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12], // optional  // (1=January, 12 = December )Pass null for all months data
        employee_type: stat2 ? stat1 : null,
        user_id: employee1 ? employee : null, // optional
        project_id: project1 ? project : null, // optional
        keyword: search ? search : null,
        is_placement_project: project_type2 ? project_type1 : null,
        business_unit_id: business2 ? business1 : '',
        pagenumber: null,
        pagesize: null,
      }
      if (perPage == null || perPage == '') {
        const res1: any = await Apiservices.get_overall_active_projects_count_in_selected_months(
          article2
        )
        if (res1.isError == false) {
          setProjectReportCountData(res1.count[0].count)
          var count_All_id = res1.count[0].count
          // setProjectReportProjectsData(res1.data)
          var project_All_id = res1.data
        }
      }
      const article1 = {
        year: selectYear ? moment(selectYear).format('YYYY') : '',
        month: month12 ? month1 : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12], // optional  // (1=January, 12 = December )Pass null for all months data
        employee_type: stat2 ? stat1 : null,
        user_id: employee1 ? employee : null, // optional
        keyword: search ? search : null,
        // project_id: project1 ? project : project_All_id ? project_All_id : null, // optional
        project_id: project1 ? project : null, // optional
        is_placement_project: project_type2 ? project_type1 : null,
        business_unit_id: business2 ? business1 : '',
        pagenumber: page,
        // pagesize: count > 0 ? Number(count) : Number(perPage),
        pagesize: perPage == null || perPage == '' ? Number(count_All_id) : Number(perPage),
      }

      const response: any = await Apiservices.get_project_wise_revenue_report(article1)
      if (response && response.isError == false) {
        // setProjectReportCountData(response.count[0].count)
        if (perPage == null || perPage == '') {
          setPages(Math.ceil(response.count[0].count / Number(count_All_id)))
        } else {
          setPages(Math.ceil(response.count[0].count / Number(perPage)))
        }
        // setProjectReportData(response.data)
        let ProjDetailsArray = []
        let AllData
        for (var i = 0; i < response.data.length; i = i + parseInt(month1.length)) {
          AllData = response.data[i]
          if (!AllData.hasOwnProperty('user_business_unit_name')) {
            for (let j = i + 1; j < i + parseInt(month1.length); j++) {
              if (response.data[j].hasOwnProperty('user_business_unit_name')) {
                AllData = response.data[j]
              }
            }
          }

          let ProjDetailsArray1 = []

          if (AllData.hasOwnProperty('user_business_unit_name')) {
            for (let monthJ = 0; monthJ < month1.length; monthJ++) {
              let total_logged_hours =
                parseFloat(response.data[i + monthJ].total_logged_hours).toFixed(2) || `-`
              let total_revenue = response.data[i + monthJ].total_revenue || ``
              let monthName = response.data[i + monthJ].monthName || ``
              ProjDetailsArray1.push({
                total_logged_hours: total_logged_hours,
                total_revenue: total_revenue,
                monthName: monthName,
              })
            }
            AllData.data = ProjDetailsArray1
            ProjDetailsArray.push(AllData)
            setProjectReportData(ProjDetailsArray)
          }
        }
      }
      setShowBackDrop(false)
    }
  }
  const exporthandleClick = async (e: any) => {
    setShowBackDrop(true)
    let stat1 = selectConsultant1.map((val: any) => {
      return val.value
    })
    let stat2 = stat1.filter((temp: any) => `${temp}`).join(',')

    let employee = select_consultant1.map((value: any) => {
      return Number(value.id)
    })
    let employee1 = employee.filter((temp: any) => `${temp}`).join(',')
    let business1 = ComplianceBusinessUnit.map((val: any) => {
      return val.business_unit_id
    })
    let business2 = business1.filter((temp: any) => `${temp}`).join(',')
    const article2 = {
      year: selectYear ? moment(selectYear).format('YYYY') : '',
      employee_type: stat2 ? stat1 : null,
      user_id: employee1 ? employee : null, // optional
      business_unit_id: business2 ? business1 : '',
    }
    const response: any = await Apiservices.export_yearly_revenue_report(article2)
    var link = document.createElement('a')
    // If you don't know the name or want to use
    // the webserver default set name = ''
    link.setAttribute('download', 'Downloaded File')
    link.href = response?.data?.blobUrl
    document.body.appendChild(link)
    link.click()
    link.remove()
    setShowBackDrop(false)
  }

  const exporthandleClick1 = async (e: any) => {
    if (
      selectMonth == null ||
      selectMonth == [] ||
      selectMonth == '' ||
      selectMonth?.length == 0 ||
      selectMonth == undefined
    ) {
      setValidateDescription(true)
      // setProjectReportData([])
      setFlag(false)
    } else {
      setValidateDescription(false)
    }

    let yesterday = moment(new Date()).subtract(1, 'month').format('M')
    let stat1 = selectConsultant.map((val: any) => {
      return val.value
    })
    let stat2 = stat1.filter((temp: any) => `${temp}`).join(',')
    let business1 = BusinessUnit.map((val: any) => {
      return val.business_unit_id
    })
    let business2 = business1.filter((temp: any) => `${temp}`).join(',')
    let month1 = selectMonth.map((temp: any) => {
      return Number(temp.value)
    })
    month1.sort((a: any, b: any) => a - b)
    let month12 = month1.filter((temp: any) => `${temp}`).join(',')
    let employee = select_consultant.map((value: any) => {
      return Number(value.id)
    })
    let employee1 = employee.filter((temp: any) => `${temp}`).join(',')
    let project = countries1.map((value: any) => {
      return Number(value.id)
    })
    let project1 = project.filter((temp: any) => `${temp}`).join(',')
    let project_type1 = selectProjectType.map((temp: any) => {
      return Number(temp.value)
    })
    let project_type2 = project_type1.filter((temp: any) => `${temp}`).join(',')

    if (
      selectMonth == null ||
      selectMonth == [] ||
      selectMonth == '' ||
      selectMonth?.length == 0 ||
      selectMonth == undefined
    ) {
      console.log('if')
    } else {
      setShowBackDrop(true)
      const article1 = {
        year: selectYear ? moment(selectYear).format('YYYY') : '',
        // month: month12 ? month1 : [Number(yesterday)], // optional  // (1=January, 12 = December )Pass null for all months data
        month: month12 ? month1 : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12], // optional  // (1=January, 12 = December )Pass null for all months data
        employee_type: stat2 ? stat1 : null,
        user_id: employee1 ? employee : null, // optional
        project_id: project1 ? project : null, // optional
        is_placement_project: project_type2 ? project_type1 : null,
        business_unit_id: business2 ? business1 : '',
      }
      const response: any = await Apiservices.export_project_wise_revenue_report(article1)
      var link = document.createElement('a')
      // If you don't know the name or want to use
      // the webserver default set name = ''
      link.setAttribute('download', 'Downloaded File')
      link.href = response?.data?.blobUrl
      document.body.appendChild(link)
      link.click()
      link.remove()
      setShowBackDrop(false)
    }
  }
  const handleCountries1 = async (country1: any) => {
    // if (country1 && country1.length >= 2) {
    const response: any = await Apiservices.getprojectbyname({country_name: country1})
    if (response?.data?.data !== null) {
      setCountry1(response && response.data && response.data.data ? response?.data?.data : '')
    }
    // }
  }

  const selectedCountries1 = async (state: any) => {
    setCountries1(state)
  }
  const handleFilterConsultant = async (consult_Name: any) => {
    // let filteredConsultant = candidate?.filter((each: any) =>
    //   each?.full_name?.toLowerCase()?.includes(consult_Name?.toLowerCase())
    // )
    // setCandidateList(filteredConsultant)
    const response: any = await Apiservices.getconsultantbyname()
    setCandidateList(response?.data?.data)
  }

  const selectedConsultant = async (consult_name: any) => {
    setSelectconsultant(consult_name)
  }

  const handleFilterConsultant1 = async (consult_Name: any) => {
    const response: any = await Apiservices.getconsultantbyname()
    setCandidateList1(response?.data?.data)
  }
  const selectedConsultant1 = async (consult_name: any) => {
    setSelectconsultant1(consult_name)
    setSelectconsultant(consult_name)
  }
  // const handleConsultantType = (select: any) => {
  //   setSelectConsultant1(select)
  //   setSelectConsultant(select)
  // }
  // const handleBusinessUnit = (select: any) => {
  //   setComplianceBusinessUnit(select)
  //   setBusinessUnit(select)
  // }
  const handleBusinessUnit = (selected: any) => {
    // setBusinessUnit(selected)

    if (selected.length > 0) {
      for (let each of selected) {
        if (each.value == 'All') {
          let x = BusinessUnitsList.filter(
            (temp: any) => temp.value != 'All' && temp.value != 'empty'
          )
          setBusinessUnit(x)
          BusinessUnitsList.shift({
            name: 'Select All',
            value: 'All',
          })
          let abc = BusinessUnitsList.find((item: any) => item.value == 'empty')
          if (!abc) {
            BusinessUnitsList.push({
              name: 'Deselect All',
              value: 'empty',
            })
            setBusinessUnitsList(BusinessUnitsList)
          }
        } else if (each.value == 'empty') {
          setBusinessUnit([])
          let cde = BusinessUnitsList.find((item: any) => item.value == 'All')

          if (!cde) {
            BusinessUnitsList.unshift({
              name: 'Select All',
              value: 'All',
            })
            // setBusinessUnitsList(BusinessUnitsList)
          }
          BusinessUnitsList.pop({
            name: 'Deselect All',
            value: 'empty',
          })
        } else {
          setBusinessUnit(selected)
          let abc = BusinessUnitsList.find((item: any) => item.value == 'empty')
          let cde = BusinessUnitsList.find((item: any) => item.value == 'All')

          if (!abc) {
            BusinessUnitsList.push({
              name: 'Deselect All',
              value: 'empty',
            })
            setBusinessUnitsList(BusinessUnitsList)
          }
          if (!cde) {
            BusinessUnitsList.unshift({
              name: 'Select All',
              value: 'All',
            })
            setBusinessUnitsList(BusinessUnitsList)
          }

          let check_drop = selected.find((item: any) => item && item.value && item.value == 'All')
          let check_drop1 = selected.find(
            (item: any) => item && item.value && item.value == 'empty'
          )
          if (!check_drop && !check_drop1) {
            let test = BusinessUnitsList.filter(
              (id: any) => !selected.some((x: any) => x.name == id.name)
            )
            if (test.length == 2) {
              let sample = test.filter((res: any) => res.value == 'All' || res.value == 'empty')
              BusinessUnitsList.shift({
                name: 'Select All',
                value: 'All',
              })
            }
          }
        }
      }
    } else {
      setBusinessUnit(selected)
      BusinessUnitsList.pop({
        label: 'Deselect All',
        value: 'empty',
      })

      let businessReset = BusinessUnitsList.find((item: any) => item.value == 'All')
      if (!businessReset) {
        BusinessUnitsList.unshift({
          name: 'Select All',
          value: 'All',
        })
      }
    }
  }
  const handleConsultantType = (selected: any) => {
    if (selected.length > 0) {
      for (let each of selected) {
        if (each.value == 'All') {
          let x = AllOptions.filter((temp: any) => temp.value != 'All' && temp.value != 'empty')
          setSelectConsultant(x)
          AllOptions.shift({
            label: 'Select All',
            value: 'All',
          })
          let abc = AllOptions.find((item: any) => item.value == 'empty')
          if (!abc) {
            AllOptions.push({
              label: 'Deselect All',
              value: 'empty',
            })
            setAllOptions(AllOptions)
          }
        } else if (each.value == 'empty') {
          setSelectConsultant([])
          let cde = AllOptions.find((item: any) => item.value == 'All')
          if (!cde) {
            AllOptions.unshift({
              label: 'Select All',
              value: 'All',
            })
            // setAllOptions(AllOptions)
          }
          AllOptions.pop({
            label: 'Deselect All',
            value: 'empty',
          })
        } else {
          setSelectConsultant(selected)
          let abc = AllOptions.find((item: any) => item.value == 'empty')
          let cde = AllOptions.find((item: any) => item.value == 'All')
          if (!abc) {
            AllOptions.push({
              label: 'Deselect All',
              value: 'empty',
            })
            // setAllOptions(AllOptions)
          }
          if (!cde) {
            AllOptions.unshift({
              label: 'Select All',
              value: 'All',
            })
            setAllOptions(AllOptions)
          }
          let check_drop = selected.find((item: any) => item.value == 'All')
          let check_drop1 = selected.find((item: any) => item.value == 'empty')

          if (!check_drop && !check_drop1) {
            let test = AllOptions.filter(
              (id: any) => !selected.some((x: any) => x.value == id.value)
            )
            if (test.length == 2) {
              let sample = test.filter((res: any) => res.value == 'All' || res.value == 'empty')
              AllOptions.shift({
                label: 'Select All',
                value: 'All',
              })
            }
          }
        }
      }
    } else {
      setSelectConsultant(selected)
      AllOptions.pop({
        label: 'Deselect All',
        value: 'empty',
      })

      let consultantReset = AllOptions.find((item: any) => item.value == 'All')
      if (!consultantReset) {
        AllOptions.unshift({
          label: 'Select All',
          value: 'All',
        })
      }
    }
    // setSelectConsultant(selected)
  }

  const handleMonth = (selected: any) => {
    // setSelectMonth(selected)
    if (selected?.length > 0) {
      setValidateDescription(false)
    }
    if (selected.length > 0) {
      if (selected.length < 4 || selected.find((item: any) => item.value == 'empty')) {
        for (let each of selected) {
          // if (each.value == 'All') {
          //   let x = AllMonthOptions.filter(
          //     (temp: any) => temp.value != 'All' && temp.value != 'empty'
          //   )
          //   setSelectMonth(x)
          //   AllMonthOptions.shift({
          //     label: 'Select All',
          //     value: 'All',
          //   })
          //   let abc = AllMonthOptions.find((item: any) => item.value == 'empty')
          //   if (!abc) {
          //     AllMonthOptions.push({
          //       label: 'Deselect All',
          //       value: 'empty',
          //     })
          //     // setAllMonthOptions(AllMonthOptions)
          //   }
          // }
          if (each.value == 'empty') {
            setSelectMonth([])
            let cde = AllMonthOptions.find((item: any) => item.value == 'All')
            // if (!cde) {
            //   AllMonthOptions.unshift({
            //     label: 'Select All',
            //     value: 'All',
            //   })
            // }
            AllMonthOptions.pop({
              label: 'Deselect All',
              value: 'empty',
            })
          } else {
            setSelectMonth(selected)
            let abc = AllMonthOptions.find((item: any) => item.value == 'empty')
            let cde = AllMonthOptions.find((item: any) => item.value == 'All')

            if (!abc) {
              AllMonthOptions.push({
                label: 'Deselect All',
                value: 'empty',
              })
              // setAllMonthOptions(AllMonthOptions)
            }

            // if (!cde) {
            //   AllMonthOptions.unshift({
            //     label: 'Select All',
            //     value: 'All',
            //   })
            //   setAllMonthOptions(AllMonthOptions)
            // }
            let check_drop = selected.find((item: any) => item.value == 'All')
            let check_drop1 = selected.find((item: any) => item.value == 'empty')

            if (!check_drop && !check_drop1) {
              let test = AllMonthOptions.filter(
                (id: any) => !selected.some((x: any) => x.value == id.value)
              )
              if (test.length == 2) {
                let sample = test.filter((res: any) => res.value == 'All' || res.value == 'empty')
                // AllMonthOptions.shift({
                //   label: 'Select All',
                //   value: 'All',
                // })
              }
            }
          }
        }
      }
    } else {
      setSelectMonth(selected)
      let monthReset1 = AllMonthOptions.find((item: any) => item.value == 'empty')
      if (monthReset1) {
        AllMonthOptions.pop({
          label: 'Deselect All',
          value: 'empty',
        })
      }
      let monthReset = AllMonthOptions.find((item: any) => item.value == 'All')
      // if (!monthReset) {
      //   AllMonthOptions.unshift({
      //     label: 'Select All',
      //     value: 'All',
      //   })
      // }
    }
  }
  return (
    <>
      <Modal show={show} fullscreen={fullscreen} onHide={() => handleClose()}>
        <Modal.Header closeButton className='p-3 bg-light'>
          <Modal.Title>Revenue Monthly Report </Modal.Title>
        </Modal.Header>
        <Modal.Body className='p-3 bg-light'>
          <div className='d-flex justify-content-between align-items-center'>
            <div> </div>
          </div>
          <BackDrop showBackDrop={showBackDrop} />
          <div className='py-1'>
            <div className='card card-custom card-flush '>
              <div className='card-body  py-1 px-5'>
                <div className='row gy-3 align-items-center py-2'>
                  <div className='col-xs-12 col-md-3 col-lg '>
                    <label className='form-label '> Year *</label>
                    <DatePicker
                      selected={selectYear}
                      onChange={(date: any) => singleDateSelection(date)}
                      placeholderText='Select Year'
                      className={clsx('form-control')}
                      // disabled={true}
                      dateFormat='yyyy'
                      showYearPicker
                      required
                    />
                  </div>
                  <div className='col-xs-12 col-md-3 col-lg '>
                    <label className='form-label '>Select Month *</label> (Maximum 3 months)
                    {/* <Typeahead
                      multiple
                      id='keep-menu-open'
                      onChange={(selected: any) => {
                        setSelectMonth(selected)
                        // Keep the menu open when making multiple selections.
                        typeaheadRef1.current.toggleMenu()
                      }}
                      options={options3}
                      placeholder='Select Month'
                      ref={typeaheadRef1}
                      selected={selectMonth}
                    /> */}
                    <Typeahead
                      multiple
                      id='keep-menu-open'
                      onChange={(selected: any) => {
                        // setSelectMonth(selected)
                        handleMonth(selected)
                        // Keep the menu open when making multiple selections.
                        typeaheadRef1.current.toggleMenu()
                      }}
                      options={AllMonthOptions}
                      placeholder='Select Month'
                      ref={typeaheadRef1}
                      selected={selectMonth}
                      // inputProps={{
                      //   required: true,
                      // }}
                    />
                    {validatedescription && (
                      <span className='error text-danger mb-0'>Month is required</span>
                    )}
                    <div className='invalid-feedback'>{errors.employee_type?.message}</div>
                  </div>
                  <div className='col-xs-12 col-md-3 col-lg'>
                    <label className='form-label '>Consultant Type</label>
                    {/* <Typeahead
                      multiple
                      id='keep-menu-open'
                      onChange={(selected: any) => {
                        setSelectConsultant(selected)
                        // Keep the menu open when making multiple selections.
                        typeaheadRef2.current.toggleMenu()
                      }}
                      options={options2}
                      placeholder='Select Consultant Type'
                      ref={typeaheadRef2}
                      selected={selectConsultant}
                    /> */}

                    <Typeahead
                      multiple
                      id='keep-menu-open'
                      onChange={(selected: any) => {
                        handleConsultantType(selected)
                        // setSelectConsultant(selected)
                        // Keep the menu open when making multiple selections.
                        typeaheadRef2.current.toggleMenu()
                      }}
                      options={AllOptions}
                      placeholder='Select Consultant Type'
                      ref={typeaheadRef2}
                      selected={selectConsultant}
                    />
                    {/* <div className='invalid-feedback'>{errors.last_name?.message}</div> */}
                  </div>
                  <div className='col-xs-12 col-md-3 col-lg'>
                    <label className='form-label '>Business Unit</label>
                    {/* <Typeahead
                      multiple
                      id='keep-menu-open'
                      // disabled={isView}
                      onChange={(e) => {
                        setBusinessUnit(e)
                        typeaheadRef7.current.toggleMenu()
                      }}
                      options={BusinessUnitsList}
                      ref={typeaheadRef7}
                      placeholder='Select Business Unit'
                      selected={BusinessUnit}
                      labelKey={(BusinessUnitsList: any) => BusinessUnitsList.name}
                      //   inputProps={{required: true}}
                    >
                    </Typeahead> */}

                    <Typeahead
                      multiple
                      id='keep-menu-open'
                      // disabled={isView}
                      onChange={(e) => {
                        // setBusinessUnit(e)
                        handleBusinessUnit(e)
                        typeaheadRef7.current.toggleMenu()
                      }}
                      options={BusinessUnitsList}
                      ref={typeaheadRef7}
                      placeholder='Select Business Unit'
                      selected={BusinessUnit}
                      labelKey={(BusinessUnitsList: any) => BusinessUnitsList.name}
                      //   inputProps={{required: true}}
                    ></Typeahead>
                    {/* <div className='invalid-feedback'>{errors.last_name?.message}</div> */}
                  </div>
                  <div className='col-xs-12 col-md-3 col-lg'>
                    <label className='form-label '>Project Type </label>
                    {/* <select
                      // value={selectedItem}
                      {...register('project_type')}
                      onChange={handleProjectType}
                      className={clsx('form-select')}
                    >
                      <option value=''>Select Project Type</option>
                      <option value='1'>Placement</option>
                      <option value='2'>Bench Project</option>
                      <option value='3'>Internal</option>
                    </select> */}

                    <Typeahead
                      multiple
                      id='keep-menu-open'
                      onChange={(selected: any) => {
                        // setSelectProjectType(selected)
                        handleProjectType(selected)
                        // Keep the menu open when making multiple selections.
                        typeaheadRef5.current.toggleMenu()
                      }}
                      options={AllProjectTypeOptions}
                      placeholder='Select Project Type'
                      ref={typeaheadRef5}
                      selected={selectProjectType}
                    />

                    {/* <div className='invalid-feedback'>{errors.user_status?.message}</div> */}
                  </div>
                  <div className='col-xs-12 col-md-3 col-lg'>
                    <label className='form-label '>Employee Name</label>
                    <Typeahead
                      multiple
                      id='keep-menu-open'
                      // disabled={isView}
                      onChange={(e) => {
                        selectedConsultant(e)
                        typeaheadRef3.current.toggleMenu()
                      }}
                      onInputChange={(e) => handleFilterConsultant(e)}
                      options={candidateList}
                      ref={typeaheadRef3}
                      placeholder='Search Employee'
                      selected={select_consultant}
                      labelKey={(candidateList: any) => user(candidateList.full_name)}
                      //   inputProps={{required: true}}
                    >
                      {/* {({onClear, selected}) => (
                    <div className='rbt-aux'>
                      {!!selected.length && (
                        <ClearButton disabled={AllFieldsToDisabled} onClick={onClear} />
                      )}
                    </div>
                  )} */}
                    </Typeahead>
                    {/* <div className='invalid-feedback'>{errors.last_name?.message}</div> */}
                  </div>
                  <div className='col-xs-12 col-md-3 col-lg'>
                    <label className='form-label '>Project Name</label>
                    <Typeahead
                      multiple
                      id='keep-menu-open'
                      onChange={(e) => {
                        selectedCountries1(e)
                        typeaheadRef4.current.toggleMenu()
                      }}
                      onInputChange={(e) => {
                        handleCountries1(e)
                      }}
                      placeholder='Please Enter Project Name'
                      ref={typeaheadRef4}
                      options={country1}
                      selected={countries1}
                      labelKey={(country1: any) => country1.project_name}
                    >
                      {/* {({onClear, selected}) => (
                            <div className='rbt-aux'>
                              {!!selected.length && <ClearButton onClick={onClear} />}
                            </div>
                          )} */}
                    </Typeahead>
                    {/* <div className='invalid-feedback'>{errors.last_name?.message}</div> */}
                  </div>
                  <div className='col-xs-12 col-md-3 col-lg'>
                    <label className='form-label '>Search</label>
                    <input
                      type='text'
                      id='form1'
                      className='form-control'
                      // value={search}
                      {...register('work_city')}
                      placeholder='Search'
                      onChange={(e) => handleChange1(e.target.value)}
                    />
                  </div>
                  <div className='col-auto pt-md-0 pt-lg-8'>
                    <button
                      className='btn btn-sm btn-primary'
                      type='submit'
                      disabled={selectMonth.length > 3 ? true : false}
                      // onClick={() => handleClick1()}
                      // onClick={() => handleClick1(page, perPage, ProjectReportCountData)}
                      onClick={() => handleClick1(page, perPage)}
                    >
                      Run
                    </button>
                  </div>
                  <div className='col-auto pt-md-0 pt-lg-8'>
                    <button
                      type='button'
                      className='btn btn-danger btn-sm cursor-pointer '
                      id='kt_toolbar_primary_button'
                      onClick={resetData}
                    >
                      Reset
                    </button>
                  </div>
                  <div className='col-auto pt-md-0 pt-lg-8'>
                    <button
                      // className='btn btn-icon btn-light  btn-active-color-primary btn-sm'
                      className='btn btn-light-primary   btn-sm'
                      onClick={exporthandleClick1}
                      disabled={selectMonth.length > 3 ? true : false}
                    >
                      Export
                      {/* <i className='bi bi-cloud-arrow-down-fill fs-2'></i> */}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* {flag == true && ( */}
          {flag == true ? (
            <>
              <div className='pt-3'>
                <div className='card card-custom card-flush '>
                  <div className='card-body p-3 py-3'>
                    <div className='table-responsive padding-down reportTab'>
                      <table
                        id='kt_table_users'
                        className='table table-row-bordered table-row-gray-300 gy-4 table-two-headers-sticky'
                      >
                        <>
                          <thead>
                            <tr className='fw-semibold fs-7 text-gray-600 text-uppercase'>
                              <th
                                onClick={() => sorting6('user_business_unit_name')}
                                className='  py-4'
                              >
                                Business Unit{' '}
                                <span>
                                  {order6 === 'ASC' ? (
                                    <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                  ) : (
                                    <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                  )}
                                </span>
                              </th>
                              <th onClick={() => sorting6('employee_id')} className='  py-4'>
                                Consultant ID{' '}
                                <span>
                                  {order6 === 'ASC' ? (
                                    <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                  ) : (
                                    <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                  )}
                                </span>
                              </th>
                              <th onClick={() => sorting5('employee_type')} className='  py-4'>
                                Consultant Type{' '}
                                <span>
                                  {order5 === 'ASC' ? (
                                    <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                  ) : (
                                    <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                  )}
                                </span>
                              </th>
                              <th
                                onClick={() => sorting7('display_name', 'full_name')}
                                className='  py-4'
                              >
                                Consultant Name{' '}
                                <span>
                                  {order7 === 'ASC' ? (
                                    <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                  ) : (
                                    <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                  )}
                                </span>
                              </th>
                              <th onClick={() => sorting6('placement_code')} className='  py-4'>
                                Placement Code{' '}
                                <span>
                                  {order6 === 'ASC' ? (
                                    <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                  ) : (
                                    <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                  )}
                                </span>
                              </th>
                              <th
                                onClick={() => sorting5('is_placement_project')}
                                className='  py-4'
                              >
                                Project Type{' '}
                                <span>
                                  {order5 === 'ASC' ? (
                                    <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                  ) : (
                                    <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                  )}
                                </span>
                              </th>
                              <th onClick={() => sorting5('project_name')} className='  py-4'>
                                Project Name{' '}
                                <span>
                                  {order5 === 'ASC' ? (
                                    <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                  ) : (
                                    <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                  )}
                                </span>
                              </th>
                              <th
                                onClick={() =>
                                  sorting8(
                                    'direct_customer_engagement',
                                    'end_customer',
                                    'client_name'
                                  )
                                }
                                className='  py-4'
                              >
                                Client Name{' '}
                                <span>
                                  {order8 === 'ASC' ? (
                                    <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                  ) : (
                                    <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                  )}
                                </span>
                              </th>
                              <th onClick={() => sorting5('start_date')} className='  py-4'>
                                Start Date <span className='fs-9'>(DD-MMM-YYYY)</span>{' '}
                                <span>
                                  {order5 === 'ASC' ? (
                                    <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                  ) : (
                                    <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                  )}
                                </span>
                              </th>
                              <th onClick={() => sorting5('project_status')} className='  py-4'>
                                Project Status{' '}
                                <span>
                                  {order5 === 'ASC' ? (
                                    <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                  ) : (
                                    <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                  )}
                                </span>
                              </th>

                              <th onClick={() => sorting9('bill_rate')} className='  py-4'>
                                Bill Rate{' '}
                                <span>
                                  {order9 === 'ASC' ? (
                                    <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                  ) : (
                                    <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                  )}
                                </span>
                              </th>
                              {MonthNumbers.map((test: any) => (
                                <>
                                  <th className='  py-4' colSpan={3}>
                                    {moment(test, 'M').format('MMMM')}{' '}
                                  </th>
                                </>
                              ))}
                            </tr>

                            <tr className='fw-semibold fs-7  text-gray-600 text-uppercase'>
                              <th className=' py-4'></th>
                              <th className=' py-4'></th>
                              <th className=' py-4'></th>
                              <th className=' py-4'></th>
                              <th className=' py-4'></th>
                              <th className=' py-4'></th>
                              <th className=' py-4'></th>
                              <th className=' py-4'></th>
                              <th className=' py-4'></th>
                              <th className=' py-4'></th>
                              <th className=' py-4'></th>
                              {MonthNumbers.map((test: any) => (
                                <>
                                  {/* <th className=' py-4'></th> */}
                                  <th
                                    onClick={() => sorting9('total_logged_hours')}
                                    className='  py-4'
                                  >
                                    Hours Logged{' '}
                                    <span>
                                      {order9 === 'ASC' ? (
                                        <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                      ) : (
                                        <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                      )}
                                    </span>
                                  </th>
                                  <th onClick={() => sorting9('total_revenue')} className='  py-4'>
                                    Revenue{' '}
                                    <span>
                                      {order9 === 'ASC' ? (
                                        <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                      ) : (
                                        <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                      )}
                                    </span>
                                    <Tooltip text={'(Logged Hours*Bill Rate)'}>
                                      <span>
                                        <i className='bi bi-info-lg  fs-4 bg-light-danger rounded-circle text-gray-700'></i>
                                      </span>
                                    </Tooltip>
                                  </th>
                                  <th></th>
                                </>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {ProjectReportData?.length > 0 ? (
                              <>
                                {ProjectReportData?.map((each: any, index: any) => (
                                  <tr className='namepage text-dark fw-semibold  fs-6 ' key={index}>
                                    <td className=''>
                                      {each.user_business_unit_name
                                        ? each.user_business_unit_name
                                        : '-'}
                                    </td>

                                    <td className=''>
                                      {each.employee_id ? each.employee_id : '-'}
                                    </td>
                                    <td className=''>
                                      {each.employee_type ? each.employee_type : '-'}
                                    </td>
                                    <td className='text-capitalize'>
                                      {each.display_name
                                        ? each.display_name
                                        : each.full_name
                                        ? each.full_name
                                        : '-'}
                                    </td>
                                    <td className=''>
                                      {each.placement_code ? each.placement_code : '-'}
                                    </td>
                                    <td className='text-capitalize'>
                                      {/* {each.is_placement_project == false ? 'Bench' : 'Placement'} */}
                                      {each.is_placement_project
                                        ? each.is_placement_project == 1
                                          ? 'Placement'
                                          : each.is_placement_project == 2
                                          ? 'Bench'
                                          : each.is_placement_project == 3
                                          ? 'Internal'
                                          : '-'
                                        : '-'}
                                    </td>
                                    <td className='text-capitalize'>
                                      {each.project_name ? each.project_name : '-'}
                                    </td>
                                    <td className=''>
                                      {each.direct_customer_engagement == true
                                        ? each.end_customer
                                          ? each.end_customer
                                          : '-'
                                        : each.client_name
                                        ? each.client_name
                                        : '-'}
                                    </td>
                                    <td className=''>
                                      {each.start_date
                                        ? moment(each.start_date).format('DD-MMM-YYYY')
                                        : '-'}
                                    </td>
                                    <td className=''>
                                      {each.project_status
                                        ? each.project_status == 'IN_PROGRESS'
                                          ? 'In Progress'
                                          : each.project_status == 'INITIATED'
                                          ? 'Initiated'
                                          : each.project_status == 'CANCELLED'
                                          ? 'Cancelled'
                                          : each.project_status == 'COMPLETED'
                                          ? 'Completed'
                                          : each.project_status == 'REJECTED'
                                          ? 'Rejected'
                                          : '-'
                                        : '-'}
                                    </td>

                                    <td className=''>
                                      {each.bill_rate}
                                      {each.bill_rate != 0 && each.is_bill_rate_same == false ? (
                                        <Tooltip text={'Please check bill rate details'}>
                                          <span>
                                            <i className='bi bi-info-lg  fs-4 bg-light-danger rounded-circle text-gray-700'></i>
                                          </span>
                                        </Tooltip>
                                      ) : (
                                        ''
                                      )}
                                    </td>
                                    {each &&
                                      each.data?.map((temp: any) => (
                                        <>
                                          <td className=''>
                                            {temp.total_logged_hours
                                              ? temp.total_logged_hours
                                              : '-'}
                                          </td>

                                          <td className=''>
                                            {temp.total_revenue
                                              ? '$' +
                                                Number(temp.total_revenue).toLocaleString(
                                                  undefined,
                                                  {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                  }
                                                )
                                              : '-'}
                                          </td>
                                          <td></td>
                                        </>
                                      ))}
                                  </tr>
                                ))}
                              </>
                            ) : (
                              ''
                            )}
                          </tbody>
                        </>
                      </table>
                      {(ProjectReportData?.length == null || ProjectReportData?.length == 0) && (
                        <div className='text-center  fw-bold text-gray-800'>No Records found </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className='d-flex flex-stack py-2'>
                {/* {ProjectReportData?.length > 0 ? ( */}
                <div>
                  <Container className='p-0'>
                    <Row className='align-items-center'>
                      <Col xs='auto' className='pe-0'>
                        {' '}
                        <label className='form-label fw-semibold'>No. of projects</label>
                      </Col>

                      <Col>
                        <select
                          name='perPage'
                          className='form-select'
                          onChange={onChange}
                          value={perPage}
                        >
                          {/* <option value='10'>10</option> */}
                          <option value='25'>25</option>
                          <option value='50'>50</option>
                          <option value='75'>75</option>
                          {/* <option value={ProjectReportCountData}>All</option> */}
                          <option value=''>All</option>
                        </select>
                      </Col>
                    </Row>
                  </Container>
                </div>
                {/* ) : null} */}

                <div className='pr-150'>
                  {/* {ProjectReportData?.length > 0 ? ( */}
                  <ReactPaginate
                    previousLabel={'<'}
                    nextLabel={'>'}
                    pageCount={pages}
                    onPageChange={handlePageClick}
                    containerClassName={'pagination justify-content-right'}
                    activeClassName={'active'}
                    breakLabel={'...'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    breakClassName={'page-item'}
                    breakLinkClassName={'page-link'}
                  />
                  {/* ) : null} */}
                </div>
              </div>
            </>
          ) : (
            ''
          )}
          {/* )} */}
          {/* {flag == false && (
            <div className='fs-6 text-gray-500 text-transform-unset'>
              Note: Please select any one and click run to view the result
            </div>
          )} */}
        </Modal.Body>
      </Modal>
    </>
  )
}

export default RevenueMonthlyComplianceReports
