import {useEffect, useState, useRef, createContext} from 'react'
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import {useIntl} from 'react-intl'
import {useNavigate} from 'react-router-dom'
import {useLocation} from 'react-router-dom'
import {default as Apiservices} from '../../../common/index'
import {Link} from 'react-router-dom'
import ModalPop from '../../modules/apps/masters/modal/ModalPop'
import BackDrop from '../../modules/apps/timesheetentry/ComponentBackdrop'
import AnnouncementPop from '../../modules/apps/masters/modal/AnnouncementPop'
import {Tooltip} from '../../modules/apps/timesheetentry/ComponentTooltip'
import Cookies from 'universal-cookie'
import TimesheetImg from '../../../localMedia/img/timesheet.svg'
import MailIconImg from '../../../localMedia/img/mail-icon.svg'
import PasswordImg from '../../../localMedia/img/password-icon.svg'
import GooglePlayStoreImg from '../../../localMedia/img/google-play.svg'
import AppStoreImg from '../../../localMedia/img/app-store.svg'
import MSRcosmosGrouplogoImg from '../../../localMedia/img/MSRcosmosGrouplogo.svg'
import UnderMaintenance from './UnderMaintenance'

type UserSubmitForm = {
  email: string
  password: string
  token: any
}

function Timesheet() {
  const methods = useForm()

  document.documentElement.setAttribute('data-theme', 'light')
  useEffect(() => {
    document.body.classList.add('bg-body')
    return () => {
      document.body.classList.remove('bg-body')
    }
  }, [])

  const intl = useIntl()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState<any | []>(false)
  const [loading, setLoading] = useState(false)
  const [list, setList] = useState(false)
  const [announcement_open_popup, setAnnouncementOpenPoup] = useState(false)
  const cookies = new Cookies()
  const [test, setTest] = useState(false)
  const [session, setSession] = useState(false)
  const [invalidMessage, setInvalidMessage] = useState('')
  const [showBackDrop, setShowBackDrop] = useState(false)
  const [emailForProp, setEmailForProp] = useState('')
  const [passwordForProp, setPasswordForProp] = useState('')
  const [ConfigValue, setConfigValue] = useState<any>('')
  const [Maintenance, setMaintenance] = useState(false)
  const validationSchema = Yup.object().shape({
    email: Yup.string().required('Email is required').email(' Email is invalid'),
    password: Yup.string().required('Password is required'),
  })

  const {
    register,
    handleSubmit,
    resetField,
    reset,
    formState: {errors},
  } = useForm<UserSubmitForm>({
    resolver: yupResolver(validationSchema),
  })

  const modalPop = () => {
    setList(false)
  }
  const AnnouncePop = () => {
    setAnnouncementOpenPoup(false)
  }
  const getCookie = async () => {
    if ((cookies.get('JWT-TOkEN') || cookies.get('JWT_TOKEN')) && !cookies.get('org_id')) {
      setShowBackDrop(true)

      const data1: any = await Apiservices.getSSO()
      if (data1 && data1.data && data1.data.statusCode == 200) {
        // cookies.set('JWT-TOkEN', data1.data.accessToken)
        // cookies.set('JWT_TOKEN', data1.data.accessToken)

        cookies.set('JWT-TOkEN', data1.data.accessToken, {path: '/', domain: '.msrcosmos.com'})
        cookies.set('JWT_TOKEN', data1.data.accessToken, {path: '/', domain: '.msrcosmos.com'})
        localStorage.setItem('user_email', data1.data.email.toLowerCase())
        // localStorage.setItem('token', data1.data.accessToken)
        localStorage.setItem('token', 'Bearer' + ' ' + data1.data.accessToken)
        let organizationName1 = data1.data.organizations
        organizedTools(organizationName1)
      }
      // setShowBackDrop(false)
      setTimeout(function () {
        setShowBackDrop(false)
      }, 2000)
    } else if ((cookies.get('JWT-TOkEN') || cookies.get('JWT_TOKEN')) && cookies.get('org_id')) {
      let orgid = cookies.get('org_id')
      setShowBackDrop(true)

      const data1: any = await Apiservices.getSSO()
      if (data1 && data1.data && data1.data.statusCode == 200) {
        // cookies.set('JWT-TOkEN', data1.data.accessToken)
        // cookies.set('JWT_TOKEN', data1.data.accessToken)

        cookies.set('JWT-TOkEN', data1.data.accessToken, {path: '/', domain: '.msrcosmos.com'})
        cookies.set('JWT_TOKEN', data1.data.accessToken, {path: '/', domain: '.msrcosmos.com'})
        localStorage.setItem('user_email', data1.data.email.toLowerCase())
        // localStorage.setItem('token', data1.data.accessToken)
        localStorage.setItem('token', 'Bearer' + ' ' + data1.data.accessToken)
        let organizationName1 = data1.data.organizations
        let orglist: any = []

        let toolNameMoreThanOne = organizationName1.filter((each: any) => each.toolRoles.length > 0)
        let timeSheetTrackerCount = 0
        let tosterCount = 0

        for (let each of organizationName1) {
          if (each.toolRoles && each.toolRoles.length > 0) {
            for (let tools of each.toolRoles) {
              if (tools.toolName == 'Timesheet Tracker') {
                timeSheetTrackerCount += 1
                break
              }
            }
          }
        }
        if (timeSheetTrackerCount >= 1 && toolNameMoreThanOne.length > 0) {
          for (let each of organizationName1) {
            for (let tools of each.toolRoles) {
              if (tools.toolName == 'Timesheet Tracker') {
                let OrgListArray: any = each
                orglist.push(OrgListArray)
                localStorage.setItem('org_list', JSON.stringify(orglist))

                if (organizationName1?.length > 1 && timeSheetTrackerCount > 1) {
                  localStorage.setItem('org_icon', 'true')
                  localStorage.setItem('org_id', orgid)
                  cookies.set('org_id', orgid, {path: '/', domain: '.msrcosmos.com'})
                  cookies.set('torg_id', orgid, {path: '/', domain: '.msrcosmos.com'})

                  tosterCount = tosterCount + 1
                  if (tosterCount == 1) {
                    getUserAuth(orgid)

                    // toast.success('Login Successfully')
                  }

                  // setTimeout(function () {
                  //   navigate('/OrganizationList')
                  // }, 1000)
                } else {
                  localStorage.setItem('role_Name', tools.roleName.toLowerCase())
                  localStorage.setItem('org_prefix', each.orgPrefix)
                  localStorage.setItem('org_id', each.organizationId)
                  cookies.set('org_id', each.organizationId, {path: '/', domain: '.msrcosmos.com'})
                  cookies.set('torg_id', each.organizationId, {path: '/', domain: '.msrcosmos.com'})
                  localStorage.setItem('org_name', each.organizationName)
                  localStorage.setItem('tool_name', tools.toolName)
                  getUserAuth(each.organizationId)
                  break
                }
              }
            }
          }
        } else {
          toast.warn("User doesn't have timesheet tracker. Please contact your admin")
          navigate('/login')
          setLoading(false)
        }
        // getUserAuth(orgid)
      }
      // setShowBackDrop(false)
      setTimeout(function () {
        setShowBackDrop(false)
      }, 2000)
    }
  }

  useEffect(() => {
    getCookie()
    getConfig()
    console.log(window.location.hostname, 'bdgdbdygdy')
  }, [])
  const getConfig = async () => {
    const config: any = await Apiservices.getConfig()
    if (config && config.data && config.data.isError == false) {
      // let AllConfig = config.data.data.filter((temp: any) => temp.accessKey == 'SITE_ACCESS')
      // var AllConfig1 = config.data.appConfig.filter(
      //   (temp: any) => temp.accessKey == 'NOTIFICATION_SITE_DOWN'
      // )
      if (config.data.data.site_access == true) {
        setConfigValue(config.data.data.site_access_msg)
        setMaintenance(true)
      }
    }
  }
  function removeAll() {
    let lRemove: any = [
      'token',
      'user_email',
      'org_list',
      'role_Name',
      'org_prefix',
      'org_id',
      'org_name',
      'tool_name',
      'ecdb_user_id',
      'userid',
      'Authorization',
      'NetSuiteToken',
      'enable_netsuite',
      'subsidary_name',
      'logged_in_id',
      'Announcement',
      'refresh_Token',
      'enable_quickbooks',
      'org_icon',
      'time_sheet_admin_screen_user_id',
      'MobileForceUpdateAccessbyEmail',
      'allowed_business_unit_id',
      'bu_list',
      'enable_unbilled',
      'invoice_access',
    ]
    for (let each of lRemove) {
      localStorage.removeItem(each)
    }
  }
  const getUserAuth = async (orgId: any) => {
    let orgEmail = localStorage.getItem('user_email')
    let authtoken: any = localStorage.getItem('token')?.split(' ')
    let data: any = {}
    let roles: any = []
    var buRoles
    data.email = orgEmail
    data.organizationId = orgId
    data.ecdbToken = authtoken[1]
    const response: any = await Apiservices.tokenData(data)
    if (response.isError == false) {
      if (response?.userBUresponse?.length > 0) {
        for (let each of response.userBUresponse) {
          roles.push(each.roleName?.toLowerCase())
        }
        roles.push(response.user_data.role_name?.toLowerCase())
        buRoles = roles
      } else {
        buRoles = response.user_data.role_name?.toLowerCase()
      }

      localStorage.setItem('ecdb_user_id', response.user_data.ecdb_user_id)
      localStorage.setItem('enable_unbilled', response?.user_data?.enable_unbilled)
      localStorage.setItem('invoice_access', response?.user_data?.invoice_access)
      localStorage.setItem('userid', response.user_data.id)
      localStorage.setItem('Authorization', response.data)
      localStorage.setItem('logged_in_id', orgId)
      // localStorage.setItem('role_Name', response.user_data.role_name.toLowerCase())
      localStorage.setItem('role_Name', buRoles)
      localStorage.setItem('bu_list', JSON.stringify(response.userBUresponse))
      localStorage.setItem('org_name', response.user_data.org_name)
      localStorage.setItem('allowed_business_unit_id', response.allowed_business_unit_id)
      let business: any = localStorage.getItem('role_Name')
      let numArray: any = business?.split(',')
      // getCookieAll()
      // if (response.isError == true) {
      //   localStorage.clear()
      //   navigate('/login')
      // }
      // if (localStorage.getItem('role_Name') === 'employee') {
      if (numArray?.includes('employee')) {
        localStorage.setItem('Announcement', 'false')

        // navigate('/timesheetlist')
        const token: any = await Apiservices.refreshToken()
        if (token && token.data && token.data.isError == false) {
          localStorage.setItem('refresh_Token', token.data.isError)
          // navigate('/timesheetlist')

          return (
            toast.success('Login Successful'),
            {
              position: toast.POSITION.BOTTOM_LEFT,
            },
            setTimeout(function () {
              setLoading(false)
              navigate('/timesheetlist')
            }, 500)
          )
        } else {
          localStorage.setItem('refresh_Token', 'true')
          // navigate('/timesheetlist')
          return (
            toast.success('Login Successful'),
            {
              position: toast.POSITION.BOTTOM_LEFT,
            },
            setTimeout(function () {
              setLoading(false)

              navigate('/timesheetlist')
            }, 500)
          )
          // setShow(true)
          // props.setList(true)
        }
      }
      //  else if (localStorage.getItem('role_Name') === 'timesheetadmin') {
      //   const token: any = await Apiservices.refreshToken()
      //   if (token && token.data && token.data.isError == false) {
      //     localStorage.setItem('refresh_Token', token.data.isError)

      //     return (
      //       toast.success('Login Successfully'),
      //       {
      //         position: toast.POSITION.BOTTOM_LEFT,
      //       },
      //       setTimeout(function () {
      //         setLoading(false)

      //         navigate('/masters/manageconsultants')
      //       }, 1000)
      //     )
      //   } else {
      //     localStorage.setItem('refresh_Token', 'true')
      //     return (
      //       toast.success('Login Successfully'),
      //       {
      //         position: toast.POSITION.BOTTOM_LEFT,
      //       },
      //       setTimeout(function () {
      //         setLoading(false)

      //         navigate('/masters/manageconsultants')
      //       }, 1000)
      //     )
      //     // setShow(false)
      //     // props.setList(false)
      //   }
      // }
      else if (
        // localStorage.getItem('role_Name') === 'superadmin' ||
        // localStorage.getItem('role_Name') === 'admin' ||
        // localStorage.getItem('role_Name') === 'timesheetadmin'
        numArray?.includes('superadmin') ||
        numArray?.includes('admin') ||
        numArray?.includes('timesheetadmin')
      ) {
        localStorage.setItem('Announcement', 'false')
        const token: any = await Apiservices.refreshToken()
        if (token && token.data && token.data.isError == false) {
          localStorage.setItem('refresh_Token', token.data.isError)

          return (
            toast.success('Login Successful'),
            {
              position: toast.POSITION.BOTTOM_LEFT,
            },
            setTimeout(function () {
              setLoading(false)

              navigate('/masters/manageconsultants')
            }, 1000)
          )
        } else {
          localStorage.setItem('refresh_Token', 'true')
          return (
            // toast.warn('QuickBooks not enabled. Please contact your admin'), //not required now
            toast.success('Login Successful'),
            {
              position: toast.POSITION.BOTTOM_LEFT,
            },
            setTimeout(function () {
              setLoading(false)

              navigate('/masters/manageconsultants')
            }, 1000)
          )
          // setShow(false)
          // props.setList(false)
        }
      }
      //  else if (localStorage.getItem('role_Name') === 'accounts') {
      //   const token: any = await Apiservices.refreshToken()
      //   if (token && token.data && token.data.isError == false) {
      //     localStorage.setItem('refresh_Token', token.data.isError)

      //     return (
      //       toast.success('Login Successfully'),
      //       {
      //         position: toast.POSITION.BOTTOM_LEFT,
      //       },
      //       setTimeout(function () {
      //         setLoading(false)

      //         navigate('/approver')
      //       }, 1000)
      //     )
      //   } else {
      //     localStorage.setItem('refresh_Token', 'true')
      //     return (
      //       toast.warn('QuickBooks not enabled. Please contact your admin'),
      //       toast.success('Login Successfully'),
      //       {
      //         position: toast.POSITION.BOTTOM_LEFT,
      //       },
      //       setTimeout(function () {
      //         setLoading(false)

      //         navigate('/approver')
      //       }, 1000)
      //     )
      //     // setShow(false)
      //     // props.setList(false)
      //   }
      // }
      else if (numArray?.includes('relationshipmanager')) {
        localStorage.setItem('Announcement', 'false')
        const token: any = await Apiservices.refreshToken()
        if (token && token.data && token.data.isError == false) {
          localStorage.setItem('refresh_Token', token.data.isError)
          return (
            toast.success('Login Successful'),
            {
              position: toast.POSITION.BOTTOM_LEFT,
            },
            setTimeout(function () {
              setLoading(false)
              navigate('/MyConsultant')
            }, 1000)
          )
        } else {
          localStorage.setItem('refresh_Token', 'true')
          return (
            // toast.warn('QuickBooks not enabled. Please contact your admin'), //not required now
            toast.success('Login Successful'),
            {
              position: toast.POSITION.BOTTOM_LEFT,
            },
            setTimeout(function () {
              setLoading(false)
              navigate('/MyConsultant')
            }, 1000)
          )
          // setShow(false)
          // props.setList(false)
        }
      } else {
        localStorage.setItem('Announcement', 'false')
        const token: any = await Apiservices.refreshToken()
        if (token && token.data && token.data.isError == false) {
          localStorage.setItem('refresh_Token', token.data.isError)

          return (
            toast.success('Login Successful'),
            {
              position: toast.POSITION.BOTTOM_LEFT,
            },
            setTimeout(function () {
              setLoading(false)

              navigate('/approver')
            }, 1000)
          )
        } else {
          localStorage.setItem('refresh_Token', 'true')
          return (
            toast.success('Login Successful'),
            {
              position: toast.POSITION.BOTTOM_LEFT,
            },
            setTimeout(function () {
              setLoading(false)

              navigate('/approver')
            }, 1000)
          )
          // setShow(false)
          // props.setList(false)
        }
      }

      // setIsLoading(false)
    } else {
      // localStorage.clear()
      removeAll()
      cookies.remove('JWT-TOkEN')
      cookies.remove('JWT_TOKEN')
      cookies.remove('org_id')
      cookies.remove('torg_id')
      cookies.remove('profileView')

      cookies.remove('JWT-TOkEN', {path: '/', domain: '.msrcosmos.com'})
      cookies.remove('JWT_TOKEN', {path: '/', domain: '.msrcosmos.com'})
      cookies.remove('org_id', {path: '/', domain: '.msrcosmos.com'})
      cookies.remove('torg_id', {path: '/', domain: '.msrcosmos.com'})
      cookies.remove('profileView', {path: '/', domain: '.msrcosmos.com'})

      navigate('/login')
      toast.error('Unauthorize access')
      setLoading(false)
    }
  }
  const onSubmit = async (values: any) => {
    setShowBackDrop(true)
    setLoading(true)
    const data: any = await Apiservices.getUserToken({
      email: values.email.toLowerCase(),
      password: values.password,
      // firstLogin:true,
      // toolName:"Timesheet Tracker"
    })
    setEmailForProp(values.email.toLowerCase())
    setPasswordForProp(values.password)
    if (data.statusCode == 200) {
      let organizationName = data.organizations
      let spaced = ''
      localStorage.setItem('token', 'Bearer' + ' ' + data.accessTokenResponse.accessToken)
      // cookies.set('JWT-TOkEN', data.accessTokenResponse.accessToken)
      // cookies.set('JWT_TOKEN', data.accessTokenResponse.accessToken)

      cookies.set('JWT-TOkEN', data.accessTokenResponse.accessToken, {
        path: '/',
        domain: '.msrcosmos.com',
      })
      cookies.set('JWT_TOKEN', data.accessTokenResponse.accessToken, {
        path: '/',
        domain: '.msrcosmos.com',
      })

      localStorage.setItem('user_email', data.email.toLowerCase())

      organizedTools(organizationName)
    } else if (data.statusCode == 400) {
      setShowBackDrop(true)

      setList(true)
      setIsLoading(false)
      setLoading(false)
      setSession(true)
      setTest(false)
      setShowBackDrop(false)
    } else if (data.statusCode == 500 && data.message == 'Invalid Credentials') {
      setIsLoading(false)
      setLoading(false)

      // setList(true)
      // setTest(true)
      // setSession(false)
      toast.warn('Invalid Credentials')

      // setInvalidMessage(data.message)
    } else if (data.statusCode == 500 && data.message != 'Invalid Credentials') {
      toast.warn('User does not exist')
      setIsLoading(false)
      setLoading(false)
    }
    // setShowBackDrop(false)
    setTimeout(function () {
      setShowBackDrop(false)
    }, 2000)

    // setLoading(false)
    // setIsLoading(false)
  }

  const organizedTools = (organizationName: any) => {
    let orglist: any = []

    let toolNameMoreThanOne = organizationName.filter((each: any) => each.toolRoles.length > 0)
    let timeSheetTrackerCount = 0
    let tosterCount = 0

    for (let each of organizationName) {
      if (each.toolRoles && each.toolRoles.length > 0) {
        for (let tools of each.toolRoles) {
          if (tools.toolName == 'Timesheet Tracker') {
            // toast.error('User does not exist')
            timeSheetTrackerCount += 1
            break
          }
        }
      }
    }

    if (timeSheetTrackerCount >= 1 && toolNameMoreThanOne.length > 0) {
      for (let each of organizationName) {
        for (let tools of each.toolRoles) {
          if (tools.toolName == 'Timesheet Tracker') {
            let OrgListArray: any = each
            orglist.push(OrgListArray)
            localStorage.setItem('org_list', JSON.stringify(orglist))

            if (organizationName?.length > 1 && timeSheetTrackerCount > 1) {
              localStorage.setItem('org_icon', 'true')

              tosterCount = tosterCount + 1
              if (tosterCount == 1) {
                toast.success('Login Successful')
              }

              setTimeout(function () {
                navigate('/OrganizationList')
              }, 1000)
            } else {
              localStorage.setItem('role_Name', tools.roleName.toLowerCase())
              localStorage.setItem('org_prefix', each.orgPrefix)
              localStorage.setItem('org_id', each.organizationId)
              cookies.set('org_id', each.organizationId, {path: '/', domain: '.msrcosmos.com'})
              cookies.set('torg_id', each.organizationId, {path: '/', domain: '.msrcosmos.com'})
              localStorage.setItem('org_name', each.organizationName)
              localStorage.setItem('tool_name', tools.toolName)
              getUserAuth(each.organizationId)
              break
            }
          }
        }
      }
    } else {
      toast.warn("User doesn't have timesheet tracker. Please contact your admin")
      navigate('/login')
      setLoading(false)
    }
  }
  const [showPassword, setShowPassword] = useState(false)
  const [imageLoading, setImageLoading] = useState(false)
  const showPasswordHandler = () => {
    setShowPassword(!showPassword)
  }

  // const getCookieAll = async () => {
  //   if (cookies.get('JWT-TOkEN') || cookies.get('JWT_TOKEN')) {
  //     const data1: any = await Apiservices.getSSO()
  //     if (data1 && data1.data && data1.data.statusCode == 200) {
  //       cookies.set('JWT-TOkEN', data1.data.accessToken)
  //       cookies.set('JWT_TOKEN', data1.data.accessToken)

  //       // cookies.set('JWT-TOkEN', data1.data.accessToken, {path: '/', domain: '.msrcosmos.com'})
  //       // cookies.set('JWT_TOKEN', data1.data.accessToken, {path: '/', domain: '.msrcosmos.com'})
  //     }
  //   }
  // }

  return (
    <>
      {isLoading ? (
        <BackDrop showBackDrop={true} />
      ) : (
        <>
          {list ? (
            <ModalPop
              emailForProp={emailForProp}
              passwordForProp={passwordForProp}
              close={modalPop}
              session={session}
              test={test}
              setTest={setTest}
              setSession={setSession}
              closeModal={list}
              setList={setList}
              invalidMessage={invalidMessage}
            />
          ) : (
            ''
          )}
          <BackDrop showBackDrop={showBackDrop} />

          {announcement_open_popup ? (
            <AnnouncementPop
              close={AnnouncePop}
              closeModal={announcement_open_popup}
              setAnnouncementOpenPoup={setAnnouncementOpenPoup}
            />
          ) : (
            ''
          )}
          {Maintenance ? (
            <UnderMaintenance config={ConfigValue} />
          ) : (
            <>
              <svg className='mask-paths'>
                <clipPath id='mask'>
                  <path
                    xmlns='http://www.w3.org/2000/svg'
                    d='M0,0H604C733,0,837.5,104.54,837.5,233.49h0C837.5,551.89,579.39,810,261,810H162A677.33,677.33,0,0,1,.12,790.48l-.11,0H0Z'
                    fill='#d9d9d9'
                  />
                </clipPath>
              </svg>
              <div className='d-flex flex-column flex-root login-content'>
                <div className='d-flex flex-column flex-lg-row flex-column-fluid fluid-area'>
                  <div className='lft-cover d-flex flex-column'>
                    <div className='image-shape'></div>
                  </div>
                  <div className='d-flex flex-column flex-lg-row-fluid form-section'>
                    <div className='d-flex flex-center flex-column flex-column-fluid'>
                      <div className='form'>
                        <div className='login'>
                          <div>
                            <div className='brand-logo '>
                              <img src={TimesheetImg} alt='' />
                            </div>
                            <h2 className='mb-2'>Welcome To Timesheet</h2>
                            <h5 className='fw-semibold text-gray-9'>Login to begin.</h5>
                            <p className='login-descrip'>
                              To keep connected with you please login with your personal information
                              by email & password
                            </p>
                            <form
                              onSubmit={handleSubmit(onSubmit)}
                              noValidate
                              // id='kt_login_signin_form'
                            >
                              <div className='login-form'>
                                <div className='form-floating '>
                                  <img src={MailIconImg} alt='' />
                                  <input
                                    type='email'
                                    id='floatingInput'
                                    placeholder='name@example.com'
                                    {...register('email')}
                                    onKeyPress={(e) => {
                                      if (new RegExp(/[0-9a-zA-Z. -@]/i).test(e.key)) {
                                      } else e.preventDefault()
                                    }}
                                    className={`form-control ps-12 ${
                                      errors.email ? 'is-invalid' : ''
                                    }`}
                                  />
                                  <label htmlFor='floatingInput'>Email address *</label>
                                </div>
                                <div className='invalid-feedback'>{errors.email?.message}</div>
                                <div className='form-floating'>
                                  <img src={PasswordImg} alt='' />
                                  <input
                                    id='floatingPassword'
                                    placeholder='Password'
                                    type={showPassword ? 'text' : 'password'}
                                    {...register('password')}
                                    className={`form-control ps-12 ${
                                      errors.password ? 'is-invalid' : ''
                                    }`}
                                  />
                                  {showPassword ? (
                                    <Tooltip text='Hide Password'>
                                      <i
                                        className={`bi fs-3 bi-eye viewPassword ${
                                          errors.password ? 'is-invalid' : ''
                                        }`}
                                        onClick={showPasswordHandler}
                                      ></i>
                                    </Tooltip>
                                  ) : (
                                    <Tooltip text='View Password'>
                                      <i
                                        className={`bi fs-3 bi-eye-slash viewPassword ${
                                          errors.password ? 'is-invalid' : ''
                                        }`}
                                        onClick={showPasswordHandler}
                                      ></i>
                                    </Tooltip>
                                  )}
                                  <label htmlFor='floatingPassword'>Password *</label>
                                </div>
                              </div>
                              <div className='invalid-feedback d-flex fs-6'>
                                {errors.email?.message ? errors.email?.message + ',' : ''}{' '}
                                {errors.password?.message}
                              </div>
                              {/* <div className='invalid-feedback d-flex fs-6'>{errors.password?.message}</div> */}
                              {/* <div className='invalid-feedback d-flex fs-6'>
                          Please provide a valid password.
                        </div> */}

                              <div className='row justify-content-between mt-5 mb-4'>
                                <div className='col-auto d-none'>
                                  <div className='form-check'>
                                    <input
                                      className='form-check-input'
                                      type='checkbox'
                                      value=''
                                      id='Remember-Me'
                                    />
                                    <label className='form-check-label' htmlFor='Remember-Me'>
                                      Remember Me
                                    </label>
                                  </div>
                                </div>
                                <div className='col '>
                                  <Link
                                    to='/forgotPassword'
                                    className='forgot-pwd'
                                    style={{marginLeft: '5px'}}
                                  >
                                    Forgot Password ?
                                  </Link>
                                </div>
                              </div>
                              <div className='row'>
                                <div className='col'>
                                  <button
                                    type='submit'
                                    className='btn btn-primary custom-btn'
                                    disabled={loading ? true : false}
                                  >
                                    {!loading && <span className='indicator-label'>Login</span>}
                                    {loading && (
                                      <span
                                        className='indicator-progress'
                                        style={{display: 'block'}}
                                      >
                                        Logging in
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                      </span>
                                    )}
                                  </button>
                                </div>
                              </div>
                            </form>

                            <div className='row app-download'>
                              <div className='col'>
                                <p>Or you can use on</p>
                                <ul>
                                  <li>
                                    <a
                                      href='https://play.google.com/store/apps/details?id=com.msrcosmos.timesheets&pli=1'
                                      target='_blank'
                                    >
                                      <img src={GooglePlayStoreImg} alt='' />
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href='https://apps.apple.com/us/app/msr-timesheets/id6451482238'
                                      target='_blank'
                                    >
                                      <img src={AppStoreImg} alt='' />
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='d-flex flex-center flex-wrap poweredby '>
                      <p>Powered by</p>
                      <img src={MSRcosmosGrouplogoImg} alt='MSRcosmosGroup' />
                    </div>
                  </div>
                </div>
                <div className='animate'>
                  <div className='animate_circles'>
                    <span className='circle1'></span>
                    <span className='circle2'></span>
                    <span className='circle3'></span>
                    <span className='circle4'></span>
                    <span className='circle5'></span>
                    <span className='circle6'></span>
                    <span className='circle7'></span>
                    <span className='circle8'></span>
                    <span className='circle9'></span>
                    <span className='circle10'></span>
                    <span className='circle11'></span>
                    <span className='circle12'></span>
                    <span className='circle13'></span>
                    <span className='circle14'></span>
                    <span className='circle15'></span>
                    <span className='circle16'></span>
                    <span className='circle17'></span>
                    <span className='circle18'></span>
                    <span className='circle19'></span>
                    <span className='circle20'></span>
                  </div>
                </div>
                <div className='clock'>
                  <div className='top'></div>
                  <div className='right'></div>
                  <div className='bottom'></div>
                  <div className='left'></div>
                  <div className='center'></div>
                  <div className='shadow'></div>
                  <div className='hour'></div>
                  <div className='minute'></div>
                  <div className='second'></div>
                </div>
                <div className='calendar'>
                  {/* <div className="header"></div> */}
                  <div className='body'>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
                <div className='bg-items'>
                  <span className='circle circle1'> </span>
                  <span className='circle circle2'> </span>
                  <span className='circle circle3'> </span>
                </div>
              </div>
            </>
          )}
          <ToastContainer />
        </>
      )}
    </>
  )
}

export default Timesheet
