import React, {useState, useEffect, useContext} from 'react'
import {useNavigate} from 'react-router-dom'
import {useIntl} from 'react-intl'
import {useLocation} from 'react-router-dom'
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import ThingsContext from '../../modules/apps/masters/commonicons/GlobalContext'
import ValidContext from '../../modules/apps/masters/commonicons/ValidateContext'
import {default as Apiservices} from '../../../common/index'
import {useForm} from 'react-hook-form'
import 'reactjs-popup/dist/index.css'
import {PageTitle} from '../../../_metronic/layout/core'
import clsx from 'clsx'

type FormData = {
  os_type: string
  app_version: string
  force_update: boolean
  description: string
}

function AddMobileSetting(props: any) {
  const location = useLocation()
  const {
    register,
    setValue,
    reset,
    getValues,
    handleSubmit,
    formState: {errors},
  } = useForm<FormData>()

  const [mobileuserid, setMobileUserId] = useState<any | []>([])
  const [forceUpdate, setForceUpdate] = useState(true)
  const navigate = useNavigate()
  const intl = useIntl()

  const handleOS = (os_type: any) => {
    setValue('os_type', os_type)
  }
  const handleDescription = (desc: any) => {
    let description1 = desc.trim()
    setValue('description', description1)
  }

  const handleForceUpdate = () => {
    setForceUpdate(!forceUpdate)
  }
  const onSubmit = async (data: FormData) => {
    const article = {
      os: data.os_type,
      app_version: data.app_version,
      force_update: forceUpdate,
      description: data.description,
    }
    const article1 = {
      id: mobileuserid?.id ? mobileuserid?.id : null,
      os: data.os_type,
      app_version: data.app_version,
      force_update: forceUpdate,
      description: data.description,
    }

    if (!mobileuserid?.id) {
      const response: any = await Apiservices.insertMobileForceUpdate(article)
      if (response.isError == false) {
        toast.success('Inserted Successfully')
        navigate('/mobilesettings')
      }
    }

    if (mobileuserid?.id) {
      const response1: any = await Apiservices.editmobileforceupdate(article1)
      if (response1.isError == false) {
        toast.success('Updated Successfully')
        navigate('/mobilesettings')
      }
    }
  }

  const tokenHeader: any = useContext(ThingsContext)
  const renderThings = async () => {
    tokenHeader.getCookie()
  }

  useEffect(() => {
    renderThings()
  }, [])

  useEffect(() => {
    let state: any = location.state
    const viewEmployee = async (id: any) => {
      if (location.state) {
        const response: any = await Apiservices.getmobileforceupdatebyid({id: location.state})
        let dataSet = {
          ...response?.data?.data,
          force: response.data.data.force_update,
          os: response.data.data.os,
        }
        setForceUpdate(dataSet.force)
        setValue('os_type', dataSet.os)
        reset(dataSet)
        setMobileUserId(response.data.data)
      }
    }
    if (location.state) {
      viewEmployee(state.id)
    }
  }, [])

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='card card-custom card-flush'>
          {location.state ? (
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.UPDATEMOBILE'})}</PageTitle>
          ) : (
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.ADDMOBILE'})}</PageTitle>
          )}
          <div className='card-body py-5'>
            <div className='row gy-4 align-items-end'>
              <div className='col-xs-12 col-md-3'>
                <label className='form-label fs-8 fw-semibold'>OS *</label>
                <select
                  className={clsx('form-select', errors.os_type ? 'is-invalid' : '')}
                  {...register('os_type', {
                    required: true,
                    onChange: (e) => {
                      handleOS(e.target.value)
                      setValue('os_type', e.target.value)
                    },
                  })}
                  aria-label='Default select example'
                >
                  <option value=''>Select OS Type</option>
                  <option value='IOS'>IOS</option>
                  <option value='ANDROID'>Android</option>
                </select>
                {errors.os_type && errors.os_type.type === 'required' && !getValues('os_type') && (
                  <p className='error text-danger mb-0'>OS Type is required.</p>
                )}
              </div>
              <div className='col-xs-12 col-md-3'>
                <label className='form-label fs-8 fw-semibold'>App Version *</label>
                <input
                  type='text'
                  className={clsx('form-select', errors.app_version ? 'is-invalid' : '')}
                  placeholder='Enter App Version'
                  {...register('app_version', {
                    required: true,
                  })}
                />
                {errors.app_version && errors.app_version.type === 'required' && (
                  <p className='error text-danger mb-0'>App Version is required.</p>
                )}
              </div>
              <div className='col-xs-12 col-md-3'>
                <label className='form-label fs-8 fw-semibold'>Force Update *</label>
                <div
                  onChange={handleForceUpdate}
                  className='form-check form-switch form-switch-sm form-check-custom form-check-solid'
                >
                  <input
                    className='form-check-input form-control fs-6 my-2'
                    {...register('force_update')}
                    type='checkbox'
                    role='switch'
                    id='flexSwitchCheckDefault'
                    checked={forceUpdate}
                  />
                  <span className='ps-2'>{forceUpdate ? 'yes ' : 'No'}</span>
                </div>
              </div>

              <div className='col-xs-12 col-md-3'>
                <label className='form-label '>Description *</label>
                <textarea
                  rows={2}
                  placeholder='Enter Description'
                  {...register('description', {
                    required: true,
                    onChange: (e) => {
                      handleDescription(e.target.value)
                      // setValue('description', e.target.value)
                    },
                  })}
                  className={`form-control ${errors.description ? 'is-invalid' : ''}`}
                />
                {errors.description && errors.description.type === 'required' && (
                  <p className='error text-danger mb-0'>Description is required.</p>
                )}
              </div>
            </div>
            <div className='d-flex justify-content-end  py-1 '>
              <div>
                <div className='d-flex gap-3 '>
                  {!mobileuserid?.id && (
                    <div>
                      <button type='submit' className='btn btn-primary'>
                        Submit
                      </button>
                    </div>
                  )}
                  {mobileuserid?.id && (
                    <div>
                      <button
                        type='submit'
                        className='btn btn-primary'
                        // disabled={message == 'Records Found' ? true : false}
                      >
                        Update
                      </button>
                    </div>
                  )}
                  <div>
                    {' '}
                    <button type='button' onClick={() => navigate(-1)} className='btn btn-danger'>
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <ToastContainer />
          </div>
        </div>
      </form>
    </>
  )
}
export default AddMobileSetting
