import React, {useState} from 'react'
import {default as Apiservices} from '../../../../../common/index'
import {useNavigate} from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import BackDrop from '../../timesheetentry/ComponentBackdrop'
import AnnouncementPop from './AnnouncementPop'

export default function LeaveAndHolidayPopup(props: any) {
  const navigate = useNavigate()
  const [show, setShow] = useState(true)
  const handleClose = () => {
    props.setForLeaveAndHoldiayPopup(false)
  }
  const handelOk = ((message:any,data:any) => {
props.leaveHandler(message,data)
  })
  console.log(props.holidayData,'props')
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby='contained-modal-title-vcenter'
        centered
        size="sm"
      >
        {props.view_open && (
          <div className='col-auto'>
            <button
              type='button'
              className='btn-close'
              data-bs-dismiss='modal'
              aria-label='Close'
              onClick={props.cancel}
            ></button>
          </div>
        )}
        <Modal.Body>
            <>
              <div className='text-center text-gray-800 fs-5 fw-semibold text-transform-unset pb-4'>
                {props.text}
              </div>
             
            </>
          <div className='d-flex justify-content-center gap-3 py-2'>
              <>
               
                <div className=''>
                  <button className='btn btn-sm btn-light-danger' onClick={()=>handelOk('DontApplyLeave',props.holidayData)}>
                    Cancel
                  </button>
                </div>
                <div className=''>
                  <button
                    className='btn btn-sm  btn-primary'
                    onClick={() => handelOk("ApplyLeave",props.holidayData)}
                  >
                    Ok
                  </button>
                </div>
              </>
           
          </div>
        </Modal.Body>
      </Modal>
      {/* )} */}
      <ToastContainer />
    </>
  )
}
