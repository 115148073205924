import React, {useState} from 'react'
import {default as Apiservices} from '../../../../../../src/common/index'
import {useNavigate} from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import BackDrop from '../../timesheetentry/ComponentBackdrop'
import AnnouncementPop from '../modal/AnnouncementPop'

export default function SubmitButtonPop(props: any) {
  const navigate = useNavigate()
  const [show, setShow] = useState(true)
  const [message, setMessage] = useState(false)
  const [showBackDrop, setShowBackDrop] = useState(true)
  const [isLoading, setIsLoading] = useState<any | []>(false)
  const [announcement_open_popup, setAnnouncementOpenPoup] = useState(false)

  const handleClose = () => {
    setShow(false)
    props.close(false)
    props.setShow(false)
    props.setSubmitOpen(false)
    props.setSubmitShow(false)
    props.setSubmitAllOpen(false)
    props.setSubmitAllShow(false)
  }
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby='contained-modal-title-vcenter'
        centered
        backdrop='static'
      >
        {props.view_open && (
          <div className='col-auto'>
            <button
              type='button'
              className='btn-close'
              data-bs-dismiss='modal'
              aria-label='Close'
              onClick={props.cancel}
            ></button>
          </div>
        )}
        <Modal.Body>
          {props.submit_open && (
            <>
              <p className='text-center text-gray-800 fs-5 fw-semibold text-transform-unset'>
                Are you sure you want to submit the timesheet ?
              </p>
              <div className='d-flex justify-content-center align-items-center py-3'>
                <div className='fs-6 fw-semibold text-gray-600 pe-3'>
                  Regular Hours:{' '}
                  <span className='text-gray-800'>{props.sumofRegularHoursProject}</span>
                </div>
                <div className='fs-6 fw-semibold text-gray-600 pe-3'>
                  OT Hours: <span className='text-gray-800'>{props.sumofOtHoursProject}</span>
                </div>{' '}
                <div className='fs-6 fw-semibold text-gray-600 pe-3'>
                  Leave Hours: <span className='text-gray-800'>{props.sumofLeaveHoursProject}</span>
                </div>
              </div>
              <p className='text-center text-gray-700  fs-7 text-transform-unset'>
                Note:{' '}
                <span className='fst-italic'>
                  Save all other entered project hours before Submitting project wise timesheet
                </span>
              </p>
            </>
          )}
          {props.submitall_open && (
            <>
              <p className='text-center text-gray-800 fs-5 fw-semibold text-transform-unset'>
                Are you sure you want to submit all the timesheets ?
              </p>
              <div className='d-flex justify-content-center align-items-center '>
                <div className='fs-6 fw-semibold text-gray-600 pe-3'>
                  Regular Hours:{' '}
                  <span className='text-gray-800'>{props.sumofRegularHoursProject}</span>
                </div>
                <div className='tfs-6 fw-semibold text-gray-600 pe-3'>
                  OT Hours: <span className='text-gray-800'>{props.sumofOtHoursProject}</span>
                </div>{' '}
                <div className='fs-6 fw-semibold text-gray-600 '>
                  Leave Hours: <span className='text-gray-800'>{props.sumofLeaveHoursProject}</span>
                </div>
              </div>
            </>
          )}
          {props.quickbook_open_pop && (
            <p className='text-center text-gray-700 fs-6 text-transform-unset'>
              Are you sure to submit ?
            </p>
          )}
          {props.test && <p>{props.invalidMessage}</p>}

          <div className='d-flex justify-content-center gap-3 py-2'>
            {props.test && (
              <div className=''>
                <button className='btn btn-sm  btn-primary' onClick={handleClose}>
                  Ok
                </button>
              </div>
            )}

            {props.submit_open && (
              <>
                <div className=''>
                  <button
                    className='btn btn-sm  btn-primary'
                    onClick={(e) => props.handleSubmitted(e, props.index)}
                  >
                    Ok
                  </button>
                </div>
                <div className=''>
                  <button className='btn btn-sm btn-danger' onClick={props.modalPop}>
                    Cancel
                  </button>
                </div>
              </>
            )}
            {props.submitall_open && (
              <>
                <div className=''>
                  <button
                    className='btn btn-sm btn-primary'
                    onClick={(e) => props.handleSubmittedAll(e, props.index)}
                  >
                    Ok
                  </button>
                </div>
                <div className=''>
                  <button className='btn btn-sm btn-danger' onClick={props.modalPopforSubmitAll}>
                    Cancel
                  </button>
                </div>
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
      {/* )} */}
      <ToastContainer />
    </>
  )
}
