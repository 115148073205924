import React, {useState} from 'react'
import {default as Apiservices} from '../../../../../../src/common/index'
import {useNavigate} from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import BackDrop from '../../timesheetentry/ComponentBackdrop'
import AnnouncementPop from '../modal/AnnouncementPop'

export default function WorkLocationSubmitAllPop(props: any) {
  const navigate = useNavigate()
  const [show, setShow] = useState(true)
  const [message, setMessage] = useState(false)
  const [showBackDrop, setShowBackDrop] = useState(true)
  const [isLoading, setIsLoading] = useState<any | []>(false)
  const [announcement_open_popup, setAnnouncementOpenPoup] = useState(false)

  const handleClose = () => {
    setShow(false)
    props.close(false)
    props.setShow(false)
    props.setWorkLocationShow(false)
  }
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby='contained-modal-title-vcenter'
        centered
        backdrop='static'
      >
        {props.view_open && (
          <div className='col-auto'>
            <button
              type='button'
              className='btn-close'
              data-bs-dismiss='modal'
              aria-label='Close'
              onClick={props.cancel}
            ></button>
          </div>
        )}
        <Modal.Body>
          <>
            <div className='text-center fw-bold fs-5 text-gray-800'>Update work location</div>
            <p className='text-center text-gray-600 fs-5 fw-semibold text-transform-unset'>
              It's suggested that you review your work location details since they haven't been
              updated in the last 90 days.
            </p>
          </>
          <div className='d-flex justify-content-center gap-3 py-2'>
            <>
              <div className=''>
                <button
                  className='btn btn-sm  btn-primary'
                  onClick={(e) => props.WorkLocationAllContinuePop(e, 'continue')}
                >
                  Continue Submission
                </button>
              </div>
              <div className=''>
                <button
                  className='btn btn-sm btn-danger'
                  onClick={(e) => props.WorkLocationAllOkPop(e, 'modify')}
                >
                  Modify
                </button>
              </div>
            </>
          </div>
        </Modal.Body>
      </Modal>
      {/* )} */}
      <ToastContainer />
    </>
  )
}
