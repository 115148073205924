import React, {useState, useEffect, useContext} from 'react'
import './style.css'
import connect from './connect-img.svg'
import parse from 'html-react-parser'
import curve from ' ./back-curve-shape.svg'
function UnderMaintenance(props: any) {
  const xmlString = props.config
  // const xmlDoc = new DOMParser().parseFromString(xmlString, 'text/xml')

  return (
    <>
      <>
        {/*[if lt IE 7]>      <html class="no-js lt-ie9 lt-ie8 lt-ie7"> <![endif]*/}
        {/*[if IE 7]>         <html class="no-js lt-ie9 lt-ie8"> <![endif]*/}
        {/*[if IE 8]>         <html class="no-js lt-ie9"> <![endif]*/}
        {/*[if gt IE 8]>      <html class="no-js"> <!--<![endif]*/}
        {/* <meta charSet='utf-8' /> */}
        {/* <meta httpEquiv='X-UA-Compatible' content='IE=edge' /> */}
        {/* <title /> */}
        {/* <meta name='description' content='' /> */}
        {/* <meta name='viewport' content='width=device-width, initial-scale=1' /> */}
        {/* <link
          href='https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,300;9..40,500;9..40,600&display=swap'
          rel='stylesheet'
        /> */}
        {/* <link rel='stylesheet' href='assets/style.css' /> */}
        {/*[if lt IE 7]>
<p class="browsehappy">
  You are using an <strong>outdated</strong> browser. Please
  <a href="#">upgrade your browser</a> to improve your experience.
</p>
    <![endif]*/}
        <div className='mainUM'>
          <div className='contentUM'>
            <img
              src='https://www.msrcosmosgroup.com/wp-content/uploads/2021/12/MSRcososGroup-logo.svg'
              alt='MSRcosmos Group'
              className='logoUM'
            />
            <h4>Under Maintenance</h4>
            {/* <p>
              Our Internal Tools are currently undergoing scheduled maintenance to implement a
              system upgrade from{' '}
              <span className='highlightDateTime'>
                1<sup>st</sup> Jan, 2024 11:30 p.m. EST
              </span>{' '}
              to
              <span className='highlightDateTime'>
                3<sup>rd</sup> Jan, 2024 10:30 a.m. EST
              </span>
            </p> */}

            {/* <p> {xmlDoc.documentElement.textContent}</p> */}
            <p> {parse(xmlString)}</p>
            <p>
              Thank you for your understanding and continued support. If you have any urgent queries
              or concerns, please feel free to contact our support team at{' '}
              <a href='mailto:tools.support@msrcosmos.com'>tools.support@msrcosmos.com</a> <br />
              OR
              <br />
              <span>India</span> - <a href='tel:+918037739677'>+91 803-773-9677</a>,<span>USA</span>{' '}
              - <a href='tel:+13478146627'>+1 347-814-6627</a>
            </p>
          </div>
          <div className='connect-img'>
            <img src={connect} alt='' />
          </div>
          <div className='bg'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width={827}
              height={923}
              viewBox='0 0 827 923'
              fill='none'
              className='Polygon-1'
            >
              <path
                opacity={1}
                d='M363.712 13.9112C394.652 -3.95205 432.772 -3.95206 463.712 13.9112L776.52 194.511C807.46 212.374 826.52 245.387 826.52 281.113L826.52 642.313C826.52 678.039 807.46 711.052 776.52 728.915L463.712 909.515C432.772 927.378 394.652 927.378 363.712 909.515L50.9046 728.915C19.9645 711.052 0.904541 678.039 0.904541 642.313L0.904541 281.113C0.904541 245.387 19.9644 212.374 50.9045 194.511L363.712 13.9112Z'
                fill='currentColor'
              />
            </svg>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width={839}
              height={923}
              viewBox='0 0 839 923'
              fill='none'
              className='Polygon-2'
            >
              <path
                opacity={1}
                d='M13.3036 267.142C14.5218 231.437 34.6963 199.093 66.2275 182.295L385.012 12.4667C416.543 -4.33115 454.64 -3.03133 484.953 15.8766L791.421 207.037C821.734 225.945 839.657 259.589 838.439 295.295L826.123 656.284C824.904 691.99 804.73 724.333 773.199 741.131L454.415 910.959C422.883 927.757 384.786 926.457 354.473 907.55L48.0051 716.389C17.6921 697.481 -0.231076 663.837 0.987151 628.131L13.3036 267.142Z'
                fill='url(#paint0_linear_946_55)'
              />
              <defs>
                <linearGradient
                  id='paint0_linear_946_55'
                  x1='15.2722'
                  y1='209.441'
                  x2='824.154'
                  y2='713.985'
                  gradientUnits='userSpaceOnUse'
                >
                  <stop stopColor='#617FF9' />
                  <stop offset={1} stopColor='#B75EEE' />
                </linearGradient>
              </defs>
            </svg>
          </div>
        </div>
      </>
    </>
  )
}
export default UnderMaintenance
