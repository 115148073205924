import {useEffect, useRef, useState} from 'react'
import {Outlet} from 'react-router-dom'
import {AsideDefault} from './components/aside/AsideDefault'
import {HeaderWrapper} from './components/header/HeaderWrapper'
import {Toolbar} from './components/toolbar/Toolbar'
import {RightToolbar} from '../partials/layout/RightToolbar'
import {ScrollTop} from './components/ScrollTop'
import {Content} from './components/Content'
import {PageDataProvider} from './core'
import {useLocation} from 'react-router-dom'
import {
  DrawerMessenger,
  ActivityDrawer,
  InviteUsers,
  UpgradePlan,
  ThemeModeProvider,
} from '../partials'
import {MenuComponent} from '../assets/ts/components'
import {Footer} from './components/Footer'
import Cookies from 'universal-cookie'
import {useNavigate} from 'react-router-dom'
import {default as Apiservices} from '../../../src/common/index'
import {ToastContainer, toast} from 'react-toastify'
import {ThingsProvider} from '../../app/modules/apps/masters/commonicons/GlobalContext'
import {ValidProvider} from '../../app/modules/apps/masters/commonicons/ValidateContext'
import serverCredintials from '../../common/serverCredintials'

// const MasterLayout = () => {
export function MasterLayout(props: any) {
  const location = useLocation()
  const cookies = new Cookies()
  const navigate = useNavigate()
  const iframeRef: any = useRef(null)
  const iframeSrc = serverCredintials.BuildURL.getTicketingWidgetURL()
  const [widgetToggle, setWidgetToggle] = useState(false)
  const [widgetShow, setWidgetShow] = useState(false)
  const [widgetRoute, setWidgetRoute] = useState('/')
  function removeAll() {
    let lRemove: any = [
      'token',
      'user_email',
      'org_list',
      'role_Name',
      'org_prefix',
      'org_id',
      'org_name',
      'tool_name',
      'ecdb_user_id',
      'userid',
      'Authorization',
      'NetSuiteToken',
      'enable_netsuite',
      'subsidary_name',
      'logged_in_id',
      'Announcement',
      'refresh_Token',
      'enable_quickbooks',
      'org_icon',
      'time_sheet_admin_screen_user_id',
      'MobileForceUpdateAccessbyEmail',
      'allowed_business_unit_id',
      'bu_list',
      'enable_unbilled',
      'invoice_access',
    ]
    for (let each of lRemove) {
      localStorage.removeItem(each)
    }
  }

  useEffect(() => {
    const handleRouteChange = (location: any) => {
      if (window.localStorage.getItem('Authorization')) {
        setWidgetShow(true)
      }
    }
    handleRouteChange(location)
  }, [location])

  useEffect(() => {
    window.addEventListener('message', getWidgetMsg)
    return () => {
      window.removeEventListener('message', getWidgetMsg)
    }
  }, [])

  const getWidgetMsg = (event: any) => {
    if (event.data && event.data.app === 'widget') {
      setWidgetToggle(event.data.widgetShow)
      setWidgetRoute(event.data.url)
    }
  }

  const widgetReload = () => {
    if (iframeRef.current) {
      iframeRef.current.contentWindow.postMessage('reload', '*')
    }
  }
  const widgetLogout = () => {
    if (iframeRef.current) {
      iframeRef.current.contentWindow.postMessage('logout', '*')
    }
  }
  const logout = async () => {
    console.log('hiiiiii')
    // if (issuesProjectLength > 0) {
    const data: any = await Apiservices.getUserLogout()
    removeAll()
    widgetLogout()
    cookies.remove('JWT-TOkEN')
    cookies.remove('JWT_TOKEN')
    cookies.remove('org_id')
    cookies.remove('torg_id')
    cookies.remove('profileView')

    cookies.remove('JWT-TOkEN', {path: '/', domain: '.msrcosmos.com'})
    cookies.remove('JWT_TOKEN', {path: '/', domain: '.msrcosmos.com'})
    cookies.remove('org_id', {path: '/', domain: '.msrcosmos.com'})
    cookies.remove('torg_id', {path: '/', domain: '.msrcosmos.com'})
    cookies.remove('profileView', {path: '/', domain: '.msrcosmos.com'})

    return (
      toast.error('Session expired'),
      {
        position: toast.POSITION.BOTTOM_LEFT,
      },
      setTimeout(function () {
        navigate('/login')
      }, 1000)
    )
    // }
  }

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [])
  // const getValidate = async () => {
  //   const res: any = await Apiservices.getValidate()
  //   if (!(res.data.statusCode == 200)) {
  //     issuesProjectLength += 1
  //     logout()
  //   }
  // }

  const getCookie = async () => {
    let issuesProjectLength = 0

    const res: any = await Apiservices.getValidate()
    if (!(res && res.data && res.data.statusCode == 200)) {
      if (issuesProjectLength == 0) {
        issuesProjectLength += 1
        logout()
      }
      // logout()
    }

    if (
      (cookies.get('JWT-TOkEN') || cookies.get('JWT_TOKEN')) &&
      cookies.get('org_id') &&
      cookies.get('torg_id') == cookies.get('org_id')
    ) {
      const data1: any = await Apiservices.getSSO()
      if (data1 && data1.data && data1.data.statusCode == 200) {
        // cookies.set('JWT-TOkEN', data1.data.accessToken)
        // cookies.set('JWT_TOKEN', data1.data.accessToken)
        cookies.set('JWT-TOkEN', data1.data.accessToken, {path: '/', domain: '.msrcosmos.com'})
        cookies.set('JWT_TOKEN', data1.data.accessToken, {path: '/', domain: '.msrcosmos.com'})
      }
    } else if (
      (cookies.get('JWT-TOkEN') || cookies.get('JWT_TOKEN')) &&
      cookies.get('org_id') &&
      cookies.get('torg_id') != cookies.get('org_id')
    ) {
      navigate('OrganizationList')
    } else {
      if (issuesProjectLength == 0) {
        issuesProjectLength += 1
        logout()
      }
      // logout()
    }
  }

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])

  return (
    // <ValidProvider value={{getValidate: getValidate}}>
    <ThingsProvider value={{getCookie: getCookie}}>
      <PageDataProvider>
        <ThemeModeProvider>
          <div className='page d-flex flex-row flex-column-fluid'>
            <AsideDefault />
            <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
              <HeaderWrapper widgetReload={widgetReload} widgetLogout={widgetLogout} />

              <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
                <Toolbar />
                <div className='post d-flex flex-column-fluid' id='kt_post'>
                  <Content>
                    <Outlet />
                  </Content>
                </div>
              </div>
              <Footer />
              {widgetShow && (
                <div
                  id='widget'
                  style={{
                    position: 'fixed',
                    right: 0,
                    bottom: 0,
                    zIndex: 9999,
                    width: widgetToggle ? '80vh' : '150px',
                    height: widgetToggle
                      ? ['/', '/intro'].includes(widgetRoute)
                        ? '580px'
                        : '750px'
                      : '65px',
                  }}
                >
                  <iframe
                    ref={iframeRef}
                    src={iframeSrc}
                    title='widget'
                    style={{width: '100%', height: '100%', border: 'none'}}
                    allow='display-capture'
                  ></iframe>
                </div>
              )}
            </div>
          </div>

          {/* begin:: Drawers */}
          <ActivityDrawer />
          <RightToolbar />
          <DrawerMessenger />
          {/* end:: Drawers */}

          {/* begin:: Modals */}
          <InviteUsers />
          <UpgradePlan />
          {/* end:: Modals */}
          <ScrollTop />
        </ThemeModeProvider>
      </PageDataProvider>
    </ThingsProvider>
    // </ValidProvider>
  )
}

// export {MasterLayout}
