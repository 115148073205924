import React, {useState, useEffect} from 'react'
import {default as Apiservices} from '../../../../../../src/common/index'
import {useNavigate} from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import BackDrop from '../../timesheetentry/ComponentBackdrop'
import AnnouncementPop from '../modal/AnnouncementPop'
import {useForm} from 'react-hook-form'
import clsx from 'clsx'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
type FormData = {
  vacation_type: any
}
export default function ConsultantActiveInactivePop(props: any) {
  const navigate = useNavigate()
  const [show, setShow] = useState(true)
  const [showBackDrop, setShowBackDrop] = useState(true)
  const [isLoading, setIsLoading] = useState<any | []>(false)
  const [customer_open_popup, setCustomerOpenPopup] = useState(false)
  const [select, setSelect] = useState<any>([])
  const [FinalProj, setFinalProj] = useState<any>([])
  const [dateRange, setDateRange] = useState<any | []>([null, null])
  const [startDate, endDate] = dateRange
  const [fullscreen, setFullscreen] = useState<any>(true)
  const [datevalidation, setDateValidation] = useState('')
  const {
    register: register,
    setValue: setValue,
    reset: reset,
    getValues: getValues,
    handleSubmit: handleSubmit,
    formState: {errors: errors},
  } = useForm<FormData>()

  const handleClose = () => {
    setShow(false)
    setFullscreen(false)
    props.close(false)
    props.setShow(false)
  }
  const options1: any = [
    {
      label: 'Select Type',
      value: '',
    },
    {
      label: 'Leave',
      value: 1,
    },
    {
      label: 'Holiday',
      value: 2,
    },
  ]
  useEffect(() => {}, [])
  const handleVacation = (vacation: any) => {
    setValue('vacation_type', vacation)
  }

  const onSubmit = () => {
    if (!startDate || !endDate) {
      setDateValidation('Date Range is required')
    } else {
      props.ApplyVacation(getValues('vacation_type'), startDate, endDate)
      setDateValidation('')
    }
  }

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        // size='lg'
        // fullscreen={fullscreen}
        aria-labelledby='contained-modal-title-vcenter'
        centered
        backdrop='static'
      >
        {props.view_open && (
          <div className='col-auto'>
            <button
              type='button'
              className='btn-close'
              data-bs-dismiss='modal'
              aria-label='Close'
              onClick={props.cancel}
            ></button>
          </div>
        )}
        <Modal.Body>
          <>
            <form onSubmit={handleSubmit(onSubmit)}>
              {/* <div className='card card-custom card-flush '>
                <div className='card-body p-2 py-3'> */}
              <p className='text-center text-gray-800 fs-5 fw-semibold text-transform-unset'>
                Active project alert!
              </p>
              <div className='d-flex justify-content-center align-items-center py-3'>
                <div className='row gy-3 fs-6 fw-semibold align-items-center text-gray-600 py-2'>
                  Consultant is having active projects, please mark them as Completed and then
                  inactivate the consultant.
                </div>
              </div>
              <div className='d-flex justify-content-center gap-3 py-2'>
                <>
                  <div className=''>
                    <button
                      className='btn btn-sm btn-danger'
                      onClick={props.modalActiveInactiveClosePop}
                    >
                      Ok
                    </button>
                  </div>
                </>
              </div>
            </form>
          </>
        </Modal.Body>
      </Modal>
      <ToastContainer />
    </>
  )
}
