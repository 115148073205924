import React, {useState, useEffect} from 'react'
import {default as Apiservices} from '../../../../../../src/common/index'
import {useNavigate} from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import BackDrop from '../../timesheetentry/ComponentBackdrop'
import AnnouncementPop from '../modal/AnnouncementPop'
import {useForm} from 'react-hook-form'
import clsx from 'clsx'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
type FormData = {
  vacation_type: any
}
export default function VacationPop(props: any) {
  const navigate = useNavigate()
  const [show, setShow] = useState(true)
  const [showBackDrop, setShowBackDrop] = useState(true)
  const [isLoading, setIsLoading] = useState<any | []>(false)
  const [customer_open_popup, setCustomerOpenPopup] = useState(false)
  const [select, setSelect] = useState<any>([])
  const [FinalProj, setFinalProj] = useState<any>([])
  const [dateRange, setDateRange] = useState<any | []>([null, null])
  const [startDate, endDate] = dateRange
  const [fullscreen, setFullscreen] = useState<any>(true)
  const [datevalidation, setDateValidation] = useState('')
  const {
    register: register,
    setValue: setValue,
    reset: reset,
    getValues: getValues,
    handleSubmit: handleSubmit,
    formState: {errors: errors},
  } = useForm<FormData>()

  const handleClose = () => {
    setShow(false)
    setFullscreen(false)
    props.close(false)
    props.setShow(false)
  }
  const options1: any = [
    {
      label: 'Select Type',
      value: '',
    },
    {
      label: 'Leave',
      value: 1,
    },
    {
      label: 'Holiday',
      value: 2,
    },
  ]
  useEffect(() => {}, [])
  const handleVacation = (vacation: any) => {
    setValue('vacation_type', vacation)
  }

  const onSubmit = () => {
    if (!startDate || !endDate) {
      setDateValidation('Date Range is required')
    } else {
      props.ApplyVacation(getValues('vacation_type'), startDate, endDate)
      setDateValidation('')
    }
  }

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        // size='lg'
        // fullscreen={fullscreen}
        aria-labelledby='contained-modal-title-vcenter'
        centered
        backdrop='static'
      >
        {props.view_open && (
          <div className='col-auto'>
            <button
              type='button'
              className='btn-close'
              data-bs-dismiss='modal'
              aria-label='Close'
              onClick={props.cancel}
            ></button>
          </div>
        )}
        <Modal.Body>
          <>
            <form onSubmit={handleSubmit(onSubmit)}>
              {/* <div className='card card-custom card-flush '>
                <div className='card-body p-2 py-3'> */}
              <p className='text-center text-gray-800 fs-5 fw-semibold text-transform-unset'>
                Leave/Vacation Request
              </p>
              <div className='d-flex justify-content-between align-items-center'>
                <div className='row gy-3 align-items-center py-2'>
                  <div className='col-xs-12 col-md-3 col-lg-5'>
                    <label className='form-label'>Vacation Type * </label>
                    <select
                      {...register('vacation_type', {
                        required: true,
                        onChange: (e) => {
                          handleVacation(e.target.value)
                        },
                      })}
                      className='form-select'
                      aria-label='Default select example'
                    >
                      {options1.map((option: any, index: any) => {
                        return (
                          <option value={option.value} key={index}>
                            {option.label}
                          </option>
                        )
                      })}
                    </select>
                    {errors.vacation_type &&
                      errors.vacation_type.type === 'required' &&
                      !getValues('vacation_type') && (
                        <p className='error text-danger mb-0'>Vacation Type is required.</p>
                      )}
                  </div>
                  <div className='col-xs-12 col-md-3 col-lg'>
                    <label className='form-label fw-semibold'>Date Range *</label>
                    <DatePicker
                      // showYearDropdown
                      placeholderText='Select Date Range'
                      selectsRange={true}
                      startDate={startDate}
                      endDate={endDate}
                      dateFormat='dd-MMM-yyyy'
                      onChange={(update: any) => {
                        setDateRange(update)
                      }}
                      isClearable={true}
                      monthsShown={2}
                      className={clsx('form-control')}
                      required
                    />
                    {datevalidation && (
                      <p className='error text-danger mb-0 mt-2'>{datevalidation}</p>
                    )}
                  </div>
                </div>
              </div>
              <div className='d-flex justify-content-center gap-3 py-2'>
                <>
                  <div className=''>
                    <button
                      className='btn btn-sm  btn-primary'
                      type='submit'
                      // onClick={() =>
                      //   props.ApplyVacation(getValues('vacation_type'), startDate, endDate)
                      // }
                    >
                      Apply Vacation
                    </button>
                  </div>
                  <div className=''>
                    <button className='btn btn-sm btn-danger' onClick={props.ApplyLeavePopUpClose}>
                      Cancel
                    </button>
                  </div>
                </>
              </div>
              {/* </div>
              </div> */}
            </form>
            {/* </div> */}
          </>
        </Modal.Body>
      </Modal>
      <ToastContainer />
    </>
  )
}
