import React, {useState, useEffect} from 'react'
import {default as Apiservices} from '../../../../../../src/common/index'
import {useNavigate} from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import BackDrop from '../../timesheetentry/ComponentBackdrop'
import AnnouncementPop from '../modal/AnnouncementPop'
import {useForm} from 'react-hook-form'

type FormData = {
  temp: any
}

export default function CopyButtonPop(props: any) {
  const navigate = useNavigate()
  const [show, setShow] = useState(true)
  const [showBackDrop, setShowBackDrop] = useState(true)
  const [isLoading, setIsLoading] = useState<any | []>(false)
  const [customer_open_popup, setCustomerOpenPopup] = useState(false)
  const [select, setSelect] = useState<any>([])
  const [FinalProj, setFinalProj] = useState<any>([])

  const {
    register: register,
    setValue: setValue,
    reset: reset,
    getValues: getValues,
    handleSubmit: handleSubmit,
    formState: {errors: errors},
  } = useForm<FormData>()

  const handleClose = () => {
    setShow(false)
    props.close(false)
    props.setShow(false)
    props.setSubmitOpen(false)
    props.setSubmitShow(false)
    props.setSubmitAllOpen(false)
    props.setSubmitAllShow(false)
  }

  const handleCustomer = (index: any, e: any) => {
    const selectedIndex = select.indexOf(index)
    let newSelected: any = []
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(select, index)
    } else if (selectedIndex == 0) {
      newSelected = newSelected.concat(select.slice(1))
    } else if (selectedIndex === select.length - 1) {
      newSelected = newSelected.concat(select.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        select.slice(0, selectedIndex),
        select.slice(selectedIndex + 1)
      )
    }
    setSelect(newSelected)
    // setCustomerOpenPopup(!customer_open_popup)
  }
  useEffect(() => {
    let arr = []

    for (let each of props.CopyProjectMatchingprevweek) {
      for (let item of props.employeesProjectList) {
        if (each.id == item.id) {
          arr.push(each)
        }
      }
    }
    let arr1 = arr.filter((test: any) => test.timesheet_data)

    // arr = props.CopyProjectMatchingprevweek.filter((id: any) =>
    //   props.employeesProjectList.some((x: any) => x.id == id.id)
    // )

    // setFinalProj(arr)
    setFinalProj(arr1)
  }, [])

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby='contained-modal-title-vcenter'
        centered
        backdrop='static'
      >
        {props.view_open && (
          <div className='col-auto'>
            <button
              type='button'
              className='btn-close'
              data-bs-dismiss='modal'
              aria-label='Close'
              onClick={props.cancel}
            ></button>
          </div>
        )}
        <Modal.Body>
          <>
            <p className='text-center text-gray-800 fs-5 fw-semibold text-transform-unset'>
              Are you sure you want to copy the timesheet ?
            </p>
            {FinalProj.map((temp: any, index: any) => {
              let proj = select.filter((test: any) => test == temp.id)
              return (
                <>
                  <div className='d-flex align-items-center'>
                    <p className='m-0 pr-2 text-gray-800 fs-7'>{temp.project_name}</p>
                    <div
                      onChange={(e) => handleCustomer(temp.id, e)}
                      className='form-check form-switch form-switch-sm form-check-custom form-check-solid'
                    >
                      <input
                        className='form-check-input form-control fs-6 my-2'
                        {...register('temp')}
                        type='checkbox'
                        role='switch'
                        id='flexSwitchCheckDefault'
                        checked={proj && proj[0]}
                      />
                      <span className='ps-2'>{proj && proj[0] ? 'yes ' : 'No'}</span>
                    </div>
                  </div>
                </>
              )
            })}

            <div className='d-flex justify-content-center align-items-center py-3'></div>
          </>

          <div className='d-flex justify-content-center gap-3 py-2'>
            <>
              <div className=''>
                <button
                  className='btn btn-sm  btn-primary'
                  onClick={(e) => props.getCopyProjectNotMatchingPopup(select)}
                >
                  Ok
                </button>
              </div>
              <div className=''>
                <button
                  className='btn btn-sm btn-danger'
                  onClick={props.getCopyProjectNotMatchingClosePopup}
                >
                  Cancel
                </button>
              </div>
            </>
          </div>
        </Modal.Body>
      </Modal>
      {/* )} */}
      <ToastContainer />
    </>
  )
}
