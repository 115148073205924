import React, {useState} from 'react'
import {default as Apiservices} from '../../../../../../src/common/index'
import {useNavigate} from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'
import parse from 'html-react-parser'

export default function RevertPopup(props: any) {
  console.log(props, 'dbgjkfdg')
  console.log(props.checkAdpData, 'dbgjkfdg')
  //   const { closeModal,emailForProp,passwordForProp} = props;
  const {closeModal, close, customermessage, cancel} = props
  const navigate = useNavigate()
  const [show, setShow] = useState(true)
  const [adpDisabledd, setAdpDisabledd] = useState(false)
  const [revertDataPopup, setRevertDataPopup] = useState('')
  const [okButtonTriggered, setOkButtonTriggered] = useState(false)

  console.log(revertDataPopup)
  const handleClose = () => {
    setShow(false)
  }

  const Announcement = async () => {
    const data: any = await Apiservices.markAsReadAnnouncement({
      id: props.announcement_list.id,
    })
    props.setAnnouncementOpenPoup(false)
  }

  // const greetUser = async () => {
  //     props.setConsultant(revertDataPopup)
  //     console.log(props.checkAdpData, "Hi there, user!");
  // }

  const greetUser = async () => {
    if (revertDataPopup !== '') {
      props.setConsultant(revertDataPopup)
    }
    setOkButtonTriggered(true)
  }

  function handleChange(event: any) {
    console.log(event.target.value)
    setRevertDataPopup(event.target.value)
    // props.setConsultant(props.checkAdpData)
  }

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        // size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        backdrop='static'
      >
        <Modal.Header className='py-4 '>
          <Modal.Title>
            <div className=' text-gray-800 fs-4 fw-semibold text-transform-unset'>
              <span></span> Do you want revert the day ?
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-3">
          <>
            <div className=' m-0'>
              <div className='col'>
                <textarea
                  placeholder='Please Enter Revert Comments *'
                  onChange={handleChange}
                  rows={3}
                  className={`form-control`}
                ></textarea>

                {(!revertDataPopup || revertDataPopup == '' || revertDataPopup == ' ') &&
                  okButtonTriggered && (
                    <>
                      <div style={{color: 'red'}}>Please Enter Revert Comments</div>
                    </>
                  )}
              </div>
            </div>
          </>
        </Modal.Body>
        <Modal.Footer className='p-2 '>
          <div className='row justify-content-end   '>
            <div className='col-auto'>
              <button className='btn btn-light-danger btn-sm cursor-pointer' onClick={props.onCancel}>
                Cancel
              </button>
            </div>
            <div className='col-auto'>
              <button className='btn btn-sm btn-primary' onClick={greetUser}>
                OK
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}
