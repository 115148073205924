import {FC} from 'react'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router'
import {checkIsActive, KTSVG, WithChildren} from '../../../helpers'
import {useLayout} from '../../core'

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
}

const AsideMenuItem: FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet = false,
}) => {
  const {pathname} = useLocation()
  const isActive = checkIsActive(pathname, to)
  const {config} = useLayout()
  const {aside} = config
  window.onclick = function () {
    const elements: HTMLCollectionOf<Element> = document.getElementsByClassName('menu-item dropend')
    const elementsTwo: HTMLCollectionOf<Element> = document.getElementsByClassName(
      'customSideBar dropdown-menu show'
    )
    if (elementsTwo.length > 0) {
      return false
    } else {
      const element: Element = elements[0]
      element.classList.remove('hover')
    }
  }
  const test = () => {
    const elements: HTMLCollectionOf<Element> = document.getElementsByClassName('menu-item dropend')
    const elementsTwo: HTMLCollectionOf<Element> = document.getElementsByClassName(
      'customSideBar dropdown-menu'
    )
    if (elements.length > 0) {
      const element: Element = elements[0]
      element.classList.remove('hover')
    }
    if (elementsTwo.length > 0) {
      const elementTwo: Element = elementsTwo[0]
      elementTwo.classList.remove('show')
    }
  }
  return (
    <div className='menu-item dropdown-item' onClick={test}>
      <Link className={clsx('menu-link without-sub ', {active: isActive})} to={to}>
        {/* {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )} */}
        {icon && aside.menuIcon === 'svg' && (
          <div className='menu-icon'>
            <KTSVG path={icon} className='svg-icon-2' />
          </div>
        )}
        {fontIcon && aside.menuIcon === 'font' && <i className={clsx('bi fs-3', fontIcon)}></i>}
        <span className='menu-title'>{title}</span>
      </Link>
      {children}
    </div>
  )
}

export {AsideMenuItem}
