// @ts-nocheck
import { Column } from 'react-table'
import { UserInfoCell } from './UserInfoCell'
import { UserLastLoginCell } from './UserLastLoginCell'
import { UserTwoStepsCell } from './UserTwoStepsCell'
import { UserActionsCell } from './UserActionsCell'
import { UserSelectionCell } from './UserSelectionCell'
import { UserCustomHeader } from './UserCustomHeader'
import { UserSelectionHeader } from './UserSelectionHeader'
import { User } from '../../core/_models'

const usersColumns: ReadonlyArray<Column<User>> = [
  {
    Header: (props) => <UserSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({ ...props }) => <UserSelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Employee Name' className='min-w-125px' />,
    id: 'name',
    Cell: ({ ...props }) => <UserInfoCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Employee Email' className='min-w-125px' />,
    accessor: 'role',
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Employee ID' className='min-w-125px' />
    ),
    id: 'last_login',
    Cell: ({ ...props }) => <UserLastLoginCell last_login={props.data[props.row.index].last_login} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Client Company' className='min-w-125px' />
    ),
    id: 'two_steps',
    Cell: ({ ...props }) => <UserTwoStepsCell two_steps={props.data[props.row.index].two_steps} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Status' className='min-w-125px' />
    ),
    accessor: 'joined_day',
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({ ...props }) => <UserActionsCell id={props.data[props.row.index].id} />,
  },
]




const usersListColumns: ReadonlyArray<Column<User>> = [
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Employee Name' className='min-w-125px' />,
    id: 'employee_name',
    Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Emplpoyee Email' className='min-w-125px' />,
    accessor: 'employee_email',
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Employee ID' className='min-w-125px' />
    ),
    id: 'employee_id',
    Cell: ({...props}) => <UserLastLoginCell last_login={props.data[props.row.index].last_login} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Department ' className='min-w-125px' />
    ),
    id: 'department',
    Cell: ({...props}) => <UserTwoStepsCell two_steps={props.data[props.row.index].two_steps} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Status' className='min-w-125px' />
    ),
    accessor: 'status',
  },
  // {
  //   Header: (props) => (
  //     <UserCustomHeader tableProps={props} title='Actions' className='min-w-125px' />
  //   ),
  //   id: 'actions',
  //   Cell: ({...props}) => <UserActionsCell id={props.data[props.row.index].id} />,
  // },
]

const ManageListProjects: ReadonlyArray<Column<User>> = [
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Project' className='min-w-125px' />,
    id: 'project',
    Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Client' className='min-w-125px' />,
    accessor: 'client',
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Customer' className='min-w-125px' />
    ),
    id: 'customer',
    Cell: ({...props}) => <UserLastLoginCell last_login={props.data[props.row.index].last_login} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Candidate ' className='min-w-125px' />
    ),
    id: 'candidate',
    Cell: ({...props}) => <UserTwoStepsCell two_steps={props.data[props.row.index].two_steps} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Bill Rate' className='min-w-125px' />
    ),
    accessor: 'bill_rate',
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Pay Rate' className='min-w-125px' />
    ),
    accessor: 'pay_rate',
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='start Date' className='min-w-125px' />
    ),
    accessor: 'start_date',
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Status ' className='min-w-125px' />
    ),
    accessor: 'status',
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Actions' className='min-w-125px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <UserActionsCell id={props.data[props.row.index].id} />,
  },
]

const ListProjects: ReadonlyArray<Column<User>> = [
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Placememt Code' className='min-w-125px' />,
    id: 'placement_code',
    Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Placement Type' className='min-w-125px' />,
    accessor: 'placement_type',
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Employee Name' className='min-w-125px' />
    ),
    id: 'employee_name',
    Cell: ({...props}) => <UserLastLoginCell last_login={props.data[props.row.index].last_login} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Project Name ' className='min-w-125px' />
    ),
    id: 'project_name',
    Cell: ({...props}) => <UserTwoStepsCell two_steps={props.data[props.row.index].two_steps} />,
  },
 
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='start Date' className='min-w-125px' />
    ),
    accessor: 'start_date',
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='QB Project Id' className='min-w-125px' />
    ),
    accessor: 'qb_project_id',
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='QB Product ID' className='min-w-125px' />
    ),
    accessor: 'qb_product_id',
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Status ' className='min-w-125px' />
    ),
    accessor: 'status',
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Actions' className='min-w-125px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <UserActionsCell id={props.data[props.row.index].id} />,
  },
]

const ManageListClients: ReadonlyArray<Column<User>> = [
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Client' className='min-w-125px' />,
    id: 'client',
    Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='QB Name' className='min-w-125px' />,
    accessor: 'qb_name',
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='QB ID' className='min-w-125px' />
    ),
    id: 'qb_id',
    Cell: ({...props}) => <UserLastLoginCell last_login={props.data[props.row.index].last_login} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Status ' className='min-w-125px' />
    ),
    id: 'status',
    Cell: ({...props}) => <UserTwoStepsCell two_steps={props.data[props.row.index].two_steps} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title=' Actions' className='min-w-125px' />
    ),
    accessor: 'actions',
  },
  
]

export {usersColumns,usersListColumns,ManageListProjects,ListProjects,ManageListClients}
