import React, {useState, useMemo, useEffect, useRef} from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas'
import moment from 'moment'
import momenttz from 'moment-timezone'
import {Tooltip} from './ComponentTooltip'
const Drawer = (props: any) => {
  const handleClose = () => {
    props.setShow(false)
    if (
      !(
        (props.approver_history_open_per_week &&
          props.approver_history_per_week &&
          props.approver_history_per_week.length > 0) ||
        (props.approver_history_open_per_day &&
          props.approver_history_per_day &&
          props.approver_history_per_day.length > 0)
      )
    ) {
      props.setHistoryOpenPerWeek(false)
      props.setHistoryOpenPerDay(false)
    }

    if (
      !(
        (props.history_open_per_day && props.history_per_day && props.history_per_day.length > 0) ||
        (props.history_open_per_week && props.history_per_week && props.history_per_week.length > 0)
      )
    ) {
      props.setApproverHistoryOpenPerWeek(false)
      props.setApproverHistoryOpenPerDay(false)
    }
  }

  const country = moment.tz.guess()
  // console.log(country)

  // var cutoffString = props.history_per_week[0]?.history_created // in utc
  // var utcCutoff = moment.utc(cutoffString, 'YYYYMMDD HH:mm:ss')
  // var displayCutoff = utcCutoff.clone().tz(country)

  // console.log('utcCutoff:', utcCutoff.format('YYYY-MM-DD hh:mm A'))
  // console.log('displayCutoff:', displayCutoff.format('YYYY-MM-DD hh:mm A'))

  // var originalDate = new Date(props.history_per_week[0]?.history_created)

  // console.log(props.history_per_week[0]?.history_created)
  // console.log(originalDate)

  // var convertedDate = new Date(originalDate.toLocaleString('en-US', {timeZone: country}))

  // console.log(convertedDate)

  return (
    <Offcanvas show={props.show} onHide={handleClose} placement='end'>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title className='fs-5  fw-semibold text-gray-700'>
          {props.history_open_per_day &&
          props.history_per_day &&
          props.history_per_day.length > 0 ? (
            // props.history_per_day[0].project_name
            <span className='text-capitalize'>{props.history_per_day[0].project_name}</span>
          ) : (
            ''
          )}
          {props.history_open_per_week &&
          props.history_per_week &&
          props.history_per_week.length > 0 ? (
            // props.history_per_week[0].project_name
            <span className='text-capitalize'>{props.history_per_week[0].project_name}</span>
          ) : (
            ''
          )}
          {props.approver_history_open_per_day &&
          props.approver_history_per_day &&
          props.approver_history_per_day.length > 0 ? (
            // props.approver_history_per_day[0].project_name
            <span className='text-capitalize'>
              {props.approver_history_per_day[0].project_name}
            </span>
          ) : (
            ''
          )}
          {props.approver_history_open_per_week &&
          props.approver_history_per_week &&
          props.approver_history_per_week.length > 0 ? (
            // props.approver_history_per_week[0].project_name
            <span className='text-capitalize'>
              {props.approver_history_per_week[0].project_name}
            </span>
          ) : (
            ''
          )}
        </Offcanvas.Title>
      </Offcanvas.Header>
      <div className='border-top '></div>
      <Offcanvas.Body>
        {props.approver_history_open_per_week &&
        props.approver_history_per_week &&
        props.approver_history_per_week.length > 0
          ? props.approver_history_per_week.map((text: any, index: number) => (
              <>
                <div className='row gap-2  py-3 border-bottom ' key={index}>
                  <div className='col-auto'>
                    <Tooltip text={text.updated_by_name}>
                      <span className=' approverBadge badge  bg-light text-dark' role='button'>
                        {text?.updated_by_user_id
                          ? text.updated_by_name?.slice(0, 1)
                          : text.created_by_name?.slice(0, 1)}
                      </span>
                    </Tooltip>
                  </div>
                  <div className='col ps-0'>
                    <div className=' text-gray-600 fs-7 fw-regular'>
                      Timesheet{' '}
                      <span
                        className={
                          text.status == 'SUBMITTED' || text.status == 'SAVED'
                            ? 'text-primary'
                            : text.status == 'APPROVED'
                            ? 'text-success'
                            : 'text-danger'
                        }
                      >
                        {/* {text.status === 'SUBMITTED' ? (
                        text.updated_by_name == text.full_name ? (
                          'SUBMITTED'
                        ) : (
                          <span className='text-danger'>REVERT</span>
                        )
                      ) : (
                        text.status
                      )} */}
                        {text?.is_reverted ? (
                          <span className='text-danger'>REVERTED</span>
                        ) : (
                          text.status
                        )}
                      </span>{' '}
                      on <span>{moment(text.history_created).format('DD/MM/YYYY hh:mm A')}</span>
                    </div>
                    <div className='pt-1'>
                      <table className='HistoryTable'>
                        {' '}
                        <tbody>
                          <tr>
                            <td>
                              <div className=' text-gray-500 fs-7 fw-regular border-left text-nowrap'>
                                Updated By:&nbsp;
                              </div>
                            </td>
                            <td>
                              <div className='text-gray-700 fs-7 fw-semibold text-break'>
                                {text.updated_by_name == null
                                  ? text.created_by_name
                                  : text.updated_by_name}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className=' text-gray-500 fs-7 fw-regular border-left  text-nowrap'>
                                Comments:&nbsp;
                              </div>
                            </td>
                            <td>
                              <div className='text-gray-700 fs-7 fw-regular text-break'>
                                {text?.updated_by_role_id == 7 || text?.updated_by_role_id == 8
                                  ? text?.user_notes
                                    ? text?.user_notes
                                    : '-'
                                  : text?.approver_notes
                                  ? text?.approver_notes
                                  : '-'}
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </>
            ))
          : props.approver_history_open_per_week && (
              <div>
                <h4>Hours History</h4>
                <p>No Data Found</p>{' '}
              </div>
            )}

        {props.approver_history_open_per_day &&
        props.approver_history_per_day &&
        props.approver_history_per_day.length > 0
          ? props.approver_history_per_day.map((text: any, index: any) => (
              <>
                <div className='row gap-1  py-3 border-bottom ' key={index}>
                  <div className='col-auto'>
                    <Tooltip text={text.updated_by_name}>
                      <span className=' approverBadge badge  bg-light text-dark' role='button'>
                        {text?.updated_by_user_id
                          ? text.updated_by_name?.slice(0, 1)
                          : text.created_by_name?.slice(0, 1)}
                      </span>
                    </Tooltip>
                  </div>
                  <div className='col px-0'>
                    <div className=' text-gray-600 fs-7 fw-regular'>
                      Timesheet{' '}
                      <span
                        className={
                          text.status == 'SUBMITTED' || text.status == 'SAVED'
                            ? 'text-primary'
                            : text.status == 'APPROVED'
                            ? 'text-success'
                            : 'text-danger'
                        }
                      >
                        {/* {text.status === 'SUBMITTED' ? (
                        text.updated_by_name == text.full_name ? (
                          'SUBMITTED'
                        ) : (
                          <span className='text-danger'>REVERT</span>
                        )
                      ) : (
                        text.status
                      )} */}
                        {text?.is_reverted ? (
                          <span className='text-danger'>REVERTED</span>
                        ) : (
                          text.status
                        )}
                      </span>{' '}
                      on <span>{moment(text.history_created).format('DD/MM/YYYY hh:mm A')}</span>
                    </div>

                    <div className='d-flex gap-2 flex-wrap'>
                      <div className=' text-gray-500 fs-8 fw-regular'>
                        Regular Hours : &nbsp;
                        <span className='text-gray-700 fw-semibold'>{`${text.regular_hours}:${
                          text.regular_minutes <= 9
                            ? '0' + text.regular_minutes
                            : text.regular_minutes
                        }`}</span>
                      </div>
                      <div className=' text-gray-500 fs-8 fw-regular'>
                        OT Hours : &nbsp;
                        <span className='text-gray-700 fw-semibold'>{`${text.ot_hours}:${
                          text.ot_minutes <= 9 ? '0' + text.ot_minutes : text.ot_minutes
                        }`}</span>
                      </div>
                      <div className=' text-gray-500 fs-8 fw-regular border-left'>
                        Leave Hours: &nbsp;
                        <span className='text-gray-700 fw-semibold'>{`${text.absent_hours}:${
                          text.absent_minutes <= 9 ? '0' + text.absent_minutes : text.absent_minutes
                        }`}</span>
                      </div>
                    </div>

                    <div className='pt-1'>
                      <table className='HistoryTable'>
                        {' '}
                        <tbody>
                          <tr>
                            <td>
                              <div className=' text-gray-500 fs-7 fw-regular border-left text-nowrap'>
                                Updated By:&nbsp;
                              </div>
                            </td>
                            <td>
                              {' '}
                              <div className='text-gray-700 fs-7 fw-semibold text-break'>
                                {text.updated_by_name == null
                                  ? text.created_by_name
                                  : text.updated_by_name}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className=' text-gray-500 fs-7 fw-regular border-left  text-nowrap'>
                                Comments:&nbsp;
                              </div>
                            </td>
                            <td>
                              <div className='text-gray-700 fs-7 fw-regular text-break'>
                                {/* {(!text?.is_reverted && (text?.status === 'SUBMITTED' ||text?.status === 'SAVED') )?(text?.user_notes? text?.user_notes:'-'):(text?.approver_notes?text?.approver_notes:'-')} */}
                                {text?.updated_by_role_id == 7 || text?.updated_by_role_id == 8
                                  ? text?.user_notes
                                    ? text?.user_notes
                                    : '-'
                                  : text?.approver_notes
                                  ? text?.approver_notes
                                  : '-'}
                              </div>
                            </td>
                          </tr>
                        </tbody>{' '}
                      </table>
                    </div>
                  </div>
                </div>
              </>
            ))
          : props.approver_history_open_per_day && (
              <div>
                <h4>Hours History</h4> <p>No Data Found</p>{' '}
              </div>
            )}
        {props.approver_history_open_per_day &&
        props.approver_history_per_day_status &&
        props.approver_history_per_day_status.length > 0 ? (
          <div className='fs-6  fw-semibold text-gray-700 py-3'>Day Status</div>
        ) : (
          ''
        )}

        {props.history_open_per_week && props.history_per_week && props.history_per_week.length > 0
          ? props.history_per_week.map((text: any, index: any) => (
              <div className='row gap-2  py-3 border-bottom ' key={index}>
                <div className='col-auto'>
                  <Tooltip text={text.updated_by_name}>
                    <span className=' approverBadge badge  bg-light text-dark' role='button'>
                      {text?.updated_by_user_id
                        ? text.updated_by_name?.slice(0, 1)
                        : text.created_by_name?.slice(0, 1)}
                    </span>
                  </Tooltip>
                </div>
                <div className='col ps-0'>
                  <div className=' text-gray-600 fs-7 fw-regular'>
                    Timesheet{' '}
                    <span
                      className={
                        text.status == 'SUBMITTED' || text.status == 'SAVED'
                          ? 'text-primary'
                          : text.status == 'APPROVED'
                          ? 'text-success'
                          : 'text-danger'
                      }
                    >
                      {' '}
                      {text?.is_reverted ? (
                        <span className='text-danger'>REVERTED</span>
                      ) : (
                        text.status
                      )}
                    </span>{' '}
                    {/* on <span>{moment(text.history_created).format('DD/MM/YYYY hh:mm A')}</span> */}
                    on{' '}
                    <span>
                      {/* {momenttz
                        .utc(text.history_created)
                        .tz('Asia/Kolkata')
                        .format('YYYY-MM-DD hh:mm A')} */}
                      {momenttz.utc(text.history_created).tz(country).format('YYYY-MM-DD hh:mm A')}
                    </span>
                  </div>
                  <div className='pt-1'>
                    <table className='HistoryTable'>
                      <tbody>
                        <tr>
                          <td>
                            <div className=' text-gray-500 fs-7 fw-regular border-left text-nowrap'>
                              Updated By:&nbsp;
                            </div>
                          </td>
                          <td>
                            {' '}
                            <div className='text-gray-700 fs-7 fw-semibold text-break'>
                              {text.updated_by_name == null
                                ? text.created_by_name
                                : text.updated_by_name}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className=' text-gray-500 fs-7 fw-regular border-left  text-nowrap'>
                              Comments:&nbsp;
                            </div>
                          </td>
                          <td>
                            <div className='text-gray-700 fs-7 fw-regular text-break'>
                              {/* {(!text?.is_reverted && (text?.status === 'SUBMITTED'|| text?.status === 'SAVED') )?(text?.user_notes? text?.user_notes:'-'):(text?.approver_notes?text?.approver_notes:'-')} */}
                              {text?.updated_by_role_id == 7 || text?.updated_by_role_id == 8
                                ? text?.user_notes
                                  ? text?.user_notes
                                  : '-'
                                : text?.approver_notes
                                ? text?.approver_notes
                                : '-'}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            ))
          : props.history_open_per_week && <p>No Data Found</p>}

        {props.history_open_per_day && props.history_per_day && props.history_per_day.length > 0
          ? props.history_per_day.map((text: any, index: any) => (
              <div className='row gap-2  py-3 border-bottom ' key={index}>
                <div className='col-auto ps-0'>
                  <Tooltip text={text.updated_by_name}>
                    <span className=' approverBadge badge  bg-light text-dark' role='button'>
                      {text?.updated_by_user_id
                        ? text.updated_by_name?.slice(0, 1)
                        : text.created_by_name?.slice(0, 1)}
                    </span>
                  </Tooltip>
                </div>
                <div className='col px-0'>
                  <div className=' text-gray-600 fs-7 fw-regular'>
                    Timesheet{' '}
                    <span
                      className={
                        text.status == 'SUBMITTED' || text.status == 'SAVED'
                          ? 'text-primary'
                          : text.status == 'APPROVED'
                          ? 'text-success'
                          : 'text-danger'
                      }
                    >
                      {text?.is_reverted ? (
                        <span className='text-danger'>REVERTED</span>
                      ) : (
                        text.status
                      )}
                    </span>{' '}
                    on <span>{moment(text.history_created).format('DD/MM/YYYY hh:mm A')}</span>
                  </div>
                  <div className='d-flex gap-2 flex-wrap'>
                    <div className=' text-gray-500 fs-8 fw-regular'>
                      Regular Hours : &nbsp;
                      <span className='text-gray-700 fw-semibold'>{`${text.regular_hours}:${
                        text.regular_minutes <= 9
                          ? '0' + text.regular_minutes
                          : text.regular_minutes
                      }`}</span>
                    </div>
                    <div className=' text-gray-500 fs-8 fw-regular'>
                      OT Hours : &nbsp;
                      <span className='text-gray-700 fw-semibold'>{`${text.ot_hours}:${
                        text.ot_minutes <= 9 ? '0' + text.ot_minutes : text.ot_minutes
                      }`}</span>
                    </div>
                    <div className=' text-gray-500 fs-8 fw-regular border-left'>
                      Leave Hours: &nbsp;
                      <span className='text-gray-700 fw-semibold'>{`${text.absent_hours}:${
                        text.absent_minutes <= 9 ? '0' + text.absent_minutes : text.absent_minutes
                      }`}</span>
                    </div>
                  </div>
                  <div className='pt-1'>
                    <table className='HistoryTable'>
                      <tbody>
                        <tr>
                          <td>
                            <div className=' text-gray-500 fs-7 fw-regular border-left text-nowrap'>
                              Updated By:&nbsp;
                            </div>
                          </td>
                          <td>
                            <div className='text-gray-700 fs-7 fw-semibold text-break'>
                              {text.updated_by_name == null
                                ? text.created_by_name
                                : text.updated_by_name}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className=' text-gray-500 fs-7 fw-regular border-left  text-nowrap'>
                              Comments:&nbsp;
                            </div>
                          </td>
                          <td>
                            <div className='text-gray-700 fs-7 fw-regular text-break'>
                              {text?.updated_by_role_id == 7 || text?.updated_by_role_id == 8
                                ? text?.user_notes
                                  ? text?.user_notes
                                  : '-'
                                : text?.approver_notes
                                ? text?.approver_notes
                                : '-'}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            ))
          : props.history_open_per_day && (
              <div>
                {' '}
                <h4>History Per Day</h4>
                <p>No Data Found</p>
              </div>
            )}
      </Offcanvas.Body>
    </Offcanvas>
  )
}
export default Drawer
