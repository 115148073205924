import React, {useState, useEffect} from 'react'
import {default as Apiservices} from '../../../../../../src/common/index'
import {useNavigate} from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import BackDrop from '../../timesheetentry/ComponentBackdrop'
import clsx from 'clsx'
import DatePicker from 'react-datepicker'
// import ButtonCal from '../../../../localMedia/buttonCal.svg'
import ButtonCal from '../../../../../localMedia/buttonCal.svg'
import {Controller} from 'react-hook-form'

import moment, {weekdays} from 'moment'

export default function DatePickerIcon(props: any) {
  // type ButtonProps = React.HTMLProps<HTMLButtonElement>

  // const [startDate, setStartDate] = useState(new Date())
  // const ExampleCustomInput = React.forwardRef<HTMLButtonElement, ButtonProps>(
  //   ({value, onClick}, ref: any) => (
  //     <button className='btn btn-icon btn-light btn-sm  ms-2 ' onClick={onClick} ref={ref}>
  //       <img src={ButtonCal} className='buttonCal' />
  //     </button>
  //   )
  // )

  return (
    <>
      <div className='col'>
        <DatePicker
          className='form-control fs-6 example-custom-input'
          selected={props.selected}
          name={props.id}
          dateFormat={props.dateFormat}
          disabled={props.disabled}
          onChange={(date: any) => {
            try {
              props.handleDate(date)
            } catch {}
            return date
          }}
          value={props.value}
          minDate={props.min}
          maxDate={props.max}
          placeholderText={props.placeholderText}
        />
      </div>
    </>
  )
}
