import {useEffect, useMemo, useState, useRef, useContext} from 'react'
import ThingsContext from '../../../modules/apps/masters/commonicons/GlobalContext'
import ValidContext from '../../../modules/apps/masters/commonicons/ValidateContext'
import {default as Apiservices} from '../../../../common/index'
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import axios from 'axios'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import {PageTitle} from '../../../../_metronic/layout/core'
import {useIntl} from 'react-intl'
import {useNavigate} from 'react-router-dom'
import clsx from 'clsx'
import {useLocation} from 'react-router-dom'
import {ClearButton, Typeahead} from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import BackDrop from '../../../modules/apps/timesheetentry/ComponentBackdrop'
import moment from 'moment'
import ConsultantPop from '../../../modules/apps/masters/modal/ConsultantPop'
import AdpPopup from '../../../modules/apps/masters/modal/AdpPopup'
import AdpAssociatePopup from '../../../modules/apps/masters/modal/AdpAssociatePopup'
import validator from 'validator'
import DatePickerIcon from '../../../modules/apps/masters/commonicons/DatePickerIcon'
import {Toolbar1} from '../../../../_metronic/layout/components/toolbar/Toolbar1'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import {Tooltip} from '../../../modules/apps/timesheetentry/ComponentTooltip'
import {KTCardBody} from '../../../../_metronic/helpers'
import {useTable, ColumnInstance} from 'react-table'
import {ManageListClients} from '../../../modules/apps/user-management/users-list/table/columns/_columns'
import {KTSVG} from '../../../../_metronic/helpers'

type FormData1 = {
  document_type: string
  document_type_name: string
  description: string
}
const AddDocument = (props: any) => {
  const {
    register,
    setValue,
    reset,
    getValues,
    handleSubmit,
    formState: {errors},
  } = useForm<FormData1>()
  const methods = useForm()
  const intl = useIntl()
  const navigate = useNavigate()
  const [fileDoc, setFileDoc] = useState<any | []>([])
  const [ceValidaton, setceValidaton] = useState(false)
  const [DocTypesList, setDocTypesList] = useState<any | []>([])
  const [FormData1, setFormData] = useState<any>()
  const [docResultDataRes, setDocResultDataRes] = useState<any>([])
  const [DocTypes, setDocTypes] = useState<any | []>([])
  const [NewDocPopUp, setNewDocPopUp] = useState(false)
  const [showBackDrop, setShowBackDrop] = useState(false)
  const [users, setUsers] = useState([])

  const data = useMemo(() => users, [users])
  const columns = useMemo(() => ManageListClients, [])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })
  const handleNewDocButtonOpen = () => {
    setNewDocPopUp(true)
    setValue('document_type_name', '')
    setValue('description', '')
  }
  const handleNewDocButtonClose = () => {
    setNewDocPopUp(false)
  }
  const downloadFile = (blob: any, index: any) => {
    var link = document.createElement('a')
    // If you don't know the name or want to use
    // the webserver default set name = ''
    link.setAttribute('download', 'Downloaded File')
    link.href = blob
    document.body.appendChild(link)
    link.click()
    link.remove()
  }
  useEffect(() => {
    getEmployeeDocTypes()
  }, [])

  const getEmployeeDocTypes = async () => {
    let ID = props.IndexState ? props.IndexState.id : props.id
    const data: any = await Apiservices.get_employee_document_types_list()
    if (data && data.status == 200) {
      setDocTypes(data.data.data)
    }
    const empdocres: any = await Apiservices.getEmployeeDocumentsbyid({
      user_id: ID,
    })
    if (empdocres && empdocres.status == 200) {
      setDocResultDataRes(empdocres.data.data)
    }
  }

  const FileUpload = async (e: any) => {
    let ID = props.IndexState ? props.IndexState.id : props.id
    var fileData = e.target.files
    let arr = []
    let filedoc = e.target.value
    arr.push(filedoc)
    setFileDoc(arr)
    let FileSize = []
    let FileFormats = [
      'image/png',
      'image/jpg',
      'image/jpeg',
      'application/pdf',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/msword',
    ]
    let EmptyFileFormats = []
    if (fileData.length > 0) {
      for (let i = 0; i < fileData.length; i++) {
        if (fileData[i].size / (1024 * 1024) <= 10) {
          FileSize.push(fileData[i].size / (1024 * 1024))
        } else {
          FileSize.length = 0
          break
        }
        if (FileFormats.includes(fileData[i].type)) {
          EmptyFileFormats.push(fileData[i].type)
        } else {
          EmptyFileFormats.length = 0
          break
        }
      }
    }
    if (fileData.length <= 5) {
      if (FileSize.length > 0) {
        if (EmptyFileFormats.length > 0) {
          var formData = new FormData()
          var filesLength = fileData && fileData.length ? fileData.length : 0
          for (var i = 0; i < filesLength; i++) {
            formData.append('document_file', fileData[i])
          }
          formData.append('user_id', ID)
          formData.append('employee_document_type_id', DocTypesList)
          // const res = await Apiservices.insert_employee_document(formData)
          setFormData(formData)
        }
      }
    }
    if (fileData.length > 0) {
      setceValidaton(true)
    } else {
      setceValidaton(false)
    }
  }
  const removeDoc1 = async (fileName: any, projectIndex: any) => {
    let ID = props.IndexState ? props.IndexState.id : props.id

    const response: any = await Apiservices.deleteEmployeeDocId({id: fileName})
    if (response.isError == false) {
      toast.success('Deleted Successfully')
      const empdocres: any = await Apiservices.getEmployeeDocumentsbyid({
        user_id: ID,
      })
      if (empdocres.status == 200) {
        setDocResultDataRes(empdocres.data.data)
      }
    }
  }
  const handleDocType = (doc: any) => {
    setDocTypesList(doc)
  }
  const onSubmit = async (data: FormData1) => {
    setShowBackDrop(true)
    const article = {
      document_type_name: data.document_type_name,
      description: data.description,
    }
    if (NewDocPopUp) {
      const response: any = await Apiservices.insertEmployeeDocumentType(article)
      if (response && response.message == 'Inserted Successfully') {
        toast.success('Inserted Successfully')
        setNewDocPopUp(false)
        let abc = [...DocTypes, response.data[0]]
        setDocTypes(abc)
        setValue('document_type', response.data[0].id)
        setDocTypesList(response.data[0].id)
      } else if (response && response.message == 'document_type_name already exists') {
        toast.warn('Document Type Already Exists')
      }
    } else {
      if (ceValidaton) {
        let ID = props.IndexState ? props.IndexState.id : props.id

        const res = await Apiservices.insert_employee_document(FormData1)
        if (res && res.statuscode == 200) {
          toast.success('Inserted Successfully')
          setceValidaton(false)
          setFormData('')
          setFileDoc([])
          const empdocres: any = await Apiservices.getEmployeeDocumentsbyid({
            user_id: ID,
          })
          if (empdocres.status == 200) {
            setDocResultDataRes(empdocres.data.data)
          }
        }
      } else {
        toast.warn('Documents are mandatory')
      }
      // const res = await Apiservices.insert_employee_document(FormData1)
      // if (res && res.statuscode == 200) {
      //   toast.success('Inserted Successfully')
      // }
    }
    setShowBackDrop(false)
  }
  return (
    <>
      <BackDrop showBackDrop={showBackDrop} />
      {!props.IndexState?.view && (
        <>
          <KTCardBody className='py-2 '>
            <div className='card card-custom card-flush'>
              <div className='card-body  py-4'>
                {/* <h3 className='text-gray-900 fs-3 fw-semibold  py-2'>Document Details</h3> */}
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className='row gy-4 align-items-end'>
                    <>
                      {NewDocPopUp ? (
                        <>
                          <div className='col-md-4 col-xs-12'>
                            <label className='form-label '>Document Type *</label>
                            <input
                              type='text'
                              disabled={props.IndexState?.view}
                              className={`form-control ${
                                errors.document_type_name ? 'is-invalid' : ''
                              }`}
                              {...register('document_type_name', {
                                required: true,
                                onChange: (e) => {
                                  setValue('document_type_name', e.target.value)
                                },
                              })}
                            />
                            {errors.document_type_name &&
                              errors.document_type_name.type === 'required' && (
                                <p className='error text-danger mb-0'>Document Type is required.</p>
                              )}
                          </div>
                          <div className='col-md-4 col-xs-12'>
                            <label className='form-label '>Description *</label>
                            <textarea
                              rows={1}
                              disabled={props.IndexState?.view}
                              {...register('description', {
                                required: true,
                              })}
                              className={`form-control ${errors.description ? 'is-invalid' : ''}`}
                            />
                            {errors.description && errors.description.type === 'required' && (
                              <p className='error text-danger mb-0'>Description is required.</p>
                            )}
                          </div>
                        </>
                      ) : (
                        <>
                          <div className='col-md-4 col-xs-12'>
                            <label className='form-label '>Document Type *</label>
                            <select
                              className={clsx(
                                'form-select',
                                errors.document_type ? 'is-invalid' : ''
                              )}
                              disabled={props.IndexState?.view}
                              {...register('document_type', {
                                required: true,
                                onChange: (e) => {
                                  handleDocType(e.target.value)
                                  // setDocTypesList(e.target.value)
                                  setValue('document_type', e.target.value)
                                },
                              })}
                            >
                              <option value=''>Select Document Type</option>
                              {DocTypes &&
                                DocTypes.length > 0 &&
                                DocTypes.map((item: any) => {
                                  return <option value={item.id}>{item.document_type_name}</option>
                                })}
                              <option value='Others'>Others</option>
                            </select>
                            {errors.document_type && errors.document_type.type === 'required' && (
                              <p className='error text-danger mb-0'>Document Type is required.</p>
                            )}
                          </div>
                          {DocTypesList == 'Others' && (
                            <div className='col-auto pt-8'>
                              <button
                                type='button'
                                className='btn btn-sm btn-primary cursor-pointer '
                                id='kt_toolbar_primary_button'
                                onClick={handleNewDocButtonOpen}
                              >
                                <KTSVG
                                  path='/media/icons/duotune/arrows/arr075.svg'
                                  className='svg-icon-5'
                                />
                                Add New Document Type
                              </button>
                            </div>
                          )}
                          {DocTypesList != 'Others' && (
                            <>
                              <div className='col-md-4 col-xs-12'>
                                <label className='form-label' style={{visibility: 'hidden'}}>
                                  Upload The Document
                                </label>

                                <input
                                  type='file'
                                  id={'test'}
                                  disabled={props.IndexState?.view}
                                  className='d-none'
                                  name='file'
                                  multiple
                                  accept='image/png,image/jpg,image/jpeg,application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword'
                                  onChange={(e: any) => {
                                    FileUpload(e)
                                    e.target.value = ''
                                  }}
                                />

                                <div className='col-md-12 col-xs-12'>
                                  <div className=''>
                                    <label
                                      role='button'
                                      htmlFor={'test'}
                                      className={clsx(
                                        'border-primary bg-primary',
                                        'width100   p-2 text-dark bg-opacity-10 border  border-dashed uploadDocument  d-flex align-items-center h45 justify-content-center'
                                      )}
                                      // className={clsx(
                                      //   ceValidaton ? 'border-primary bg-primary' : 'border-danger bg-danger',
                                      //   'width100   p-2 text-dark bg-opacity-10 border  border-dashed uploadDocument  d-flex align-items-center h85 justify-content-center'
                                      // )}
                                    >
                                      {' '}
                                      <div className='d-flex align-items-center justify-content-center gap-3'>
                                        {/* <div>
                                  <i className='bi bi-upload fs50'></i>
                                </div> */}
                                        <div>
                                          <div className='text-gray-700  fw-semibold fs-6'>
                                            Upload Document *
                                          </div>
                                          <div className='text-gray-500  fw-normal fs-8'>
                                            {/* Max 10 MB , Max 5 Files */}
                                            {/* <br /> */}
                                            png, jpg, jpeg, docx, xlsx, pdf
                                          </div>
                                        </div>
                                      </div>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </>
                      )}

                      {!props.IndexState?.view && (
                        <>
                          {NewDocPopUp ? (
                            <>
                              <div className='col-auto'>
                                <button
                                  className='btn btn-sm btn-light-danger'
                                  onClick={handleNewDocButtonClose}
                                >
                                  Close
                                </button>
                              </div>
                              <div className='col-auto'>
                                <button type='submit' className='btn btn-sm btn-primary'>
                                  Submit
                                </button>
                              </div>
                            </>
                          ) : (
                            DocTypesList != 'Others' && (
                              <div className='col-auto'>
                                <button type='submit' className='btn btn-sm btn-primary'>
                                  Upload
                                </button>
                              </div>
                            )
                          )}

                          {fileDoc.length > 0 && (
                            <div className='col-md-12 col-xs-12 text-gray-700 '>{fileDoc}</div>
                          )}
                        </>
                      )}
                    </>
                  </div>

                  {/* <ToastContainer /> */}
                </form>
              </div>
            </div>
          </KTCardBody>
        </>
      )}
      <KTCardBody className='py-2 '>
        <div className=''>
          <div className='card card-custom card-flush'>
            <div className='card-body  py-4'>
              <h3 className='text-gray-900 fs-3 fw-semibold  py-2'>Uploaded Documents</h3>
              <div className='table-responsive '>
                <table
                  id='kt_table_users'
                  className='table table-row-bordered table-row-gray-200 gy-2 gs-4'
                  {...getTableProps()}
                >
                  <thead>
                    <tr className='fw-semibold fs-7 text-gray-600 text-uppercase'>
                      <th className='  py-4'>
                        Document Type <span></span>
                      </th>
                      <th className='  py-4'>
                        Document <span></span>
                      </th>
                      <th className=' py-4 '>Actions </th>
                    </tr>
                  </thead>
                  {docResultDataRes?.length > 0 ? (
                    <>
                      <tbody className='' {...getTableBodyProps()}>
                        {docResultDataRes.length > 0 && (
                          <>
                            {docResultDataRes.map((each: any, docIndex: any) => {
                              return (
                                <>
                                  <tr
                                    key={docIndex}
                                    className='text-dark fw-semibold  fs-6 text-nowrap'
                                  >
                                    <td className=''>
                                      {each?.document_type_name ? each?.document_type_name : '-'}
                                    </td>
                                    {/* {each?.document_file?.length > 0 &&
                                      (each?.document_file?.substr(
                                        each?.document_file?.lastIndexOf('.') + 1
                                      ) == 'jpeg' ||
                                        each?.document_file?.substr(
                                          each?.document_file?.lastIndexOf('.') + 1
                                        ) == 'png' ||
                                        each?.document_file?.substr(
                                          each?.document_file?.lastIndexOf('.') + 1
                                        ) == 'pdf' ||
                                        each?.document_file?.substr(
                                          each?.document_file?.lastIndexOf('.') + 1
                                        ) == 'PDF' ||
                                        each?.document_file?.substr(
                                          each?.document_file?.lastIndexOf('.') + 1
                                        ) == 'jpg') && ( */}
                                    <td>
                                      <div className='d-flex nowrap'>
                                        <Tooltip text='View Uploaded File'>
                                          <div role='button'>
                                            <a
                                              href={each.blobUrl}
                                              target='_blank'
                                              className='m-1 mx-2'
                                            >
                                              <i className='bi bi-eye fw-bold fs-4 text-primary'></i>
                                            </a>
                                          </div>
                                        </Tooltip>
                                        <Tooltip text='Download File'>
                                          <div>
                                            <a
                                              className='m-1 mx-2'
                                              role='button'
                                              onClick={() => {
                                                downloadFile(each.blobDownloadUrl, docIndex)
                                              }}
                                            >
                                              <i className='bi bi-download  fw-bold fs-4 text-primary'></i>
                                            </a>
                                          </div>
                                        </Tooltip>
                                      </div>
                                    </td>
                                    {/* )} */}
                                    <td className=''>
                                      <div>
                                        <Tooltip text='Delete File'>
                                          <i
                                            className='bi bi-trash3 m-1 mx-2 text-danger fw-bold fs-4'
                                            role='button'
                                            onClick={() => removeDoc1(each.id, docIndex)}
                                          ></i>
                                        </Tooltip>
                                      </div>
                                    </td>
                                  </tr>
                                </>
                              )
                            })}
                          </>
                        )}
                      </tbody>
                    </>
                  ) : (
                    ''
                  )}
                </table>
                {(docResultDataRes?.length == null || docResultDataRes?.length == 0) && (
                  <p className='text-center'> No Records Found</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </KTCardBody>
    </>
  )
}

export default AddDocument
