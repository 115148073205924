import React, {useState} from 'react'
import {default as Apiservices} from '../../../../../../src/common/index'
import {useNavigate} from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import BackDrop from '../../timesheetentry/ComponentBackdrop'
import AnnouncementPop from '../modal/AnnouncementPop'

export default function FinalHoursPop(props: any) {
  const navigate = useNavigate()
  const [show, setShow] = useState(true)
  const [showBackDrop, setShowBackDrop] = useState(true)
  const [isLoading, setIsLoading] = useState<any | []>(false)

  const handleClose = () => {
    setShow(false)
    props.close(false)
    props.setShow(false)
    props.setFinalPopOpen(false)
  }
  return (
    <>
      <div className='testmodal'>
        <Modal
          show={props.FinalPopOpen}
          onHide={handleClose}
          aria-labelledby='contained-modal-title-vcenter'
          centered
          backdrop='static'
          className='multipopupbackdrop'
        >
          {props.view_open && (
            <div className='col-auto'>
              <button
                type='button'
                className='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'
                onClick={props.cancel}
              ></button>
            </div>
          )}
          <Modal.Body>
            <>
              <p className='text-center text-gray-800 fs-5 fw-semibold text-transform-unset'>
                Are you sure to mark these as final hours?
              </p>
              <div className='d-flex justify-content-center align-items-center py-3'></div>
            </>

            <div className='d-flex justify-content-center gap-3 py-2'>
              <>
                <div className=''>
                  <button
                    className='btn btn-sm  btn-primary'
                    onClick={() => props.handleSubmitted(props.indexState1, props.EachFinalId)}
                  >
                    Ok
                  </button>
                </div>
                <div className=''>
                  <button className='btn btn-sm btn-danger' onClick={props.modalPop}>
                    Cancel
                  </button>
                </div>
              </>
            </div>
          </Modal.Body>
        </Modal>
      </div>
      <ToastContainer />
    </>
  )
}
