import React, {useState} from 'react'
import {default as Apiservices} from '../../../../../../src/common/index'
import {useNavigate} from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'
export default function ProjectPop(props: any) {
  //   const { closeModal,emailForProp,passwordForProp} = props;
  const {closeModal, close, customermessage, cancel} = props
  const navigate = useNavigate()
  const [show, setShow] = useState(true)

  const handleClose = () => {
    setShow(false)
  }

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        size='sm'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        backdrop='static'
      >
        <Modal.Header className='py-4'>
          <Modal.Title>
            <div className=' text-gray-800 fs-4 fw-semibold text-center'>Alert</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className=' text-gray-800 fs-5 fw-semibold text-transform-unset'>
            Do you want to change this as primary project
          </div>
        </Modal.Body>
        <Modal.Footer className='p-2 py-3'>
          <div className='row justify-content-end  '>
            <div className='col-auto'>
              <button className='btn btn-sm btn-light-danger' onClick={props.cancel}>
                Cancel
              </button>
            </div>
            <div className='col-auto'>
              <button className='btn btn-sm btn-primary ' onClick={close}>
                Ok
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}
