import React, {useState} from 'react'
import {default as Apiservices} from '../../../../../../src/common/index'
import {useNavigate} from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'
import parse from 'html-react-parser'
import {useForm} from 'react-hook-form'

export default function RevertMonthPop(props: any) {
  const methods = useForm({
    mode: 'onChange',
  })

  const {register, control, handleSubmit, setValue, reset, getValues} = methods

  let validateFields = [`customer_name`]
  // let validateform = methods.triggerValidation(validateFields);

  // if (!validateform) return;
  //   const { closeModal,emailForProp,passwordForProp} = props;
  const {closeModal, close, customermessage, cancel} = props
  const navigate = useNavigate()
  const [show, setShow] = useState(true)
  const [revertDataPopup, setRevertDataPopup] = useState('')
  const [okButtonTriggered, setOkButtonTriggered] = useState(false)
  const handleClose = () => {
    setShow(false)
  }

  const greetUser = async () => {
    if (revertDataPopup !== '') {
      props.setConsultant10(revertDataPopup)
    }
    setOkButtonTriggered(true)
  }

  function handleChange(event: any) {
    setRevertDataPopup(event.target.value)
    // props.setConsultant(props.checkAdpData)
  }

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        // size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        backdrop='static'
      >
        <Modal.Header className=' py-4'>
          <Modal.Title>
            <div className=' text-gray-800 fs-4 fw-semibold text-transform-unset'>
              <span></span> Do you want revert the entire week ?
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='py-3'>
          <>
            <div className=' m-0'>
              <div className='col'>
                <textarea
                  placeholder='Please Enter Revert Comments *'
                  onChange={handleChange}
                  rows={3}
                  className={`form-control`}
                ></textarea>
              </div>
              {(!revertDataPopup || revertDataPopup == '' || revertDataPopup == ' ') &&
                okButtonTriggered && (
                  <>
                    <p style={{color: 'red'}}>Please Enter Revert Comments</p>
                  </>
                )}
            </div>
          </>
        </Modal.Body>
        <Modal.Footer className='p-2 '>
          <div className='row justify-content-end   '>
            <div className='col-auto'>
              <button
                className='btn btn-light-danger btn-sm cursor-pointer'
                onClick={props.onCancel}
              >
                Cancel
              </button>
            </div>

            <div className='col-auto'>
              <button className='btn btn-sm btn-primary' onClick={greetUser}>
                OK
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}
