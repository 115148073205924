import React, {useState, useMemo, useEffect, useRef} from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas'
import moment from 'moment'
import momenttz from 'moment-timezone'
import {Tooltip} from './ComponentTooltip'
import {ListProjects} from '../../../modules/apps/user-management/users-list/table/columns/_columns'
import {
  useQueryResponseData,
  useQueryResponseLoading,
} from '../../../modules/apps/user-management/users-list/core/QueryResponseProvider'
import {useTable, ColumnInstance} from 'react-table'
import DatePickerIcon from '../../../modules/apps/masters/commonicons/DatePickerIcon'

const ProjectHoursDrawer = (props: any) => {
  const users = useQueryResponseData()
  const data = useMemo(() => users, [users])
  const columns = useMemo(() => ListProjects, [])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })
  const handleClose = () => {
    props.setShow(false)
    props.setHistoryOpenPerWeek(false)
  }

  return (
    <Offcanvas show={props.show} onHide={handleClose} placement='end'>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title className='fs-5  fw-semibold text-gray-700'></Offcanvas.Title>
      </Offcanvas.Header>
      <div className='border-top '></div>
      <Offcanvas.Body>
        {props.ExpectedHoursHistory?.length > 1 && (
          <>
            <div className='row gy-4 '>
              <div className='col-12 m-0'></div>
              <div className='table-responsive '>
                <table
                  id='kt_table_users'
                  className='table table-row-bordered table-row-gray-200 gy-2 gs-4 '
                  {...getTableProps()}
                >
                  <thead>
                    <tr className='fw-semibold fs-7 text-gray-600 text-uppercase'>
                      <th onClick={() => 'effective_date'} className='  py-4'>
                        Effective Date{' '}
                      </th>
                      <th onClick={() => 'expected_hours'} className='  py-4'>
                        Expected Hours{' '}
                      </th>
                    </tr>
                  </thead>
                  {props.ExpectedHoursHistory?.length > 0 ? (
                    <>
                      <tbody className='' {...getTableBodyProps()}>
                        {props.ExpectedHoursHistory.length > 0
                          ? props.ExpectedHoursHistory.slice(1).map((user: any, index: number) => {
                              return (
                                <tr
                                  key={index}
                                  className='namepage text-dark fw-semibold  fs-6 text-nowrap'
                                >
                                  <td className=''>
                                    <DatePickerIcon
                                      className='form-control fs-6'
                                      value={
                                        user.effective_date
                                          ? moment(user.effective_date).format('MM/DD/YYYY')
                                          : '-'
                                      }
                                      dateFormat='MM/dd/yyyy'
                                      handleDate={(date: any) => {
                                        props.handleEffectiveTableDate(date, index + 1)
                                      }}
                                      disabled={true}
                                      max={
                                        props.ExpectedHoursHistory[0].effective_date
                                          ? new Date(props.ExpectedHoursHistory[0].effective_date)
                                          : ''
                                      }
                                      placeholderText='Select date'
                                    />
                                  </td>
                                  <td className=''>
                                    <input
                                      type='text'
                                      className='form-control fs-6'
                                      placeholder='Enter Expected Hours'
                                      disabled={true}
                                      value={user.expected_hours ? user.expected_hours : ''}
                                      onChange={(e: any) =>
                                        props.handleExpectedTableHours(e.target.value, index + 1)
                                      }
                                    />
                                  </td>
                                </tr>
                              )
                            })
                          : ''}
                      </tbody>
                    </>
                  ) : (
                    ''
                  )}
                </table>
                {(props.projectid.project_expected_hours?.length == null ||
                  props.projectid.project_expected_hours?.length == 0) && (
                  <p className='text-center'> No Records Found</p>
                )}
              </div>
            </div>
          </>
        )}
      </Offcanvas.Body>
    </Offcanvas>
  )
}
export default ProjectHoursDrawer
