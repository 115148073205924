import {FC} from 'react'
import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'

const Error404: FC = () => {
  let business: any = localStorage.getItem('role_Name')
  let numArray: any = business?.split(',')
  return (
    <div className='d-flex flex-column flex-root'>
      <div className='d-flex flex-column flex-center flex-column-fluid p-10'>
        {/* begin::Illustration */}
        <img
          src={toAbsoluteUrl('/media/illustrations/sketchy-1/18.png')}
          alt=''
          className='mw-100 mb-10 h-lg-450px'
        />
        {/* end::Illustration */}
        {/* begin::Message */}
        <h1 className='fw-bold mb-10' style={{color: '#A3A3C7'}}>
          Seems there is nothing here
        </h1>
        {/* end::Message */}
        {/* localStorage.getItem('role_Name') === 'admin' || localStorage.getItem('role_Name') ===
        'ACCOUNTS' */}
        {/* begin::Link */}
        {localStorage.getItem('role_Name') ? (
          <>
            {/* {localStorage.getItem('role_Name') === 'employee' ? ( */}
            {numArray?.includes('employee') ? (
              <Link to='/timesheetlist' className='btn btn-primary'>
                Back To Home Page
              </Link>
            ) : // localStorage.getItem('role_Name') === 'timesheetadmin' ||
            //   localStorage.getItem('role_Name') === 'superadmin' ||
            //   localStorage.getItem('role_Name') === 'admin' ? (
            numArray?.includes('timesheetadmin') ||
              numArray?.includes('superadmin') ||
              numArray?.includes('admin') ? (
              <Link to='/masters/manageconsultants' className='btn btn-primary'>
                Back To Home Page
              </Link>
            ) : (
              <Link to='/approver' className='btn btn-primary'>
                Back To Home Page
              </Link>
            )}
          </>
        ) : (
          <Link to='/login' className='btn btn-primary'>
            Back To Login Page
          </Link>
        )}
        {/* <Link to='/login' className='btn btn-primary'>
          Back To Home Page
        </Link> */}
        {/* end::Link */}
      </div>
    </div>
  )
}

export {Error404}
