import React, { useRef } from 'react';
import clsx from 'clsx'
import {useLocation} from 'react-router'
import {checkIsActive, KTSVG, WithChildren} from '../../../helpers'
import {useLayout} from '../../core'

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
}

const AsideMenuItemWithSub: React.FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet,
}) => {
  const {pathname} = useLocation()
  const isActive = checkIsActive(pathname, to)
  const {config} = useLayout()
  const {aside} = config
  const targetButtonRef = useRef<HTMLDivElement>(null);
const Clicked = ()=>{
  const elements: HTMLCollectionOf<Element> = document.getElementsByClassName('menu-item dropend');
  const elementsTwo: HTMLCollectionOf<Element> = document.getElementsByClassName('customSideBar dropdown-menu');

  if (targetButtonRef.current) {
  targetButtonRef.current.click();}
  
  if (elements.length > 0) {
    const element: Element = elements[0];
    element.classList.remove('hover');
    
  }
  if (elementsTwo.length > 0) {
    const elementTwo: Element = elementsTwo[0];
    elementTwo.classList.remove('show');
   
  }

 
}
// console.log(isActive,'isActive 123')
  return (<>
    <div
      className={clsx('menu-item dropend ', {'here show': isActive})}
      data-kt-menu-trigger='click'
    >
      <div className={clsx('text menu-link with-sub',{'active': isActive})} ref={targetButtonRef}  role="button"  id="defaultDropdown" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
        {/* {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )} */}
        {icon && aside.menuIcon === 'svg' && (
          <span className='menu-icon'>
            <KTSVG path={icon} className='svg-icon-2' />
          </span>
        )}
        {fontIcon && aside.menuIcon === 'font' && <i className={clsx('bi fs-3', fontIcon)}></i>}
        <div className='d-flex align-items-center'>
        <span className='menu-title test'>{title}</span>
        <span className='menu-arrow'></span></div>
      </div>
      {/* <div className={clsx('menu-sub menu-sub-accordion', {'menu-active-bg': isActive})}> */}
      <div className={clsx("customSideBar dropdown-menu ",{'menu-active-bg': isActive})} aria-labelledby="defaultDropdown" onClick={Clicked}>
        {children}
      </div>
    </div>
   </>
  )
}

export {AsideMenuItemWithSub}
