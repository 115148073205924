import React, {useState, useMemo, useEffect, useContext} from 'react'
import {default as Apiservices} from '../../../../../../src/common/index'
import Modal from 'react-bootstrap/Modal'
import {useForm} from 'react-hook-form'
import ThingsContext from '../../../../modules/apps/masters/commonicons/GlobalContext'
import {ToastContainer, toast} from 'react-toastify'
import BackDrop from '../../../../modules/apps/timesheetentry/ComponentBackdrop'
import {ManageListClients} from '../../../../modules/apps/user-management/users-list/table/columns/_columns'
import {useTable, ColumnInstance} from 'react-table'
import moment from 'moment'

export default function InvoiceHistoryViewPop(props: any) {
  const {
    register,
    setValue,
    reset,
    getValues,
    handleSubmit,
    formState: {errors},
  } = useForm()
  const [show, setShow] = useState(true)
  const [showBackDrop, setShowBackDrop] = useState(false)
  const [users, setUsers] = useState([])
  const data = useMemo(() => users, [users])
  const columns = useMemo(() => ManageListClients, [])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })
  const handleClose = () => {
    setShow(false)
    props.handleInvoiceClose()
  }

  const tokenHeader: any = useContext(ThingsContext)
  const renderThings = async () => {
    tokenHeader.getCookie()
  }
  useEffect(() => {
    renderThings()
  }, [])

  return (
    <>
      <BackDrop showBackDrop={showBackDrop} />
      <Modal
        show={show}
        onHide={handleClose}
        // size='lg'
        size='xl'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        backdrop='static'
      >
        {/* <Modal.Header className=' py-4'>
          <Modal.Title>
            <div className=' text-gray-800 fs-4 fw-semibold text-transform-unset'>
              <span></span> QB Settings(ON/OFF)
            </div>
          </Modal.Title>
        </Modal.Header> */}
        <Modal.Header closeButton className=' py-4'>
          <h1 className='text-capitalize'>
            #Invoice -{' '}
            {props.ViewHistory && props.ViewHistory[0] && props.ViewHistory[0].invoice_id}
          </h1>
        </Modal.Header>

        <Modal.Body className=''>
          <div className='pt-3'>
            <div className='card card-custom card-flush '>
              <div className='card-body p-3 py-3'>
                <div className='table-responsive reports-table'>
                  <table
                    id='kt_table_users'
                    className='table table-row-bordered table-row-gray-300 gy-4'
                  >
                    <>
                      <thead>
                        <tr className='fw-semibold fs-7 text-gray-600 text-uppercase'>
                          <th className='  py-4'>Date </th>
                          <th className='  py-4'>Regular Hours </th>
                          <th className='  py-4'>OT Hours </th>
                          <th className='  py-4'>Bill Rate </th>
                          <th className='  py-4'>OT Bill Rate </th>
                          <th className='  py-4'>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {props.ViewHistory?.length > 0 ? (
                          <>
                            {props.ViewHistory?.map((each: any, index: any) => {
                              let hr_min = each.regular_hours + each.regular_minutes / 60
                              let hr_min1 = each.ot_hours + each.ot_minutes / 60
                              return (
                                <tr
                                  className='namepage text-dark fw-semibold  fs-6 text-nowrap'
                                  key={index}
                                >
                                  <td className=''>
                                    {each.timesheet_date
                                      ? moment(each.timesheet_date).format('MMMM DD')
                                      : '-'}
                                  </td>
                                  <td className=''>{hr_min ? hr_min.toFixed(2) : '-'}</td>
                                  <td className=''>{hr_min1 ? hr_min1.toFixed(2) : '-'}</td>
                                  <td className=''>
                                    {each.bill_rate
                                      ? '$' +
                                        Number(each.bill_rate).toLocaleString(undefined, {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        })
                                      : '-'}
                                  </td>
                                  <td className=''>
                                    {each.ot_bill_rate
                                      ? '$' +
                                        Number(each.ot_bill_rate).toLocaleString(undefined, {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        })
                                      : '-'}
                                  </td>
                                  <td className=''>
                                    {each.invoice_amount
                                      ? '$' +
                                        Number(each.invoice_amount).toLocaleString(undefined, {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        })
                                      : '-'}
                                  </td>
                                </tr>
                              )
                            })}
                          </>
                        ) : (
                          ''
                        )}
                      </tbody>
                    </>
                  </table>
                  {(props.ViewHistory?.length == null || props.ViewHistory?.length == 0) && (
                    <div className='text-center  fw-bold text-gray-800'>No Records found </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <ToastContainer />
      </Modal>
    </>
  )
}
