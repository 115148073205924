import axiosHttpRequest from '../axios'

async function listCommodityData(endpoint: any, params: any, configheaders: any) {
  // console.log('Axios Call', endpoint, params, configheaders);
  try {
    const serviceResponse = await axiosHttpRequest.execGetRequestsWithParams(
      endpoint,
      params,
      configheaders
    )
    // console.log('Axios Call', serviceResponse);
    return serviceResponse
  } catch (e) {
    // console.log('catch', e);
  }
}
async function listCommodityData2(endpoint: any, params: any, configheaders: any) {
  // console.log('Axios Call', endpoint, params, configheaders);
  try {
    const serviceResponse = await axiosHttpRequest.execGetRequestsWithParams2(
      endpoint,
      params,
      configheaders
    )
    // console.log('Axiosbhb', serviceResponse)
    return serviceResponse
  } catch (e) {
    // console.log('catch', e);
  }
}
// async function listCommodityData1(endpoint: any, postData: any, configheaders: any) {
//     const serviceResponse = await axiosHttpRequest.execGetRequestsWithParamsBody(
//         endpoint,
//         postData,
//         configheaders
//     );
//     return serviceResponse;
// }

async function putMasterData(endpoint: any, postData: any, configheaders: any) {
  const serviceResponse = await axiosHttpRequest.execPutRequest(endpoint, postData, configheaders)
  return serviceResponse
}

async function patchMasterData(endpoint: any, postData: any, configheaders: any) {
  const serviceResponse = await axiosHttpRequest.execPatchRequest(endpoint, postData, configheaders)
  return serviceResponse
}

async function postMasterData(endpoint: any, postData: any, configheaders: any) {
  const serviceResponse = await axiosHttpRequest.execPostRequest(endpoint, postData, configheaders)
  // console.log(serviceResponse, "serviceResponse")
  return serviceResponse
}

async function getUserTokenByUserName(endpoint: any, postData: any, configheaders: any) {
  const serviceResponse = await axiosHttpRequest.execPostRequest(endpoint, postData, configheaders)
  return serviceResponse
}
export default {
  listCommodityData,
  listCommodityData2,
  putMasterData,
  patchMasterData,
  postMasterData,
  getUserTokenByUserName,
  // listCommodityData1
}
