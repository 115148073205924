import React, { useState, useEffect } from 'react'
import { default as Apiservices } from '../../../../../../src/common/index'
import { useNavigate } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'
import parse from 'html-react-parser'
import moment from 'moment'
import momenttz from 'moment-timezone'

export default function EmployeePop(props: any) {
    console.log(props.projectsData, "ghhhh")
    let projectData: any = props.projectsData
    //   const { closeModal,emailForProp,passwordForProp} = props;
    const { closeModal, close, customermessage, cancel } = props
    const navigate = useNavigate()
    const [show, setShow] = useState(true)
    const [date, setDate] = useState(new Date())

    // useEffect(() => {
    //     var today = moment(date)
    //     var startOfWeek = today.clone().startOf('isoWeek').format('YYYY-MM-DD')
    //     var endOfWeek = today.clone().endOf('isoWeek').format('YYYY-MM-DD')
    //     console.log(startOfWeek, endOfWeek, "anill")

    //     const getComments: any = async () => {

    //         const data: any = await Apiservices.employeereportViewData({

    //             start_date: startOfWeek,
    //             end_date: endOfWeek,
    //             user_id: props.indexState
    //         })
    //         console.log(data.data.data, 'anill')
    //         // setUpdateDate1(data?.data?.data[0]?.updatedate?.slice(0, 10))
    //         // setMainTableData(data?.data?.data)
    //         // setPageCount(Math.ceil(data.data.count[0].count / itemsPerPage))
    //         // setLoading(!false)
    //     }
    //     getComments()
    //     // setShowBackDrop(false)
    // }, [])

    const handleClose = () => {
        setShow(false)
    }

    const Announcement = async () => {
        const data: any = await Apiservices.markAsReadAnnouncement({
            id: props.announcement_list.id,
        })
        props.setAnnouncementOpenPoup(false)
    }
    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                size='lg'
                aria-labelledby='contained-modal-title-vcenter'
                centered
                backdrop='static'
            >
                <Modal.Body className='p-0'>
                    {/* {props.role_name == 'Admin' ? (
                        <div className='p-4 m-0'>{parse(props?.indexState ? props?.indexState : '')}</div>
                    ) : (
                        <>
                            <div className=' text-gray-800 fs-5 fw-semibold p-4'>
                                {/* {props.announcement_list ? props.announcement_list.title : ''} */}
                    {/* </div>
                            <div className='border-top'></div>
                            <div className='p-4 m-0'>
                                {parse(props.announcement_list ? props.announcement_list.description : '')}
                            </div> */}
                    {/* </> */}
                    {/* )} */}

                    {projectData.map((each: any) => (
                        <div className='p-4 m-0'>
                            Project Name :    {each.project_name}<br />
                            Total Hours  :    {each.regular_hours_minutes.slice(0, 5)}

                        </div>
                    ))}

                    <div className='border-top'></div>
                    <div className='row justify-content-end gap-2 py-2 p-4 '>
                        {props.role_name == 'Admin' ? (
                            <div className='col-auto'>
                                <button className='btn btn-sm btn-success' onClick={props.close}>
                                    Cancel
                                </button>
                            </div>
                        ) : (
                            <>
                                <div className='col-auto'>
                                    <button className='btn btn-sm btn-light-danger' onClick={Announcement}>
                                        Don't show it again
                                    </button>
                                </div>
                                <div className='col-auto'>
                                    <button className='btn btn-sm btn-primary ' onClick={() => close('true')}>
                                        Confirm
                                    </button>
                                </div>
                            </>
                        )}
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
