import React, {useState, useMemo, useEffect, useRef} from 'react'
import Modal from 'react-bootstrap/Modal'
import Spinner from 'react-bootstrap/Spinner'
import CSS from 'csstype';
const BackDrop = (props: any) => {
    const spinner: CSS.Properties = {
       width:'3rem',
       height:'3rem'
      };
 
  return (
    <Modal
    show={props.showBackDrop}
      backdrop='static'
      keyboard={false}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      className='timeSheetBackDrop'
    >
     <div className="spinner-border text-primary" style={spinner}  role="status">
  <span className="visually-hidden">Loading...</span>
</div>
    </Modal>
  )
}
export default BackDrop
